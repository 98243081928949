import { ReactNode } from 'react'

const DescriptionWithIcon = ({ label, icon }: { label: string; icon: ReactNode }) => (
  <div className="text-gray50 text-xs font-medium font-['Pretendard'] leading-[14px] flex gap-1 items-center">
    {icon}
    {label}
  </div>
)

export default DescriptionWithIcon
