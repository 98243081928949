import { SvgProps } from '../../types/svgProps'

const PlusIcon = ({ color = 'black', className = '' }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <circle cx="12" cy="12" r="11.5" fill="white" stroke="#DEDDDD" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6873 11.2373H12.7623V7.3123C12.7623 6.89435 12.4178 6.5498 11.9998 6.5498C11.5818 6.5498 11.2373 6.89435 11.2373 7.3123V11.2373H7.3123C6.89435 11.2373 6.5498 11.5818 6.5498 11.9998C6.5498 12.4178 6.89435 12.7623 7.3123 12.7623H11.2373V16.6873C11.2373 17.1053 11.5818 17.4498 11.9998 17.4498C12.4178 17.4498 12.7623 17.1053 12.7623 16.6873V12.7623H16.6873C17.1053 12.7623 17.4498 12.4178 17.4498 11.9998C17.4498 11.5818 17.1053 11.2373 16.6873 11.2373Z"
        fill={color}
      />
    </svg>
  )
}

export default PlusIcon
