import { Divider } from '@mui/material'
import colors from '../../styles/colors'
import { useLocation, useNavigate } from 'react-router-dom'
import { TRAVEL_CITY } from './TravelCityData'

interface LocationOptionProps {
  location: string
  subLocation?: string
  imgSrc: string
  onClick: () => void
}
const LocationOption = ({ location, subLocation, imgSrc, onClick }: LocationOptionProps) => {
  return (
    <div className="self-stretch justify-start items-center gap-2.5 inline-flex" onClick={onClick}>
      <img className="w-8 h-8 relative rounded-[57px]" src={imgSrc} alt={location} />
      <div className="justify-start items-center flex">
        <div className="text-center text-zinc-900 text-[15px] font-medium font-['Pretendard'] leading-tight">
          {location}
        </div>
        {subLocation && (
          <div className="text-center text-zinc-900 text-xs font-medium font-['Pretendard'] leading-none">
            ({subLocation})
          </div>
        )}
      </div>
    </div>
  )
}
const TravelPlanRecommendWhere = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleLocationClick = (selection: string) => {
    navigate('/travelPlanRecommend/when', { state: { from: location.pathname, selectedLocation: selection } })
  }

  return (
    <div className={'px-6'}>
      <div className="py-[22px] w-full text-zinc-900 text-xl font-semibold font-['Pretendard']">
        여행은 어디로 떠나시나요?
      </div>
      <div className="w-full py-[12px] px-[17.5px] bg-gray10 rounded">
        <p
          className="text-xs font-medium"
          style={{
            wordWrap: 'break-word',
            wordBreak: 'keep-all',
          }}
        >
          한국 관광공사 인기 여행기사 Top 10을 기준으로 추천드려요.
        </p>
      </div>
      <div className="flex flex-col gap-7 mt-[32px]">
        {/* TODO: 지역 및 미워드 검색 서치바 여기에 추가 */}
        {/* <div></div> */}

        <div className="w-full flex-col justify-start items-start gap-2.5 inline-flex">
          {TRAVEL_CITY.map((city, index) => {
            return (
              <>
                <LocationOption
                  key={city.name}
                  location={city.name}
                  subLocation={city.subLocation}
                  imgSrc={city.thumbnail}
                  onClick={() => handleLocationClick(city.name)}
                />
                {index !== TRAVEL_CITY.length - 1 && <Divider sx={{ width: '100%', borderColor: colors.gray10 }} />}
              </>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default TravelPlanRecommendWhere
