import { useLocation, useNavigate } from 'react-router-dom'
import { TRAVEL_CITY } from '../../travelPlanRecommend/TravelCityData'

const PopularCity = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleLocationClick = (selection: string) => {
    navigate('/travelPlanRecommend/when', { state: { from: location.pathname, selectedLocation: selection } })
  }

  const goTravelPlanRecommend = () => {
    navigate('travelPlanRecommend/where', { state: { from: location.pathname } })
  }

  return (
    <section className="text-[18px] mt-[24px]">
      <h3 className="text-[18px] px-[20px]">인기 여행도시</h3>
      <div className="flex gap-[12px] overflow-x-auto scrollbar-hide px-[20px] mt-[12px]">
        {TRAVEL_CITY.map((city) => (
          <button
            className="relative shrink-0 flex items-center justify-center rounded-[50%] w-[64px] h-[64px] overflow-hidden"
            key={city.name}
            style={{
              background: `no-repeat center url(${city.thumbnail})`,
              backgroundSize: 'cover',
            }}
            onClick={() => handleLocationClick(city.name)}
          >
            <p className="relative z-[2] text-white text-[12px]">{city.name}</p>
            <div className="absolute bg-black opacity-50 w-full h-full" />
          </button>
        ))}
        <button
          className="relative shrink-0 flex items-center justify-center rounded-[50%] w-[64px] h-[64px] overflow-hidden bg-primary50"
          onClick={goTravelPlanRecommend}
        >
          <p className="relative z-[2] text-white text-[12px]">더보기</p>
        </button>
      </div>
    </section>
  )
}

export default PopularCity
