import { Box, Typography } from '@mui/material'
import DotLine from '../shared-components/Icons/DotLine'
import colors from '../styles/colors'

const GetSignUpStep = ({ step }: { step: number }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', marginBottom: '24px', padding: '32px 0 0 24px' }}>
      <Box
        sx={{
          width: '24px',
          height: '24px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '43px',
          backgroundColor: step === 1 ? colors.primary50 : colors.gray40,
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '16px',
            letterSpacing: '-0.24px',
            color: colors.white,
          }}
        >
          1
        </Typography>
      </Box>
      <DotLine />
      <Box
        sx={{
          width: '24px',
          height: '24px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '43px',
          border: `1px solid ${step === 2 ? colors.primary50 : step === 3 ? colors.gray40 : colors.gray30}`,
          backgroundColor: step === 2 ? colors.primary50 : step === 3 ? colors.gray40 : colors.white,
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '16px',
            letterSpacing: '-0.24px',
            color: step === 1 ? colors.gray60 : colors.white,
          }}
        >
          2
        </Typography>
      </Box>
      <DotLine />
      <Box
        sx={{
          width: '24px',
          height: '24px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '43px',
          border: `1px solid ${step === 3 ? colors.primary50 : colors.gray30}`,
          backgroundColor: step === 3 ? colors.primary50 : colors.white,
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '16px',
            letterSpacing: '-0.24px',
            color: step === 3 ? colors.white : colors.gray60,
          }}
        >
          3
        </Typography>
      </Box>
    </Box>
  )
}

export default GetSignUpStep
