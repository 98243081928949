import { useEffect, useState } from 'react'
import { Button, Modal, Slide, styled } from '@mui/material'
import LikeButton from '../../shared-components/LikeButton'
import { Service, TravelSpotDetail } from './TravelSpot.entity'
import TravelThemeChip from '../../shared-components/TravelThemeChip'
import MapPinLineIcon from '../../shared-components/Icons/MapPinLineIcon'
import colors from '../../styles/colors'
import ChargerIcon from '../../shared-components/Icons/ChargerIcon'
import EVChargerChip from '../../shared-components/EVChargerChip'
import { getChargerName, getChargerType } from '../../types/EVCharger'
import { getSpotById } from '../../repository/spot.repository'
import { getThemes, TravelTheme } from '../../repository/theme.repository'
import { useLike } from '../../like/Like'
import { getServiceList } from '../../repository/service.repository'

const StyledBorderBox = styled('div')(() => ({
  border: `1px solid ${colors.gray20}`,
  borderRadius: '0.5rem',
  padding: '0.625rem',
  display: 'flex',
  gap: '6px',
  alignItems: 'center',
}))

interface TravelSpotDetailModalProps {
  open: boolean
  onClose: () => void
  onClickConfirm: () => void
  travelSpotId: string | null
  label: string
}

const TravelSpotDetailModal = ({ open, onClose, onClickConfirm, travelSpotId, label }: TravelSpotDetailModalProps) => {
  const [travelSpotDetail, setTravelSpotDetail] = useState<TravelSpotDetail>()
  const [themes, setThemes] = useState<TravelTheme[]>([])
  const { spots, addLike, removeLike } = useLike()
  const [serviceList, setServiceList] = useState<Service[]>([])

  const fetchTravelSpotDetail = () => {
    if (typeof travelSpotId === 'string') {
      getSpotById(travelSpotId).then((spot) => {
        if (spot) {
          setTravelSpotDetail({
            ...spot,
            storeCall: spot.phoneNumber,
          })
          getServiceList(spot.id).then((services) => {
            setServiceList(services)
          })
        }
      })
    }
  }

  useEffect(() => {
    getThemes().then((themes) => {
      setThemes(themes)
    })
  }, [])

  useEffect(() => {
    if (travelSpotId) {
      fetchTravelSpotDetail()
    }
  }, [travelSpotId])

  if (!travelSpotDetail) return null

  const { thumbnail, title, description, theme, address, storeCall, operationDays, operationHours, chargers } =
    travelSpotDetail

  const handleOnSave = () => {
    onClickConfirm()
  }

  const handleOnChangeLike = (value: boolean) => {
    if (value) {
      addLike(travelSpotId!)
    } else {
      removeLike(travelSpotId!)
    }
  }

  return (
    <Modal open={open} onClose={onClose} disableAutoFocus className={'flex justify-center'} closeAfterTransition>
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <div
          className={
            'mt-6 w-full max-w-[639px] h-[100vh] pb-20 bg-white rounded-t-xl focus:outline-none overflow-y-scroll scrollbar-hide'
          }
        >
          <div
            id={'travel-spot-detail-modal-header'}
            className={
              'h-[60px] bg-white w-full rounded-t-xl py-5 px-6 flex justify-between items-center sticky top-0 z-10'
            }
          >
            <p className={'text-xl font-semibold'}>상세 정보</p>
            <Button onClick={onClose} variant={'outlined'} className={'!border-gray20 !text-gray90 !min-w-fit !px-3'}>
              닫기
            </Button>
          </div>
          <img
            id={'travel-spot-detail-modal-thumbnail'}
            src={thumbnail}
            className={'w-full h-[75vw] max-h-[400px] object-cover'}
            alt={'travel spot thumbnail'}
          />
          <div id={'travel-spot-detail-modal-content'} className={'px-5 py-6 flex flex-col gap-8 w-full'}>
            <div className={'flex flex-col gap-4 w-full'}>
              <div className={'flex justify-between items-center'}>
                <p className={'text-gray90 text-[18px] font-bold'}>{title}</p>
                <LikeButton
                  liked={spots.find((spot) => spot.id === travelSpotId) !== undefined}
                  onChange={(value) => handleOnChangeLike(value)}
                />
              </div>
              <p className="text-gray60 text-sm font-normal">{description}</p>
              <div className={'flex gap-4 w-full justify-center'}>
                {(theme || []).map((theme) => (
                  <TravelThemeChip label={themes.find((t) => t.id === theme)!.name} key={theme} />
                ))}
              </div>
              <div className={'flex flex-col gap-2 w-full'}>
                <StyledBorderBox>
                  <MapPinLineIcon color={colors.gray60} className={'w-3.5 h-3.5'} />
                  <p className={'text-gray70 text-xs'}>{address}</p>
                </StyledBorderBox>
                <StyledBorderBox>
                  <MapPinLineIcon color={colors.gray60} className={'w-3.5 h-3.5'} />
                  <p className={'text-gray70 text-xs'}>
                    {operationDays.join(', ')} / {operationHours}
                  </p>
                </StyledBorderBox>
                <StyledBorderBox>
                  <MapPinLineIcon color={colors.gray60} className={'w-3.5 h-3.5'} />
                  <p className={'text-gray70 text-xs'}>{storeCall}</p>
                </StyledBorderBox>
              </div>
            </div>
            <div className={'flex flex-col gap-2.5 w-full'}>
              <p className={'text-gray90 text-[18px] font-bold'}>충전기 정보</p>
              {chargers.map((charger, index) => (
                <StyledBorderBox key={'charger' + index + charger}>
                  <ChargerIcon type={charger} className={'w-7 h-7'} />
                  <p className={'text-gray70 text-[15px]'}>{getChargerName(charger)}</p>
                  <EVChargerChip label={getChargerType(charger)} />
                </StyledBorderBox>
              ))}
            </div>
            <div className={'flex flex-col gap-4 w-full'}>
              <p className={'text-gray90 text-[18px] font-bold'}>서비스 정보</p>
              <div className={'flex flex-col w-full'}>
                {serviceList.map((service) => (
                  <div className={'flex gap-3 w-full border-b border-gray10 py-3'} key={service.id}>
                    <img
                      src={service.image}
                      className={'w-[42px] h-[42px] object-cover rounded-lg'}
                      alt={'service thumbnail'}
                    />
                    <div>
                      <p className={'text-gray90'}>{service.name}</p>
                      <p className={'text-primary50 text-sm font-semibold'}>{service.price.toLocaleString()}원</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={'w-full fixed bottom-0 z-10 max-w-[639px]'}>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: '100%', borderRadius: '0px', minHeight: '56px', background: 'black', maxWidth: '639px' }}
              onClick={handleOnSave}
            >
              {label}
            </Button>
          </div>
        </div>
      </Slide>
    </Modal>
  )
}

export default TravelSpotDetailModal
