import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { getAuth, onAuthStateChanged, User } from 'firebase/auth'

interface AuthContextType {
  currentUser: User | null
}

export const AuthContext = createContext<AuthContextType>({ currentUser: null })

interface AuthProviderProps {
  children: ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [pending, setPending] = useState(true)

  useEffect(() => {
    onAuthStateChanged(getAuth(), (user) => {
      setCurrentUser(user)
      setPending(false)
    })
  }, [])

  if (pending) {
    return null
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
