import { get, getDatabase, query, ref } from 'firebase/database'
import { objectToArray } from '../utils/objectToArray'
import { Banner } from '../model/banner.entity'

export const getBanners = async () => {
  const db = getDatabase()

  return get(query(ref(db, 'banners'))).then((snapshot) => {
    if (snapshot.exists()) {
      return objectToArray<Banner>(snapshot.val())
    }

    return null
  })
}
