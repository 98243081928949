import { ChangeEvent, useState } from 'react'
import { Service, ServiceInformation } from '../MyStore.entity'
import { Box, Button, Typography } from '@mui/material'
import PhotoIcon from '../../../shared-components/Icons/PhotoIcon'
import MyStoreTextField from './MyStoreTextField'
import EcaroButton from '../../../shared-components/EcaroButton'
import colors from '../../../styles/colors'

interface ServiceItemProps {
  service: Service
  onChangeService: (id: string, value: Service) => void
}

const ServiceItem = ({ service, onChangeService }: ServiceItemProps) => {
  const [thumbnail, setThumbnail] = useState('')

  const onChangeThumbnail = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return

    const imageFile = event.target.files[0]
    const reader = new FileReader()

    reader.addEventListener('load', (e: ProgressEvent<FileReader>) => {
      if (!e || !e.target) return
      if (typeof e.target.result !== 'string') return
      setThumbnail(e.target.result)
    })
    reader.readAsDataURL(imageFile)

    onChangeServiceImage(imageFile)
  }

  const onChangeServiceImage = (value: File) => {
    onChangeService(service.id, { ...service, image: value })
  }

  const onChangeServiceName = (value: string) => {
    onChangeService(service.id, { ...service, name: value })
  }

  const onChangeServicePriceInformation = (value: number) => {
    onChangeService(service.id, { ...service, price: value })
  }

  return (
    <>
      <Box display={'flex'} gap={'16px'} mb={'16px'}>
        <label
          htmlFor={`serviceThumbnail-${service.id}`}
          className="flex flex-col items-center justify-center gap-[5px] w-[128px] h-[128px] rounded-[4px] border border-base bg-white overflow-hidden"
        >
          {typeof service.image === 'string' ? (
            <img
              src={service.image}
              alt="img"
              style={{
                objectFit: 'cover',
              }}
            />
          ) : thumbnail ? (
            <img
              src={thumbnail}
              alt="img"
              style={{
                objectFit: 'cover',
              }}
            />
          ) : (
            <>
              <PhotoIcon />
              <Typography fontSize={'15px'} fontWeight={500}>
                사진등록
              </Typography>
            </>
          )}
          <input
            id={`serviceThumbnail-${service.id}`}
            className="hidden"
            type="file"
            accept="image/*"
            onChange={(event) => onChangeThumbnail(event)}
          />
        </label>
        <Box display={'flex'} flex={1} flexDirection={'column'} gap={'16px'}>
          <MyStoreTextField
            label="서비스/메뉴 이름"
            name="serviceName"
            placeholder="서비스/메뉴 이름"
            value={service.name}
            onChange={(value) => onChangeServiceName(value)}
          />
          <MyStoreTextField
            label="가격"
            name="priceInformation"
            placeholder="가격"
            value={service.price}
            type={'number'}
            onChange={(value) => onChangeServicePriceInformation(parseInt(value))}
          />
        </Box>
      </Box>
    </>
  )
}

interface ServiceInformationFormProps {
  serviceInformation: ServiceInformation
  onChangeServiceInformation: (value: ServiceInformation) => void
}

const ServiceInformationForm = ({ serviceInformation, onChangeServiceInformation }: ServiceInformationFormProps) => {
  const onAddService = () => {
    const currentId = serviceInformation[serviceInformation.length - 1].id + 1
    const newService: Service = {
      id: currentId,
      image: null,
      name: '',
      price: 0,
    }
    onChangeServiceInformation([...serviceInformation, newService])
  }

  const onChangeService = (id: string, value: Service) => {
    onChangeServiceInformation(
      serviceInformation.map((service) => {
        if (service.id === id) return value
        else return service
      }),
    )
  }

  const onClickRemove = async (id: string) => {
    onChangeServiceInformation(serviceInformation.filter((service) => service.id !== id))
  }

  return (
    <>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        {serviceInformation.map((service, index) => {
          return (
            <Box
              key={`service-${service.id}`}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              mb={'20px'}
            >
              <ServiceItem service={service} onChangeService={onChangeService} />
              <Box display={'flex'} gap={'16px'}>
                {index === serviceInformation.length - 1 && (
                  <EcaroButton
                    color="primary"
                    text="서비스 추가하기"
                    sx={{
                      height: '42px',
                    }}
                    onClick={onAddService}
                  />
                )}
                <Button
                  onClick={() => onClickRemove(service.id)}
                  sx={{
                    width: '48px',
                    height: '42px',
                    borderRadius: '2px',
                    backgroundColor: colors.black,
                    '&:hover': { backgroundColor: colors.gray80 },
                    mb: '20px',
                  }}
                >
                  <Typography
                    sx={{
                      color: colors.white,
                      fontSize: '16px',
                      fontWeight: '500',
                      lineHeight: '20px',
                      letterSpacing: '-0.32px',
                    }}
                  >
                    삭제
                  </Typography>
                </Button>
              </Box>
            </Box>
          )
        })}
      </Box>
    </>
  )
}

export default ServiceInformationForm
