import RightArrowIcon from '../../../shared-components/Icons/RightArrowIcon'
import colors from '../../../styles/colors'
import TravelSpotListItem from '../../../shared-components/TravelSpotListItem'
import { useLocation, useNavigate } from 'react-router-dom'
import { Divider } from '@mui/material'
import LikeButton from '../../../shared-components/LikeButton'
import TravelSpotDetailModal from '../../travelSpot/TravelSpotDetailModal'
import { useState } from 'react'
import EmptyCourseCard from '../../../shared-components/EmptyCourseCard'
import { Spot } from '../../../repository/spot.repository'

export interface LikeItem {
  id: string
  value: boolean
}

interface WishListProps {
  spots: Spot[]
  onChangeLike: (like: LikeItem) => void
}

const WishList = ({ spots, onChangeLike }: WishListProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [selectedTravelSpotId, setSelectedTravelSpotId] = useState<string | null>(null)
  const goWishList = () => {
    navigate('/wishList', { state: { from: location.pathname } })
  }

  const handleOnClickItem = (id: string) => {
    setSelectedTravelSpotId(id)
  }
  const handleOnCloseModal = () => {
    setSelectedTravelSpotId(null)
  }
  return (
    <section className="px-[20px] mt-[20px]">
      <div className="flex justify-between">
        <h3 className="text-[18px]">내가 찜한 여행지</h3>
        {spots.length !== 0 && (
          <button className="flex items-center gap-[2px]" onClick={goWishList}>
            <span className="text-[14px] text-gray60">전체보기</span>
            <RightArrowIcon color={colors.gray60} className="shrink-0 w-[14px] h-[14px]" />
          </button>
        )}
      </div>
      <div className="mt-[12px]">
        {spots.length !== 0 ? (
          spots.map((spot) => (
            <>
              <div className={'flex justify-between items-start py-4'} onClick={() => handleOnClickItem(spot.id)}>
                <TravelSpotListItem key={`${spot.id}-${spot.title}`} {...spot} disabledOperationHours={true} />
                <LikeButton liked={true} onChange={(value) => onChangeLike({ id: spot.id, value })} />
              </div>
              <Divider sx={{ borderColor: colors.gray10 }} />
            </>
          ))
        ) : (
          <EmptyCourseCard
            link={'/travelPlanRecommend/where'}
            text={'찜한 여행지가 없어요 여행 만들면서 \n 마음에 드는 여행지를 찜해보세요!'}
          />
        )}
      </div>
      <TravelSpotDetailModal
        open={selectedTravelSpotId !== null}
        onClose={handleOnCloseModal}
        travelSpotId={selectedTravelSpotId}
        label={'확인'}
        onClickConfirm={() => handleOnCloseModal()}
      />
    </section>
  )
}

export default WishList
