import { Box, Button, TextField, Typography } from '@mui/material'
import colors from '../../../styles/colors'
import React, { useRef, useState } from 'react'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import CompleteModal from '../../../shared-components/CompleteModal'
import { useNavigate } from 'react-router-dom'
import { getUserByEmail } from '../../../repository/user.repository'

const ResetPasswordPage = () => {
  const emailRef = useRef<string>('')
  const [modalOpen, setModalOpen] = useState(false)
  const navigate = useNavigate()

  /* 휴대폰 번호 인증 제거되어서 PhoneNumberValidateInput과 함께 주석처리
  const onClickPhoneValidate = () => {
    setIsPhoneNumberValidate(true)
  }*/

  const onChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
    emailRef.current = event.target.value
  }

  const onClickSubmit = async () => {
    const auth = getAuth()

    const userByEmail = await getUserByEmail(emailRef.current)
    if (!userByEmail) {
      alert('가입되지 않은 이메일입니다.')
      return
    }

    sendPasswordResetEmail(auth, emailRef.current)
      .then(() => {
        setModalOpen(true)
      })
      .catch((error) => {
        const errorCode = error.code

        if (errorCode === 'auth/invalid-email') {
          alert('유효하지 않은 이메일입니다.')
        } else if (errorCode === 'auth/user-not-found') {
          alert('가입되지 않은 이메일입니다.')
        } else {
          alert('비밀번호 재설정 이메일 발송에 실패했습니다.')
        }
      })
  }

  const onCloseModal = () => {
    setModalOpen(false)
  }

  const handleOnClickConfirm = () => {
    setModalOpen(false)
    navigate('/login', { replace: true })
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px', padding: '32px 24px 130px 24px' }}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: '500',
            lineHeight: '36px',
            letterSpacing: '-0.48px',
            color: colors.gray90,
          }}
        >
          회원가입했던
          <br />
          이메일를 입력해 주세요.
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '16px',
                letterSpacing: '-0.24px',
                color: colors.gray90,
              }}
            >
              이메일
            </Typography>
            <TextField
              name={'email'}
              placeholder={'이메일 주소를 입력해주세요'}
              variant={'standard'}
              sx={{ '.MuiInput-input': { padding: '16px 4px' } }}
              fullWidth
              type={'email'}
              onChange={onChangeField}
            />
          </Box>
          {/*<PhoneNumberValidateInput*/}
          {/*  isPhoneNumberValidate={isPhoneNumberValidate}*/}
          {/*  onClickPhoneValidate={onClickPhoneValidate}*/}
          {/*/>*/}
        </Box>
      </Box>
      <Button
        onClick={onClickSubmit}
        variant="contained"
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          borderRadius: '0px',
          minHeight: '56px',
          background: 'black',
          maxWidth: '639px',
        }}
      >
        <Typography
          color={colors.white}
          fontSize={'16px'}
          fontWeight={500}
          lineHeight={'20px'}
          letterSpacing={'-0.32px'}
        >
          비밀번호 재설정
        </Typography>
      </Button>
      <CompleteModal
        onClose={onCloseModal}
        open={modalOpen}
        title={'비밀번호 재설정 이메일 발송'}
        content={'이메일을 확인해주세요.\n이메일이 오지 않았다면 스팸메일함을 확인해주세요.'}
        onClickConfirm={handleOnClickConfirm}
      />
    </>
  )
}

export default ResetPasswordPage
