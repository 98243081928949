import { useLocation } from 'react-router-dom'
import TravelSpotListItem from '../../shared-components/TravelSpotListItem'
import SearchBar from '../../shared-components/SearchBar'
import { Button, Divider, TextField } from '@mui/material'
import { FormEvent, Fragment, useEffect, useState } from 'react'
import colors from '../../styles/colors'
import LikeButton from '../../shared-components/LikeButton'
import { TravelSpot } from './TravelSpot.entity'
import TravelSpotDetailModal from './TravelSpotDetailModal'
import { filterSpotByTheme, getSpots, searchSpot } from '../../repository/spot.repository'
import { useLike } from '../../like/Like'
import { LikeItem } from '../main/fragments/WishList'
import { getTravel } from '../../repository/travel.repository'
import { useAuth } from '../../auth/Auth'
import { useRecoilState } from 'recoil'
import { myTravelState } from '../../travel/travelState'
import { getThemes, TravelTheme } from '../../repository/theme.repository'

const TravelSpotSearch = () => {
  const location = useLocation()
  const prevSpot: TravelSpot = location.state?.prevSpot
  const [travelSpots, setTravelSpots] = useState<TravelSpot[]>([])
  const { spots: likeSpots, addLike, removeLike } = useLike()
  const [keyword, setKeyword] = useState<string>('')
  const likeSpotsIds = likeSpots.map((likeSpot) => likeSpot.id)
  const { currentUser } = useAuth()
  const [myTravel, setMyTravel] = useRecoilState(myTravelState)
  const [selectedTravelSpotId, setSelectedTravelSpotId] = useState<string>('')
  const [themes, setThemes] = useState<TravelTheme[]>([])

  useEffect(() => {
    getSpots().then((spots) => {
      setTravelSpots(spots)
    })

    getThemes().then((themeList) => {
      setThemes(themeList)
    })
  }, [])

  const handleOnSubmitSearch = (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault()
    searchSpot(keyword).then((spots) => {
      setTravelSpots(spots)
    })
  }

  const handleOnClickTheme = (event: FormEvent<HTMLButtonElement>, themeId: string) => {
    event.preventDefault()
    filterSpotByTheme(themeId).then((spots) => {
      setTravelSpots(spots)
    })
  }

  const onClickSpot = (id: string) => {
    console.log('clicked spot id: ', id)
    setSelectedTravelSpotId(id)
  }

  const handleOnCloseModal = () => {
    setSelectedTravelSpotId('')
  }

  const handleOnChangeLike = (likeItem: LikeItem) => {
    if (likeItem.value) {
      addLike(likeItem.id)
      return
    }

    removeLike(likeItem.id)
  }

  const handleOnClickConfirm = async () => {
    const { id } = location.state as { id: string }

    if (!currentUser) return

    const changeSpots = () => {
      if (prevSpot?.id) {
        return myTravel.spots.map((spot, idx) => {
          if (idx === location.state?.index) {
            return selectedTravelSpotId!
          }
          return spot
        })
      } else {
        return [...myTravel.spots, selectedTravelSpotId!]
      }
    }

    if (!id) {
      setMyTravel({
        ...myTravel,
        spots: changeSpots(),
      })
      setSelectedTravelSpotId('')

      return
    }

    const travel = await getTravel(currentUser.uid, id)

    if (!travel) return

    setMyTravel({
      ...myTravel,
      spots: changeSpots(),
    })

    setSelectedTravelSpotId('')
  }

  return (
    <>
      {location.pathname === '/travelSpot/change' && prevSpot && (
        <div className={'bg-gray05 py-3 px-6'}>
          <TravelSpotListItem {...prevSpot} />
        </div>
      )}
      <div className={'py-5 px-6 flex flex-col gap-5 mb-8'}>
        <div className={'flex flex-col gap-[20px]'}>
          <p className={'text-xl font-semibold'}>
            {location.pathname === '/travelSpot/change'
              ? '변경할 여행지를 검색해 보세요!'
              : '추가할 여행지를 검색해 보세요!'}
          </p>
          <form onSubmit={handleOnSubmitSearch}>
            <SearchBar
              content={
                <TextField
                  placeholder="여행지 검색"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  variant="standard"
                  fullWidth
                  size={'small'}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{ px: 2 }}
                />
              }
              disabledShadow
              onClickSearchIcon={() => handleOnSubmitSearch()}
            />
          </form>
        </div>
        <div className={'flex gap-2 w-full overflow-x-scroll scrollbar-hide'}>
          {themes.map((theme) => (
            <Button
              variant={'outlined'}
              key={theme.name}
              onClick={(event) => handleOnClickTheme(event, theme.id)}
              sx={{
                px: 1,
                py: 0.5,
                minWidth: 'fit-content',
                borderColor: colors.gray20,
                color: colors.gray70,
              }}
            >
              {theme.name}
            </Button>
          ))}
        </div>
        <div className={'flex flex-col gap-[16px]'}>
          {travelSpots.map((travelSpot) => {
            return (
              <Fragment key={`spot-${travelSpot.id}`}>
                <div
                  className={'flex justify-between items-start'}
                  onClick={() => onClickSpot(travelSpot.id)}
                  onKeyDown={() => onClickSpot(travelSpot.id)}
                >
                  <TravelSpotListItem {...travelSpot} disabledOperationHours={true} />
                  <LikeButton
                    liked={likeSpotsIds.includes(travelSpot.id)}
                    onChange={(value) =>
                      handleOnChangeLike({
                        id: travelSpot.id,
                        value,
                      })
                    }
                  />
                </div>
                <Divider sx={{ borderColor: colors.gray10 }} />
              </Fragment>
            )
          })}
        </div>
      </div>
      <TravelSpotDetailModal
        open={selectedTravelSpotId !== ''}
        onClose={handleOnCloseModal}
        onClickConfirm={handleOnClickConfirm}
        travelSpotId={selectedTravelSpotId}
        label={location.pathname === '/travelSpot/change' ? '변경하기' : '추가하기'}
      />
    </>
  )
}

export default TravelSpotSearch
