import { equalTo, get, getDatabase, orderByChild, push, query, ref, remove } from 'firebase/database'
import { Service } from '../pages/travelSpot/TravelSpot.entity'
import { objectToArray } from '../utils/objectToArray'

export const getServiceList = async (spotId: string): Promise<Service[]> => {
  const db = getDatabase()

  return get(query(ref(db, 'services'), orderByChild('spotId'), equalTo(spotId))).then((snapshot) => {
    if (snapshot.exists()) {
      return objectToArray<Service>(snapshot.val())
    }

    return []
  })
}

export interface AddServiceRequest {
  name: string
  image: string
  price: number
  spotId: string
}

export const addService = (request: AddServiceRequest) => {
  const db = getDatabase()

  return push(ref(db, 'services'), request)
}

export const removeServices = (spotId: string) => {
  const db = getDatabase()

  return get(query(ref(db, 'services'), orderByChild('spotId'), equalTo(spotId))).then((snapshot) => {
    if (snapshot.exists()) {
      const val = snapshot.val()

      Object.keys(val).forEach((key) => {
        get(ref(db, `services/${key}`)).then((snapshot) => {
          if (snapshot.exists()) {
            const val = snapshot.val()

            if (val.spotId === spotId) {
              return get(ref(db, `services/${key}`)).then((snapshot) => {
                if (snapshot.exists()) {
                  return remove(snapshot.ref)
                }
              })
            }
          }
        })
      })
    }
  })
}

export const removeByServiceId = (serviceId: string) => {
  const db = getDatabase()

  return get(ref(db, `services/${serviceId}`)).then((snapshot) => {
    if (snapshot.exists()) {
      return remove(snapshot.ref)
    }
  })
}
