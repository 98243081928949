const toReadableTime = (hourCount: number, minute: string) => {
  if (hourCount < 12) return `오전 ${String(hourCount).padStart(2, '0')}:${minute}`
  else if (hourCount === 12) return `오후 12:${minute}`
  else if (hourCount > 12) return `오후 ${String(hourCount - 12).padStart(2, '0')}:${minute}`
  else throw new Error('Invalid hourCount')
}

/**
 *
 * @param start 시작 시 (0 ~ 23)
 * @param end 종료 시 (12 ~ 24)
 * @param interval 분 간격 (5, 10, 15, 30)
 * @returns value: '00:00', text: '오전 00:00' 형태의 객체
 */

interface TimeOption {
  value: string
  text: string
}

const getTimeOptions = (start: number, end: number, interval: number) => {
  const options: TimeOption[] = []
  const minutes: string[] = []

  let minuteCount = 0
  while (minuteCount < 60) {
    minutes.push(String(minuteCount).padStart(2, '0'))
    minuteCount += interval
  }

  let hourCount = start

  while (hourCount < end) {
    for (const minute of minutes) {
      options.push({
        value: `${String(hourCount).padStart(2, '0')}:${minute}`,
        text: toReadableTime(hourCount, minute),
      })
    }
    hourCount++
  }

  options.push({
    value: `${String(end).padStart(2, '0')}:00`,
    text: `오후 ${String(end - 12).padStart(2, '0')}:00`,
  })

  return options
}

export default getTimeOptions
