import { Box, Button, Typography } from '@mui/material'
import colors from '../../../styles/colors'
import ActiveCarIcon from '../../../images/CarIcon.jpg'
import ActiveChargerIcon from '../../../images/ChargerIcon.jpg'

interface MemberTypeSelectPageProps {
  handleStep: () => void
  memberType: 'general' | 'enterprise'
  onClickSelectButton: (type: 'general' | 'enterprise') => void
}

const MemberTypeSelectPage = ({ handleStep, memberType, onClickSelectButton }: MemberTypeSelectPageProps) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '68px', padding: '32px 24px 130px 24px' }}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: '500',
            lineHeight: '36px',
            letterSpacing: '-0.48px',
            color: colors.gray90,
          }}
        >
          환영합니다.
          <br />
          이카로가 처음이시네요!
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px', justifyContent: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              alignItems: 'center',
              border: `1px solid ${memberType === 'general' ? colors.primary50 : '#E3E3E3'}`,
              borderRadius: '11px',
              padding: '24px 12px',
              opacity: memberType === 'general' ? '1' : '0.5',
            }}
            onClick={() => onClickSelectButton('general')}
          >
            <Box sx={{ width: '100px', height: '100px' }}>
              <img src={ActiveCarIcon} alt="user" />
            </Box>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '-0.32px',
                textAlign: 'center',
              }}
            >
              친환경차 사용자로
              <br />
              가입하기
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              alignItems: 'center',
              border: `1px solid ${memberType === 'enterprise' ? colors.primary50 : '#E3E3E3'}`,
              borderRadius: '11px',
              padding: '24px 12px',
              opacity: memberType === 'enterprise' ? '1' : '0.5',
            }}
            onClick={() => onClickSelectButton('enterprise')}
          >
            <Box sx={{ width: '100px', height: '100px' }}>
              <img src={ActiveChargerIcon} alt="charger" />
            </Box>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '-0.32px',
                textAlign: 'center',
              }}
            >
              충전시설 제공자로
              <br />
              가입하기
            </Typography>
          </Box>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          borderRadius: '0px',
          minHeight: '56px',
          background: 'black',
          maxWidth: '639px',
        }}
        onClick={handleStep}
      >
        <Typography
          color={colors.white}
          fontSize={'16px'}
          fontWeight={500}
          lineHeight={'20px'}
          letterSpacing={'-0.32px'}
        >
          {memberType === 'general' ? '사용자 가입 진행하기' : '시설 제공 사장님 가입 진행하기'}
        </Typography>
      </Button>
    </>
  )
}

export default MemberTypeSelectPage
