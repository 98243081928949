import { Box, Button, TextField } from '@mui/material'
import Logo from '../../images/logo-large.png'
import LoginIllustration from '../../images/login-illustration.png'
import colors from '../../styles/colors'
import { FormEvent, useState } from 'react'
import { isNotMatchEmailRegex } from '../../utils/isNotMatchEmailRegex'
import { useNavigate } from 'react-router-dom'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { get, getDatabase, query, ref } from 'firebase/database'
import { UserInfo } from '../../model/user.entity'

interface Account {
  email: string
  password: string
}

const Login = () => {
  //   const location = useLocation()
  const navigate = useNavigate()

  const [account, setAccount] = useState<Account>({
    email: '',
    password: '',
  })

  const setLocalStorage = (memberType: string) => {
    localStorage.setItem('memberType', memberType)
  }

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const user = await signInWithEmailAndPassword(getAuth(), account.email, account.password)
    const dataSnapshot = await get(query(ref(getDatabase(), `users/${user.user?.uid}`)))
    const userInfo: UserInfo = dataSnapshot.val()

    if (userInfo.memberType === 'enterprise') {
      setLocalStorage('enterprise')
      navigate('/')
    } else if (userInfo.memberType === 'general') {
      setLocalStorage('general')
      navigate('/')
    } else {
      alert('가입된 회원이 아닙니다.')
    }
  }

  const onChangeEmail = (value: string) => {
    setAccount({ ...account, email: value })
  }

  const onChangePassword = (value: string) => {
    setAccount({ ...account, password: value })
  }

  const goResetPassword = () => {
    navigate('/resetpassword', { state: { from: location.pathname } })
  }

  const goSignup = () => {
    navigate('/signup', { state: { from: location.pathname } })
  }

  return (
    <>
      <Box
        display={'flex'}
        minHeight={'100vh'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        paddingX={'20px'}
      >
        <img src={Logo} alt="logo" className="w-[164px] mb-[14px]" />
        <img src={LoginIllustration} alt="logo" className="w-[202px] mb-[48px]" />
        <Box component={'form'} width={'100%'} onSubmit={(event) => onSubmit(event)}>
          <TextField
            value={account.email}
            error={account.email.length > 0 && isNotMatchEmailRegex(account.email)}
            fullWidth
            label="이메일 주소"
            variant="standard"
            placeholder="이메일 주소를 입력해 주세요."
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              marginBottom: '20px',
              label: {
                color: colors.gray90,
                fontWeight: 500,
              },
              input: { padding: '16px 4px' },
            }}
            onChange={(event) => onChangeEmail(event.target.value)}
          />
          <TextField
            value={account.password}
            fullWidth
            label="비밀번호"
            type="password"
            variant="standard"
            placeholder="비밀번호를 입력해 주세요."
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              label: {
                color: colors.gray90,
                fontWeight: 500,
              },
              input: { padding: '16px 4px' },
            }}
            onChange={(event) => onChangePassword(event.target.value)}
          />
          <Box marginTop={'24px'} display={'flex'} flexDirection={'column'} gap={'14px'}>
            <Button
              disabled={
                account.email.length === 0 || account.password.length === 0 || isNotMatchEmailRegex(account.email)
              }
              type="submit"
              variant="text"
              fullWidth
              sx={{
                bgcolor: colors.gray90,
                color: colors.white,
                height: '50px',
                '&:hover': {
                  bgcolor: colors.gray90,
                  color: colors.white,
                },
                '&:disabled': {
                  bgcolor: colors.gray20,
                  color: colors.gray40,
                },
              }}
            >
              로그인
            </Button>
            <Button
              variant="text"
              fullWidth
              sx={{
                color: colors.gray90,
                height: '50px',
                border: '1px solid #DEDDDD',
                '&:hover': {
                  color: colors.gray90,
                  border: '1px solid #DEDDDD',
                },
              }}
              onClick={goSignup}
            >
              회원가입
            </Button>
          </Box>
        </Box>
        <Button
          variant="text"
          sx={{
            mt: '50px',
            fontSize: '12px',
            color: colors.gray90,
            height: '32px',
            border: '1px solid #DEDDDD',
            '&:hover': {
              color: colors.gray90,
              border: '1px solid #DEDDDD',
            },
          }}
          onClick={goResetPassword}
        >
          비밀번호를 잊으셨나요?
        </Button>
      </Box>
    </>
  )
}

export default Login
