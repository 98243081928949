import { PaletteOptions } from '@mui/material'
import colors from '../styles/colors'
const Palette = (): PaletteOptions => {
  return {
    primary: {
      main: colors.primary50,
      light: colors.primary30,
      dark: colors.primary60,
    },
    secondary: {
      main: colors.gray90,
      light: colors.gray80,
    },
  }
}
export default Palette
