import { Box, Button, Typography } from '@mui/material'
import EmptyCourseIcon from './Icons/EmptyCourseIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import colors from '../styles/colors'

interface EmptyCourseCardProps {
  link: string
  text: string
}

const EmptyCourseCard = ({ link, text }: EmptyCourseCardProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const goCreateTravel = () => {
    navigate(link, { state: { from: location.pathname } })
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', marginTop: '30px' }}>
      <EmptyCourseIcon />
      <Box sx={{ textAlign: 'center' }}>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '18px',
            letterSpacing: '-0.28px',
            whiteSpace: 'pre-line',
          }}
        >
          {text}
        </Typography>
      </Box>
      <Button
        onClick={goCreateTravel}
        sx={{
          width: '312px',
          height: '50px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          border: `1px solid ${colors.base}`,
          borderRadius: '2px',
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '20px',
            letterSpacing: '-0.32px',
            color: colors.gray90,
          }}
        >
          여행 만들기
        </Typography>
      </Button>
    </Box>
  )
}

export default EmptyCourseCard
