import { get, getDatabase, query, ref } from 'firebase/database'
import { objectToArray } from '../utils/objectToArray'
import { Category } from '../model/category.entity'

export const getCategories = async () => {
  const db = getDatabase()

  return get(query(ref(db, 'category'))).then((snapshot) => {
    if (snapshot.exists()) {
      return objectToArray<Category>(snapshot.val()).sort((a, b) => {
        return a.name > b.name ? 1 : -1
      })
    }

    return []
  })
}
