import { styled, Switch, SwitchProps } from '@mui/material'

export const AlarmSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 46,
  height: 24,
  padding: 0,
  borderRadius: 24,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#23CD84',
        opacity: 1,
        border: 0,
      },
    },
  },
}))
