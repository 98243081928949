import { equalTo, get, getDatabase, orderByChild, query, ref, set, update } from 'firebase/database'
import { UserInfo } from '../model/user.entity'

export const getUserByUid = async (uid: string): Promise<UserInfo | null> => {
  const db = getDatabase()

  return get(query(ref(db, `users/${uid}`)))
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val() as UserInfo
      }

      return null
    })
    .catch((error) => {
      console.error(error)
      return null
    })
}

export const setSpotIdByUid = async (uid: string, spotId: string) => {
  const db = getDatabase()

  return set(ref(db, `users/${uid}/spotId`), spotId)
}

export const deleteUserByUid = async (uid: string): Promise<boolean> => {
  const db = getDatabase()

  return get(query(ref(db, `users/${uid}`)))
    .then((snapshot) => {
      if (snapshot.exists()) {
        update(ref(db, `users/${uid}`), {
          isDeleted: true,
        })
        return true
      }

      return false
    })
    .catch((error) => {
      console.error(error)
      return false
    })
}

export const getUserByEmail = async (email: string): Promise<UserInfo | null> => {
  const db = getDatabase()

  return get(query(ref(db, 'users'), orderByChild('email'), equalTo(email)))
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val() as UserInfo
      }

      return null
    })
    .catch((error) => {
      console.error(error)
      return null
    })
}
