import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import RightArrowIcon from '../../../shared-components/Icons/RightArrowIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import colors from '../../../styles/colors'
import TravelHistoryCard from '../../../shared-components/TravelHistoryCard'
import EmptyCourseCard from '../../../shared-components/EmptyCourseCard'
import { useEffect, useState } from 'react'
import { Travel } from '../../../model/travel.entity'
import { getMyTravel } from '../../../repository/travel.repository'
import { useAuth } from '../../../auth/Auth'
import { getSpotById } from '../../../repository/spot.repository'

const MyTravel = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const [myTravelData, setMyTravelData] = useState<Travel[]>([])

  useEffect(() => {
    if (!currentUser) return

    const setTravelData = async () => {
      const res = await getMyTravel(currentUser.uid)
      if (!res) return

      const newVar = await Promise.all(
        res.map(async (travel) => {
          const travelSpots = Object.keys(travel.spots)

          const spot = await getSpotById(travelSpots[0])

          if (!spot)
            return {
              id: travel.id,
              companion: travel.companion,
              title: travel.title,
              startAt: travel.startAt,
              themes: travel.themes,
              spots: [],
            }

          return {
            id: travel.id,
            companion: travel.companion,
            title: travel.title,
            startAt: travel.startAt,
            themes: travel.themes,
            spots: [spot],
          }
        }),
      )

      setMyTravelData(newVar)
    }

    setTravelData()
  }, [])

  const goMyTravels = () => {
    navigate('/myTravels', { state: { from: location.pathname } })
  }

  return (
    <section className="my-[28px]">
      <div className="px-[20px] flex justify-between mb-[24px]">
        <h3 className="text-[18px]">내가 만든 여행</h3>
        {myTravelData.length !== 0 && (
          <button className="flex items-center gap-[2px]" onClick={goMyTravels}>
            <span className="text-[14px] text-gray60">전체보기</span>
            <RightArrowIcon color={colors.gray60} className="shrink-0 w-[14px] h-[14px]" />
          </button>
        )}
      </div>
      {myTravelData.length !== 0 ? (
        <Swiper slidesPerView={1.2} spaceBetween={16} centeredSlides modules={[Pagination]}>
          {myTravelData.map((history) => (
            <SwiperSlide key={history.id}>
              <TravelHistoryCard
                history={{
                  id: history.id,
                  title: history.title,
                  tags: [history.companion, ...Object.keys(history.themes)],
                  spots: history.spots,
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <EmptyCourseCard
          link={'/travelPlanRecommend/where'}
          text={'내가 만든 여행이 없어요 \n 나에게 딱 맞는 친화경차 여행코스를 만들어 보세요!'}
        />
      )}
    </section>
  )
}

export default MyTravel
