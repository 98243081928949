const EmptyNotice = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="104" height="122" viewBox="0 0 104 122" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.9375 68.4038H11.0625V106.296C11.0625 108.103 12.2476 109.697 13.9793 110.217C22.2057 112.684 45.3395 119.625 50.823 121.27C51.5906 121.5 52.4094 121.5 53.177 121.27C58.6605 119.625 81.7943 112.684 90.0207 110.217C91.7524 109.697 92.9375 108.103 92.9375 106.296C92.9375 96.8062 92.9375 68.4038 92.9375 68.4038Z"
        fill="#F2F3F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.9382 68.4038H52.0007C52.0007 68.4038 51.6036 121.442 52.0007 121.442C52.3978 121.442 52.7928 121.385 53.1777 121.27C58.6612 119.625 81.795 112.684 90.0214 110.217C91.7531 109.697 92.9382 108.103 92.9382 106.296C92.9382 96.8062 92.9382 68.4038 92.9382 68.4038Z"
        fill="#DEDDDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.0003 81.686L11.0628 68.4038C11.0628 68.4038 3.88445 79.0476 0.674946 83.8045C0.314696 84.3387 0.22668 85.0081 0.435462 85.616C0.64629 86.2239 1.12731 86.6967 1.73932 86.8973C9.5154 89.4232 32.5346 96.9025 38.5728 98.8634C39.4387 99.1458 40.3884 98.8204 40.9001 98.0651C43.645 94.0143 52.0003 81.686 52.0003 81.686Z"
        fill="#F9F9F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.9375 68.4038L52 81.686C52 81.686 60.3553 94.0143 63.1002 98.0651C63.6119 98.8204 64.5617 99.1458 65.4275 98.8634C71.4658 96.9025 94.4849 89.4232 102.261 86.8973C102.873 86.6967 103.354 86.2239 103.565 85.616C103.774 85.0081 103.686 84.3387 103.325 83.8045C100.116 79.0476 92.9375 68.4038 92.9375 68.4038Z"
        fill="#F9F9F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.0915 40.5029C41.5798 39.7517 40.6321 39.4263 39.7663 39.7088C33.7321 41.6676 10.7068 49.1489 2.93073 51.6768C2.31871 51.8754 1.83565 52.3482 1.62687 52.9561C1.41809 53.5641 1.5061 54.2354 1.86635 54.7676C4.82204 59.152 11.063 68.4039 11.063 68.4039L52.0005 55.0767C52.0005 55.0767 44.6481 44.2631 42.0915 40.5029Z"
        fill="#F2F3F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.134 54.7676C102.494 54.2354 102.582 53.5641 102.374 52.9561C102.165 52.3482 101.682 51.8754 101.07 51.6768C93.2937 49.1489 70.2684 41.6676 64.2342 39.7088C63.3683 39.4263 62.4206 39.7517 61.9089 40.5029C59.3524 44.2631 52 55.0767 52 55.0767L92.9375 68.4039C92.9375 68.4039 99.1784 59.152 102.134 54.7676Z"
        fill="#F9F9F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.9375 68.4039L52 55.0767L11.0625 68.4039L52 81.686L92.9375 68.4039Z"
        fill="#F2F3F5"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M52 81.686V55.0767L11.0625 68.4039L52 81.686Z" fill="#DEDDDD" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.776 60.9555C42.9245 60.3987 42.157 59.8031 41.4753 59.1788C40.6443 58.4153 39.3486 58.4706 38.5831 59.3037C37.8196 60.1347 37.8749 61.4304 38.708 62.1939C39.5451 62.9635 40.4887 63.6963 41.5367 64.382C42.4824 65.0001 43.7515 64.734 44.3696 63.7884C44.9878 62.8427 44.7217 61.5737 43.776 60.9555Z"
        fill="#8C8F91"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.3987 54.6672C36.7908 53.8423 36.277 53.0134 35.8554 52.1844C35.3437 51.1773 34.1094 50.7761 33.1023 51.2878C32.0953 51.7996 31.6941 53.0338 32.2058 54.0409C32.7257 55.0602 33.3562 56.0816 34.1033 57.0948C34.7726 58.0036 36.0539 58.1981 36.9648 57.5288C37.8736 56.8574 38.0681 55.5761 37.3987 54.6672Z"
        fill="#8C8F91"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.4059 46.314C34.4263 45.4891 34.5471 44.6887 34.7682 43.9212C35.0793 42.8363 34.4509 41.7003 33.364 41.3892C32.2792 41.0781 31.1431 41.7064 30.832 42.7933C30.5188 43.8905 30.3428 45.0347 30.3121 46.2116C30.2855 47.3415 31.1779 48.281 32.3078 48.3076C33.4377 48.3363 34.3772 47.4438 34.4059 46.314Z"
        fill="#8C8F91"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.5544 39.4774C38.0804 38.9903 38.6699 38.54 39.3147 38.1326C40.2706 37.5288 40.5571 36.2638 39.9553 35.31C39.3515 34.3541 38.0866 34.0675 37.1307 34.6693C36.2648 35.2158 35.4788 35.8197 34.7727 36.4747C33.9437 37.2423 33.8946 38.5379 34.6621 39.3669C35.4297 40.1959 36.7254 40.245 37.5544 39.4774Z"
        fill="#8C8F91"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.1664 35.9464C46.196 35.7561 47.287 35.6333 48.4373 35.5862C49.5672 35.5371 50.4433 34.5812 50.3962 33.4533C50.3491 32.3255 49.3932 31.4474 48.2634 31.4945C46.9124 31.5518 45.6311 31.6971 44.4214 31.9202C43.312 32.127 42.5751 33.1954 42.7818 34.3048C42.9865 35.4163 44.055 36.1511 45.1664 35.9464Z"
        fill="#8C8F91"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.835 35.826C57.581 35.8464 59.2062 35.785 60.7147 35.652C61.8405 35.5517 62.6736 34.5569 62.5733 33.4311C62.473 32.3074 61.4782 31.4743 60.3524 31.5746C58.9708 31.6954 57.4827 31.7527 55.8821 31.7322C54.7522 31.7199 53.8249 32.6267 53.8127 33.7545C53.7983 34.8844 54.7051 35.8137 55.835 35.826Z"
        fill="#8C8F91"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.4427 34.3974C69.4691 33.7772 71.1803 32.983 72.6131 32.0721C73.5649 31.4642 73.8474 30.1992 73.2395 29.2454C72.6336 28.2936 71.3666 28.0111 70.4127 28.619C69.2603 29.3518 67.8787 29.9823 66.2453 30.4817C65.1666 30.8133 64.5566 31.9575 64.8882 33.0382C65.2178 34.119 66.364 34.7269 67.4427 34.3974Z"
        fill="#8C8F91"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.8612 26.3428C78.8274 24.3451 79.1979 22.2327 79.1037 20.1961C79.0505 19.0662 78.0905 18.1942 76.9627 18.2474C75.8348 18.3006 74.9608 19.2586 75.014 20.3864C75.0795 21.7701 74.8298 23.205 74.1748 24.562C73.6836 25.5793 74.1093 26.8054 75.1266 27.2967C76.1439 27.7879 77.37 27.3601 77.8612 26.3428Z"
        fill="#8C8F91"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.5569 12.9582C75.0647 10.942 73.0404 9.53785 70.8338 9.08549C69.7265 8.86034 68.6437 9.57265 68.4165 10.68C68.1913 11.7874 68.9057 12.8702 70.011 13.0974C71.2841 13.3573 72.4058 14.2313 73.2676 15.396C73.941 16.3028 75.2244 16.4931 76.1311 15.8218C77.04 15.1483 77.2303 13.8649 76.5569 12.9582Z"
        fill="#8C8F91"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.8164 9.48241C52.0311 -3.0588 71.2247 -2.24823 60.0508 9.48241H49.8164Z"
        fill="#F2F3F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.8164 12.6592C52.0311 25.1983 71.2247 24.3898 60.0508 12.6592H49.8164Z"
        fill="#F2F3F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.6318 13.1382H63.3377C64.4676 13.1382 65.3846 12.2212 65.3846 11.0913C65.3846 9.96143 64.4676 9.04443 63.3377 9.04443H49.6318C48.504 9.04443 47.585 9.96143 47.585 11.0913C47.585 12.2212 48.504 13.1382 49.6318 13.1382Z"
        fill="#8C8F91"
      />
    </svg>
  )
}

export default EmptyNotice
