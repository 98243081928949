import { Box, Divider, Typography } from '@mui/material'
import { useState } from 'react'
import { AlarmSwitch } from '../../shared-components/AlarmSwitch'

const Alarm = () => {
  const [systemAlarm, setSystemAlarm] = useState(false)
  const [messageAlarm, setMessageAlarm] = useState(false)

  const onChangeSystemAlarm = () => {
    // TODO: 시스템 알림 실연동
    setSystemAlarm(!systemAlarm)
  }

  const onChangeMessageAlarm = () => {
    // TODO: 메시지 알림 실연동
    setMessageAlarm(!messageAlarm)
  }
  return (
    <Box height={'100%'}>
      <Box
        sx={{
          padding: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography fontSize={'15px'} fontWeight={500} lineHeight={'20px'}>
          시스템 알림
        </Typography>
        <AlarmSwitch checked={systemAlarm} onChange={onChangeSystemAlarm} />
      </Box>
      <Divider />
      <Box
        sx={{
          padding: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography fontSize={'15px'} fontWeight={500} lineHeight={'20px'}>
          메시지 수신 알림
        </Typography>
        <AlarmSwitch checked={messageAlarm} onChange={onChangeMessageAlarm} />
      </Box>
      <Divider />
    </Box>
  )
}

export default Alarm
