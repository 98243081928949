import GetSignUpStep from '../../utils/getSignUpStep'
import { ChangeEvent, useState } from 'react'
import MemberTypeSelectPage from './fragment/MemberTypeSelectPage'
import UserInfoPage from './fragment/UserInfoPage'
import AgreeTermsPage from './fragment/AgreeTermsPage'
import { Users } from '../../model/user.entity'
import CompleteModal from '../../shared-components/CompleteModal'
import { TermsStatus } from './fragment/termList'
import { createUserWithEmailAndPassword, deleteUser, getAuth, User } from 'firebase/auth'
import { getDatabase, ref, set } from 'firebase/database'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { signupStepState } from '../../store/signupStepState'

type MemberType = 'general' | 'enterprise'

const SignUp = () => {
  const navigate = useNavigate()
  const [signupStep, setSignupStep] = useRecoilState(signupStepState)
  const [memberType, setMemberType] = useState<MemberType>('general')
  const [modalOpen, setModalOpen] = useState(false)
  const [userInfo, setUserInfo] = useState<Users>({
    email: '',
    password: '',
    name: '',
    birth: '',
    memberType: 'general',
    phoneNumber: '',
    createdAt: '',
    updatedAt: '',
  })

  const onClickSelectButton = (type: MemberType) => {
    setMemberType(type)
  }
  const initStep = () => {
    setSignupStep(1)
  }
  const handleStep = () => {
    if (signupStep !== 3) {
      setSignupStep(signupStep + 1)
    } else {
      initStep()
    }
  }

  const handleField = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target
    setUserInfo({
      ...userInfo,
      [name]: value,
    })
  }

  const onCloseModal = () => {
    setModalOpen(false)
  }

  const onClickNextButton = async (termsStatus: TermsStatus) => {
    const auth = getAuth()
    const { email, password, name, phoneNumber, birth } = userInfo

    let signedUser: User

    try {
      const credential = await createUserWithEmailAndPassword(auth, email, password)
      signedUser = credential.user
    } catch (error: any) {
      const errorCode = error.code

      if (errorCode === 'auth/email-already-in-use') {
        alert('이미 사용중인 이메일입니다.')
      } else if (errorCode === 'auth/invalid-email') {
        alert('유효하지 않은 이메일입니다.')
      } else if (errorCode === 'auth/weak-password') {
        alert('비밀번호는 6자리 이상이어야 합니다.')
      } else {
        alert(errorCode)
      }
    }

    try {
      const db = getDatabase()
      await set(ref(db, 'users/' + signedUser!.uid), {
        name,
        memberType,
        phoneNumber,
        birth,
        email,
        updatedAt: new Date().getTime(),
        createdAt: new Date().getTime(),
        isDeleted: false,
        terms: {
          benefit: termsStatus.benefitTerm,
          marketing: termsStatus.marketingTerm,
        },
      })
      setModalOpen(true)
    } catch (error: any) {
      await deleteUser(signedUser!)
    }
  }

  const handleOnClickConfirm = () => {
    navigate('/login', { replace: true })
  }

  return (
    <>
      <GetSignUpStep step={signupStep} />
      {signupStep === 1 && (
        <MemberTypeSelectPage
          handleStep={handleStep}
          memberType={memberType}
          onClickSelectButton={onClickSelectButton}
        />
      )}
      {signupStep === 2 && <UserInfoPage handleStep={handleStep} onChangeField={handleField} userInfo={userInfo} />}
      {signupStep === 3 && <AgreeTermsPage onClickNextButton={onClickNextButton} />}
      <CompleteModal
        onClose={onCloseModal}
        open={modalOpen}
        title={'회원가입이 완료되었습니다.'}
        content={
          memberType === 'general'
            ? '이카로에서 즐거운 \n 친환경차 여행을 떠나보세요!'
            : '로그인 후 마이페이지에서 매장정보를 \n 등록해주세요.'
        }
        onClickConfirm={handleOnClickConfirm}
      />
    </>
  )
}

export default SignUp
