import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Main from './pages/main/Main'
import Page from './shared-components/Page'
import TravelPlanRecommended from './pages/travelPlanRecommend/TravelPlanRecommended'
import Header from './shared-components/Header'
import TravelPlanRecommendWhere from './pages/travelPlanRecommend/TravelPlanRecommendWhere'
import TravelPlanRecommendWhen from './pages/travelPlanRecommend/TravelPlanRecommendWhen'
import TravelPlanRecommendTheme from './pages/travelPlanRecommend/TravelPlanRecommendTheme'
import TravelSpotSearch from './pages/travelSpot/TravelSpotSearch'
import MyPage from './pages/myPage/MyPage'
import MyStore from './pages/myStore/MyStore'
import { createTheme, PaletteOptions, Theme, ThemeProvider } from '@mui/material'
import { useMemo } from 'react'
import Palette from './themes/palette'
import TravelHistoryDetail from './pages/travelHistory/TravelHistoryDetail'
import Login from './pages/login/Login'
import { initializeApp } from 'firebase/app'
import PrivateRoute from './PrivateRoute'
import NoticePage from './pages/myPage/NoticePage'
import NoticeDetail from './pages/myPage/fragments/NoticeDetail'
import FAQ from './pages/myPage/FAQ'
import Setting from './pages/myPage/Setting'
import Alarm from './pages/myPage/Alarm'
import Policy from './pages/myPage/Policy'
import MyCourse from './pages/myPage/MyTravels'
import MyWishList from './pages/myPage/MyWishList'
import { RecoilRoot } from 'recoil'
import Resign from './pages/myPage/Resign'
import SignUp from './pages/signup/SignUp'
import Profile from './pages/myPage/Profile'
import { AuthProvider } from './auth/Auth'
import { LikeProvider } from './like/Like'
import ResetPasswordPage from './pages/login/fragment/ResetPasswordPage'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_PRODUCTION_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_PRODUCTION_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PRODUCTION_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_PRODUCTION_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PRODUCTION_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PRODUCTION_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PRODUCTION_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_PRODUCTION_FIREBASE_MEASUREMENT_ID,
}

if (typeof window !== 'undefined') {
  initializeApp(firebaseConfig)
}

function App() {
  const palette: PaletteOptions = useMemo<PaletteOptions>(() => Palette(), [])
  const theme: Theme = useMemo(() => createTheme({ palette }), [palette])

  return (
    <div className="App bg-gray05 min-w-[360px]">
      <ThemeProvider theme={theme}>
        <RecoilRoot>
          <Page>
            <AuthProvider>
              <LikeProvider>
                <BrowserRouter>
                  <Header />
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/resetpassword" element={<ResetPasswordPage />} />
                    <Route element={<PrivateRoute />}>
                      <Route path="/" element={<Main />} />
                      <Route path="/travelPlanRecommend/where" element={<TravelPlanRecommendWhere />} />
                      <Route path="/travelPlanRecommend/when" element={<TravelPlanRecommendWhen />} />
                      <Route path="/travelPlanRecommend/theme" element={<TravelPlanRecommendTheme />} />
                      <Route path="/travelPlanRecommend" element={<TravelPlanRecommended />} />
                      <Route path="/travelSpot/change" element={<TravelSpotSearch />} />
                      <Route path="/travelSpot/search" element={<TravelSpotSearch />} />
                      <Route path="/travelHistory/:id" element={<TravelHistoryDetail />} />
                      <Route path="/myPage" element={<MyPage />} />
                      <Route path="/myStore" element={<MyStore />} />
                      <Route path="/notice" element={<NoticePage />} />
                      <Route path="/notice/:id" element={<NoticeDetail />} />
                      <Route path="/faq" element={<FAQ />} />
                      <Route path="/setting" element={<Setting />} />
                      <Route path="/alarm" element={<Alarm />} />
                      <Route path="/serviceTerms" element={<Policy />} />
                      <Route path="/privacyPolicy" element={<Policy />} />
                      <Route path="/myTravels" element={<MyCourse />} />
                      <Route path="/wishList" element={<MyWishList />} />
                      <Route path="/resign" element={<Resign />} />
                      <Route path="/profile" element={<Profile />} />
                    </Route>
                  </Routes>
                </BrowserRouter>
              </LikeProvider>
            </AuthProvider>
          </Page>
        </RecoilRoot>
      </ThemeProvider>
    </div>
  )
}

export default App
