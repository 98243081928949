import Intro from './fragments/Intro'
import Notification from './fragments/Notification'
import PopularCity from './fragments/PopularCity'
import BannerSection from './fragments/BannerSection'
import WishList, { LikeItem } from './fragments/WishList'
import MyTravel from './fragments/MyTravel'
import { useLike } from '../../like/Like'
import { useEffect, useState } from 'react'
import { Banner } from '../../model/banner.entity'
import { getBanners } from '../../repository/banner.repository'
import { Notice } from '../../model/notice.entity'
import { getNoticeList } from '../../repository/notice.repository'

const Main = () => {
  const { spots, addLike, removeLike } = useLike()
  const [banners, setBanners] = useState<Banner[]>([])
  const [noticeList, setNoticeList] = useState<Notice[]>([])

  useEffect(() => {
    getBanners().then((banners) => {
      if (!banners) return
      setBanners(banners)
    })
    getNoticeList().then((notices) => {
      if (!notices) return
      setNoticeList(notices)
    })
  }, [])

  const handleOnChangeLike = async (like: LikeItem) => {
    if (like.value) {
      await addLike(like.id)
      return
    } else {
      await removeLike(like.id)
      return
    }
  }

  return (
    <>
      <Intro />
      {noticeList.length > 0 && <Notification noticeList={noticeList} />}
      <PopularCity />
      <BannerSection banners={banners} />
      <WishList spots={spots} onChangeLike={(like) => handleOnChangeLike(like)} />
      <MyTravel />
    </>
  )
}

export default Main
