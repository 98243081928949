import { EVChargerType } from '../../types/EVCharger'

const ChargerIcon = ({ type, className }: { type: EVChargerType; className?: string }) => {
  const iconComponents = {
    AC_1,
    AC_3,
    DC_CHADEMO: DC_chademo,
    DC_COMBO: DC_combo,
    TESLA: Tesla,
  }

  const IconComponent = iconComponents[type]

  return IconComponent ? <IconComponent className={className} /> : null
}

const AC_1 = ({ className }: { className?: string }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <circle cx="16" cy="16" r="16" fill="#AEAEAE" />
    <circle cx="16" cy="15.9999" r="13.6" fill="#3B3B3B" />
    <circle cx="10" cy="11.6" r="2.6" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="2" />
    <circle cx="22" cy="11.6" r="2.6" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="2" />
    <circle cx="16.4" cy="22.8" r="2.6" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="2" />
    <circle cx="8.40002" cy="21.2" r="1.3" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="1.4" />
    <circle cx="24.4" cy="21.2" r="1.3" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="1.4" />
  </svg>
)

const AC_3 = ({ className }: { className?: string }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M32 14C32 22.8366 24.8366 30 16 30C7.16344 30 0 22.8366 0 14C0 5.16344 2.5 2 16 2C29.5 2 32 5.16344 32 14Z"
      fill="#AEAEAE"
    />
    <path
      d="M29.6004 13.9999C29.6004 21.511 23.5115 27.5999 16.0004 27.5999C8.48932 27.5999 2.40039 21.511 2.40039 13.9999C2.40039 6.48883 4.00078 4.5 16.0004 4.5C28 4.5 29.6004 6.48883 29.6004 13.9999Z"
      fill="#3B3B3B"
    />
    <circle cx="7.19961" cy="13.9999" r="2.6" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="2" />
    <circle cx="11.6498" cy="21.2001" r="2.6" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="2" />
    <circle cx="24.8012" cy="13.9999" r="2.6" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="2" />
    <circle cx="20.351" cy="21.2001" r="2.6" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="2" />
    <circle cx="16.0004" cy="13.9999" r="2.6" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="2" />
    <circle cx="10.7998" cy="8.3999" r="1.3" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="1.4" />
    <circle cx="20.3516" cy="8.3999" r="1.3" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="1.4" />
  </svg>
)

const DC_chademo = ({ className }: { className?: string }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <circle cx="16" cy="16" r="16" fill="#AEAEAE" />
    <circle cx="16" cy="15.9999" r="13.6" fill="#3B3B3B" />
    <path d="M13.6816 6.65479L18.3191 11.6805" stroke="#AEAEAE" />
    <path d="M13.4863 11.4868L18.512 6.8494" stroke="#AEAEAE" />
    <path d="M13.6816 20.0356L18.3191 25.0613" stroke="#AEAEAE" />
    <path d="M13.4863 24.8677L18.512 20.2303" stroke="#AEAEAE" />
    <circle cx="11.0752" cy="16" r="3.6748" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="2" />
    <circle cx="20.4248" cy="16" r="3.6748" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="2" />
  </svg>
)

const DC_combo = ({ className }: { className?: string }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <circle cx="11.5" cy="11.5" r="11.5" fill="#AEAEAE" />
    <circle cx="11.5006" cy="11.5001" r="9.775" fill="#3B3B3B" />
    <circle cx="7.18809" cy="8.3375" r="1.86875" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="1.4375" />
    <circle cx="15.8131" cy="8.3375" r="1.86875" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="1.4375" />
    <circle cx="11.7877" cy="16.3878" r="1.86875" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="1.4375" />
    <circle cx="6.03809" cy="15.2378" r="0.934375" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="1.00625" />
    <circle cx="17.5381" cy="15.2378" r="0.934375" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="1.00625" />
    <path
      d="M32 20.6002C32 26.8962 26.8961 32.0001 20.6001 32.0001C14.3041 32.0001 9.2002 26.8962 9.2002 20.6002C9.2002 14.3042 10.9814 12.0503 20.6001 12.0503C30.2188 12.0503 32 14.3042 32 20.6002Z"
      fill="#AEAEAE"
    />
    <path
      d="M30.29 20.6002C30.29 25.9518 25.9517 30.2901 20.6001 30.2901C15.2485 30.2901 10.9102 25.9518 10.9102 20.6002C10.9102 15.2486 12.0504 13.8315 20.6001 13.8315C29.1497 13.8315 30.29 15.2486 30.29 20.6002Z"
      fill="#3B3B3B"
    />
    <circle cx="14.3296" cy="20.6001" r="1.85248" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="1.42499" />
    <circle cx="17.5005" cy="25.7305" r="1.85248" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="1.42499" />
    <circle cx="26.8706" cy="20.6001" r="1.85248" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="1.42499" />
    <circle cx="23.6997" cy="25.7305" r="1.85248" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="1.42499" />
    <circle cx="20.6001" cy="20.6001" r="1.85248" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="1.42499" />
    <circle cx="16.8947" cy="16.6105" r="0.926242" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="0.997491" />
    <circle cx="23.7004" cy="16.6105" r="0.926242" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="0.997491" />
  </svg>
)

const Tesla = ({ className }: { className?: string }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M32 14C32 22.8366 24.8366 30 16 30C7.16344 30 0 22.8366 0 14C0 5.16344 2.5 2 16 2C29.5 2 32 5.16344 32 14Z"
      fill="#AEAEAE"
    />
    <path
      d="M29.6004 13.9999C29.6004 21.511 23.5115 27.5999 16.0004 27.5999C8.48932 27.5999 2.40039 21.511 2.40039 13.9999C2.40039 6.48883 4.00078 4.5 16.0004 4.5C28 4.5 29.6004 6.48883 29.6004 13.9999Z"
      fill="#3B3B3B"
    />
    <circle cx="7.4375" cy="8.9248" r="4.9375" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="2" />
    <circle cx="24.8013" cy="8.9248" r="4.9375" fill="#3B3B3B" stroke="#AEAEAE" strokeWidth="2" />
    <mask id="path-5-inside-1_135_72429" fill="white">
      <ellipse cx="7.43701" cy="8.9248" rx="1.42529" ry="1.3374" />
    </mask>
    <ellipse cx="7.43701" cy="8.9248" rx="1.42529" ry="1.3374" fill="#3B3B3B" />
    <path
      d="M7.4623 8.9248C7.4623 8.90546 7.46693 8.88569 7.47397 8.87006C7.48044 8.85571 7.48656 8.84986 7.48687 8.84956C7.48699 8.84946 7.47266 8.86221 7.43701 8.86221V11.6622C8.9121 11.6622 10.2623 10.5192 10.2623 8.9248H7.4623ZM7.43701 8.86221C7.40137 8.86221 7.38704 8.84946 7.38715 8.84956C7.38746 8.84986 7.39358 8.85571 7.40005 8.87006C7.4071 8.88569 7.41172 8.90546 7.41172 8.9248H4.61172C4.61172 10.5192 5.96192 11.6622 7.43701 11.6622V8.86221ZM7.41172 8.9248C7.41172 8.94415 7.4071 8.96391 7.40005 8.97954C7.39358 8.9939 7.38746 8.99975 7.38715 9.00005C7.38704 9.00015 7.40137 8.9874 7.43701 8.9874V6.1874C5.96192 6.1874 4.61172 7.33037 4.61172 8.9248H7.41172ZM7.43701 8.9874C7.47266 8.9874 7.48699 9.00015 7.48687 9.00005C7.48656 8.99975 7.48044 8.9939 7.47397 8.97954C7.46693 8.96391 7.4623 8.94415 7.4623 8.9248H10.2623C10.2623 7.33037 8.9121 6.1874 7.43701 6.1874V8.9874Z"
      fill="#AEAEAE"
      mask="url(#path-5-inside-1_135_72429)"
    />
    <mask id="path-7-inside-2_135_72429" fill="white">
      <ellipse cx="24.8008" cy="8.9248" rx="1.42529" ry="1.3374" />
    </mask>
    <ellipse cx="24.8008" cy="8.9248" rx="1.42529" ry="1.3374" fill="#3B3B3B" />
    <path
      d="M24.8261 8.9248C24.8261 8.90546 24.8307 8.88569 24.8377 8.87006C24.8442 8.85571 24.8503 8.84986 24.8506 8.84956C24.8508 8.84946 24.8364 8.86221 24.8008 8.86221V11.6622C26.2759 11.6622 27.6261 10.5192 27.6261 8.9248H24.8261ZM24.8008 8.86221C24.7651 8.86221 24.7508 8.84946 24.7509 8.84956C24.7512 8.84986 24.7574 8.85571 24.7638 8.87006C24.7709 8.88569 24.7755 8.90546 24.7755 8.9248H21.9755C21.9755 10.5192 23.3257 11.6622 24.8008 11.6622V8.86221ZM24.7755 8.9248C24.7755 8.94415 24.7709 8.96391 24.7638 8.97954C24.7574 8.9939 24.7512 8.99975 24.7509 9.00005C24.7508 9.00015 24.7651 8.9874 24.8008 8.9874V6.1874C23.3257 6.1874 21.9755 7.33037 21.9755 8.9248H24.7755ZM24.8008 8.9874C24.8364 8.9874 24.8508 9.00015 24.8506 9.00005C24.8503 8.99975 24.8442 8.9939 24.8377 8.97954C24.8307 8.96391 24.8261 8.94415 24.8261 8.9248H27.6261C27.6261 7.33037 26.2759 6.1874 24.8008 6.1874V8.9874Z"
      fill="#AEAEAE"
      mask="url(#path-7-inside-2_135_72429)"
    />
    <path
      d="M15.625 19.8751C10.925 20.0751 7.08333 22.8751 5.75 24.2501L10 27.6252L20 28.6252L27 23.3751C25.1667 22.1251 20.325 19.6751 15.625 19.8751Z"
      fill="#AEAEAE"
    />
    <circle cx="16" cy="23.5566" r="2.05664" fill="#3B3B3B" />
    <circle cx="10.9717" cy="24.21" r="1.40332" fill="#3B3B3B" />
    <circle cx="21.0283" cy="24.21" r="1.40332" fill="#3B3B3B" />
  </svg>
)
export default ChargerIcon
