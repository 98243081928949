import TravelCourse from '../../shared-components/TravelCourse'
import { useEffect, useState } from 'react'
import { getSpotById, Spot } from '../../repository/spot.repository'
import { useParams } from 'react-router-dom'
import { getTravel, TravelDetailResponse } from '../../repository/travel.repository'
import { useAuth } from '../../auth/Auth'
import { useRecoilState } from 'recoil'
import { myTravelState } from '../../travel/travelState'

const TravelHistoryDetail = () => {
  const [myTravel, setMyTravel] = useRecoilState(myTravelState)
  const [travelSpots, setTravelSpots] = useState<Spot[]>([])
  const { id } = useParams()
  const { currentUser } = useAuth()

  useEffect(() => {
    if (!currentUser || !id) return

    const setTravel = async () => {
      let travel: TravelDetailResponse | null
      if (myTravel.title === '') {
        travel = await getTravel(currentUser.uid, id)
        if (!travel) return
        setMyTravel(travel)
      } else {
        travel = myTravel
      }

      const travelSpots = await Promise.all(
        travel.spots.map(async (spotId) => {
          return await getSpotById(spotId)
        }),
      )

      if (!travelSpots) return

      setTravelSpots(travelSpots.filter((spot) => spot !== null) as Spot[])
    }

    setTravel()
  }, [])

  if (!travelSpots || !id) return null

  return (
    <TravelCourse
      title={myTravel.title}
      travelSpots={travelSpots}
      theme={Object.keys(myTravel.themes)}
      companion={myTravel.companion}
      travelId={id}
      date={myTravel.startAt}
      place={myTravel.place}
    />
  )
}

export default TravelHistoryDetail
