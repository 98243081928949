import Typography from '@mui/material/Typography'
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import colors from '../../../styles/colors'
import getTimeOptions from '../../../utils/getTimeOptions'
import WorkingDayCheckBox from './WorkingDayCheckBox'
import MyStoreTextField from './MyStoreTextField'
import EcaroButton from '../../../shared-components/EcaroButton'
import { StoreInformation } from '../MyStore.entity'
import SearchAddressModal from './SearchAddressModal'
import { ChangeEvent, useEffect, useState } from 'react'
import { getThemes, TravelTheme } from '../../../repository/theme.repository'
import { Category } from '../../../model/category.entity'
import { getCategories } from '../../../repository/category.repository'
import PhotoIcon from '../../../shared-components/Icons/PhotoIcon'

export const STORE_TYPES = ['식당', '카페', '숙소', '레저']
const TIME_OPTIONS = getTimeOptions(8, 22, 30)

interface StoreInformationFormProps {
  storeInformation: StoreInformation
  onChangeStoreInformation: (value: StoreInformation) => void
}

const StoreInformationForm = ({ storeInformation, onChangeStoreInformation }: StoreInformationFormProps) => {
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false)
  const [themes, setThemes] = useState<TravelTheme[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [thumbnail, setThumbnail] = useState('')

  useEffect(() => {
    getThemes().then((travelThemes) => {
      setThemes(travelThemes)
    })

    getCategories().then((categories) => {
      setCategories(categories)
    })
  }, [])
  const onClikSearchAddress = () => {
    setIsAddressModalOpen(true)
  }

  const onCompleteSearchAddress = (value: string) => {
    setIsAddressModalOpen(false)
    onChangeStoreInformation({ ...storeInformation, address: value })
  }

  const onChangeThumbnail = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return

    const imageFile = event.target.files[0]
    const reader = new FileReader()

    reader.addEventListener('load', (e: ProgressEvent<FileReader>) => {
      if (!e || !e.target) return
      if (typeof e.target.result !== 'string') return
      setThumbnail(e.target.result)
    })
    reader.readAsDataURL(imageFile)

    onChangeStoreInformation({ ...storeInformation, thumbnail: imageFile })
  }

  const onChangeDescription = (value: string) => {
    onChangeStoreInformation({ ...storeInformation, description: value })
  }

  return (
    <Box>
      <Typography mb={'16px'}>매장 기본정보</Typography>
      <Box display={'flex'} flexDirection={'column'} gap={'16px'} fontSize={'15px'}>
        <label
          htmlFor={'storeThumbnail'}
          className="flex flex-col items-center justify-center gap-[5px] w-full h-[128px] min-w-[128px] rounded-[4px] border border-base bg-white overflow-hidden"
        >
          {typeof storeInformation.thumbnail === 'string' ? (
            <img
              src={storeInformation.thumbnail}
              alt="img"
              style={{
                objectFit: 'cover',
              }}
            />
          ) : thumbnail ? (
            <img
              src={thumbnail}
              alt="img"
              style={{
                objectFit: 'cover',
              }}
            />
          ) : (
            <>
              <PhotoIcon />
              <Typography fontSize={'15px'} fontWeight={500}>
                사진등록
              </Typography>
            </>
          )}
          <input
            id={'storeThumbnail'}
            className="hidden"
            type="file"
            accept="image/*"
            onChange={(event) => onChangeThumbnail(event)}
          />
        </label>
        <MyStoreTextField
          label="상호명"
          name="name"
          placeholder="상호명을 입력해 주세요."
          value={storeInformation.name}
          onChange={(value) => onChangeStoreInformation({ ...storeInformation, name: value })}
        />
        <Box display={'flex'} gap={'10px'} alignItems={'center'}>
          <TextField
            fullWidth
            label={'매장 주소'}
            name={'address'}
            placeholder={'매장 주소 검색'}
            variant="outlined"
            sx={{
              bgcolor: colors.white,
              fieldset: {
                borderColor: colors.base,
              },
            }}
            onChange={(event) => onChangeStoreInformation({ ...storeInformation, address: event.target.value })}
            onClick={onClikSearchAddress}
            value={storeInformation.address}
            inputProps={{ readOnly: true }}
          />
          <EcaroButton color="primary" text="주소검색" onClick={onClikSearchAddress} />
          <SearchAddressModal isOpen={isAddressModalOpen} onCompleteSearchAddress={onCompleteSearchAddress} />
        </Box>
        <MyStoreTextField
          label="상세 주소"
          name="detailAddress"
          placeholder="상세주소를 입력해 주세요."
          value={storeInformation.detailAddress}
          onChange={(value) => onChangeStoreInformation({ ...storeInformation, detailAddress: value })}
        />
        <FormControl fullWidth>
          <InputLabel id="category">매장 카테고리</InputLabel>
          <Select
            labelId="category"
            name="category"
            label="매장 카테고리"
            value={storeInformation.category}
            sx={{
              bgcolor: colors.white,
              fieldset: {
                borderColor: colors.base,
              },
            }}
            onChange={(event) => onChangeStoreInformation({ ...storeInformation, category: event.target.value })}
          >
            {categories.map((category) => (
              <MenuItem value={category.id} key={`category-${category.id}`}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="theme">매장 테마</InputLabel>
          <Select
            labelId="theme"
            name="theme"
            label="매장 테마"
            value={storeInformation.theme}
            sx={{
              bgcolor: colors.white,
              fieldset: {
                borderColor: colors.base,
              },
            }}
            onChange={(event) => onChangeStoreInformation({ ...storeInformation, theme: event.target.value })}
          >
            {themes.map((theme) => (
              <MenuItem value={theme.id} key={`storeTheme-${theme.id}`}>
                {theme.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <MyStoreTextField
          label="연락처"
          name="phoneNumber"
          value={storeInformation.phoneNumber}
          placeholder="연락받으실 연락처를 입력해 주세요."
          onChange={(value) => onChangeStoreInformation({ ...storeInformation, phoneNumber: value })}
        />
        {/*<Box>*/}
        {/*  <RoundCheckBoxWithLabel*/}
        {/*    label="다이렉트콜 기능 사용"*/}
        {/*    checked={storeInformation.useDirectCall}*/}
        {/*    onChange={(value) => onChangeStoreInformation({ ...storeInformation, useDirectCall: value })}*/}
        {/*  />*/}
        {/*  <Typography marginTop={'10px'} fontSize={'12px'} fontWeight={500} textAlign={'right'}>*/}
        {/*    해당 번호로 고객과 문의통화가 가능합니다.*/}
        {/*  </Typography>*/}
        {/*</Box>*/}
        <MyStoreTextField
          label="사업자 등록번호"
          name="businessRegistrationNumber"
          value={storeInformation.businessRegistrationNumber}
          placeholder="사업자 등록번호를 입력해 주세요. (선택)"
          onChange={(value) => onChangeStoreInformation({ ...storeInformation, businessRegistrationNumber: value })}
        />
        <Box display={'flex'} flexDirection={'column'}>
          <TextField
            label={'매장 소개'}
            id="description"
            multiline
            rows={2}
            value={storeInformation.description}
            placeholder="매장을 소개해 주세요. (선택)"
            sx={{
              bgcolor: colors.white,
            }}
            onChange={(event) => onChangeDescription(event.target.value)}
          />
        </Box>
        <WorkingDayCheckBox
          workingDays={storeInformation.workingDay}
          onChange={(value) => onChangeStoreInformation({ ...storeInformation, workingDay: value })}
        />
        <Box display={'flex'} flexDirection={'row'} gap={'5px'}>
          <FormControl sx={{ width: '50%' }}>
            <InputLabel id="openAt">오픈시간</InputLabel>
            <Select
              fullWidth
              labelId="thopenAteme"
              name="openAt"
              label="오픈시간"
              value={storeInformation.openAt}
              sx={{
                bgcolor: colors.white,
                fieldset: {
                  borderColor: colors.base,
                },
              }}
              onChange={(event) => onChangeStoreInformation({ ...storeInformation, openAt: event.target.value })}
            >
              {TIME_OPTIONS.map((time) => (
                <MenuItem value={time.value} key={`storeTheme-${time.value}`}>
                  {time.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: '50%' }}>
            <InputLabel id="closeAt">종료시간</InputLabel>
            <Select
              fullWidth
              labelId="closeAt"
              name="closeAt"
              label="종료시간"
              value={storeInformation.closeAt}
              sx={{
                bgcolor: colors.white,
                fieldset: {
                  borderColor: colors.base,
                },
              }}
              onChange={(event) => onChangeStoreInformation({ ...storeInformation, closeAt: event.target.value })}
            >
              {TIME_OPTIONS.map((time) => (
                <MenuItem value={time.value} key={`startAt-${time.value}`}>
                  {time.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  )
}

export default StoreInformationForm
