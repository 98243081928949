import { SvgProps } from '../../types/svgProps'

const BellIcon = ({ color = '#FDBB6F', className = '' }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M17.1666 14.1667H18.8333V15.8334H2.16663L2.16663 14.1667H3.83329L3.83329 8.33341C3.83329 6.5653 4.53567 4.86961 5.78591 3.61937C7.03616 2.36913 8.73185 1.66675 10.5 1.66675C12.2681 1.66675 13.9638 2.36913 15.214 3.61937C16.4642 4.86961 17.1666 6.5653 17.1666 8.33341L17.1666 14.1667ZM7.99996 17.5001H13V19.1667H7.99996V17.5001Z"
        fill={color}
      />
    </svg>
  )
}

export default BellIcon
