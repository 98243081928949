import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import TravelCourse from '../../shared-components/TravelCourse'
import { TravelSpot } from '../travelSpot/TravelSpot.entity'
import { getSpotById, getSpotsByCityAndThemeAndDate } from '../../repository/spot.repository'
import dayjs from 'dayjs'
import { BusinessDay } from '../../types/SpotResponse'
import { getThemes } from '../../repository/theme.repository'
import { useRecoilState } from 'recoil'
import { myTravelState } from '../../travel/travelState'

// const Loading = () => (
//   <>
//     <div className={'text-xl font-semibold leading-normal w-full py-5 px-6'}>
//       <div className="text-primary50 mb-2">알뜰최저가</div>
//       <div className=" ">맞춤 여행을 만들고 있어요...</div>
//     </div>
//     <div className={'flex justify-center items-center w-full h-full mt-[20vh]'}>
//       <img src={'/animations/Loading.gif'} alt={'loading'} className={'w-1/2 h-1/2'} />
//     </div>
//   </>
// )

const days: BusinessDay[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

const convertToDayOfWeek = (day: number): BusinessDay => {
  return days[day]
}

const TravelPlanRecommended = () => {
  const location = useLocation()
  const { selectedValue } = location.state
  // const [isLoading, setIsLoading] = useState(true)
  const [spots, setSpots] = useState<TravelSpot[]>([])
  const [myTravel, setMyTravel] = useRecoilState(myTravelState)

  useEffect(() => {
    const init = async () => {
      const { location, theme, date } = selectedValue

      if (myTravel.title === '') {
        getThemes().then((travelThemes) => {
          getSpotsByCityAndThemeAndDate(
            location,
            travelThemes.find((travelTheme) => travelTheme.name === theme[0])!.id,
            convertToDayOfWeek(dayjs(date, 'YYYY-MM-DD').day()),
          ).then((spots) => {
            setMyTravel({
              title: '선택하신 테마에 맞춰 여행을 추천드려요',
              companion: selectedValue.companion,
              startAt: selectedValue.date,
              themes: {
                [theme[0]]: true,
              },
              place: selectedValue.location,
              spots: spots.map((spot) => spot.id),
            })
            setSpots(spots)
          })
        })
      } else {
        const travelSpots = await Promise.all(
          myTravel.spots.map(async (spotId) => {
            return await getSpotById(spotId)
          }),
        )

        setSpots(travelSpots.filter((spot) => spot !== null) as TravelSpot[])
      }
    }

    init()
  }, [])

  // const recommend = () => {
  //   //TODO: 여행 추천 로직 실행
  //   setIsLoading(false)
  // }
  // setTimeout(recommend, 3000) //TODO: 여행 추천 로직 실행시 삭제

  // if (isLoading) {
  //   return <Loading />
  // }

  return (
    <TravelCourse
      travelId={''}
      title={'선택하신 테마에 맞춰 여행을 추천드려요'}
      travelSpots={spots}
      theme={Object.keys(myTravel.themes)}
      companion={myTravel.companion}
      date={myTravel.startAt}
      place={myTravel.place}
    />
  )
}

export default TravelPlanRecommended
