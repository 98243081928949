import { Box, Button, Typography } from '@mui/material'
import MyStoreAccordian from '../myStore/fragments/MyStoreAccordian'
import colors from '../../styles/colors'
import { faqData } from './fragments/faqData'

const FAQ = () => {
  const onClickHelp = () => {
    // TODO: 1:1 채팅상담 기능 구현
    alert('준비중입니다.')
  }
  return (
    <Box>
      <Typography
        color={'#333'}
        fontSize={'20px'}
        fontWeight={600}
        lineHeight={'24px'}
        letterSpacing={'-0.4px'}
        my={'24px'}
        mx={'22px'}
      >
        자주 묻는 질문
      </Typography>
      {faqData.map((item) => (
        <MyStoreAccordian
          key={item.id}
          title={item.question}
          content={
            <Typography
              color={'#666'}
              fontSize={'14px'}
              fontWeight={500}
              lineHeight={'150%'}
              sx={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                wordBreak: 'keep-all',
              }}
            >
              {item.answer}
            </Typography>
          }
        />
      ))}
      <Button
        variant="contained"
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          borderRadius: '0px',
          minHeight: '56px',
          background: 'black',
          maxWidth: '639px',
        }}
        onClick={onClickHelp}
      >
        <Typography
          color={colors.white}
          fontSize={'16px'}
          fontWeight={500}
          lineHeight={'20px'}
          letterSpacing={'-0.32px'}
        >
          1:1 채팅상담
        </Typography>
      </Button>
    </Box>
  )
}
export default FAQ
