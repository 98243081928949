import { Box, Typography } from '@mui/material'
import CheckFillIcon from '../../../shared-components/Icons/CheckFillIcon'
import colors from '../../../styles/colors'

interface RoundCheckBoxWithLabelProps {
  label: string
  checked: boolean
  onChange: (checked: boolean) => void
}

const RoundCheckBoxWithLabel = ({ label, checked, onChange = () => null }: RoundCheckBoxWithLabelProps) => {
  const onChangeChecked = (checked: boolean) => {
    onChange(checked)
  }
  return (
    <Box className="flex justify-end">
      <label>
        <input
          type="checkbox"
          className="hidden"
          checked={checked}
          onChange={(event) => onChangeChecked(event.target.checked)}
        ></input>
        <CheckFillIcon color={checked ? colors.primary50 : colors.gray30} className="inline-block mb-[3px]" />
        <Typography ml={'8px'} display={'inline-block'} fontWeight={500}>
          {label}
        </Typography>
      </label>
    </Box>
  )
}

export default RoundCheckBoxWithLabel
