interface FaqDataType {
  id: number
  question: string
  answer: string
}

export const faqData: FaqDataType[] = [
  {
    id: 1,
    question: 'Q.자주 묻는 질문',
    answer: '답변내용입니다.',
  },
]
