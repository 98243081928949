import colors from '../../styles/colors'

const TourStepIcon = ({ color = colors.primary50, className }: { color?: string; className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M8 0C3.58862 0 0 3.58862 0 8C0 12.4114 3.58862 16 8 16C12.4114 16 16 12.4114 16 8C16 3.58862 12.4114 0 8 0Z"
        fill={color}
      />
      <path
        d="M7.96103 11.2001L3.23907 6.59992L4.28022 5.6001L7.96103 9.20045L11.6418 5.6001L12.683 6.59992L7.96103 11.2001Z"
        fill="white"
      />
    </svg>
  )
}

export default TourStepIcon
