/* global google */
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { ReactNode } from 'react'

interface CustomMapProps {
  center?: google.maps.LatLngLiteral
  zoom?: number
  children?: ReactNode
  onLoad?: (map: google.maps.Map) => void | Promise<void>
  onUnmount?: (map: google.maps.Map) => void | Promise<void>
}

const getGoogleMapApiKey = (): string => {
  if (process.env.NODE_ENV === 'production') {
    return process.env.REACT_APP_PRODUCTION_GOOGLE_MAP_KEY as string
  }

  return process.env.REACT_APP_DEVELOP_GOOGLE_MAP_KEY as string
}

const CustomMap = (props: CustomMapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: getGoogleMapApiKey(),
    region: 'KR',
    language: 'ko',
  })

  if (!isLoaded) {
    return null
  }

  return (
    <GoogleMap
      mapContainerClassName={'w-full h-full'}
      center={props.center}
      zoom={props.zoom}
      options={{
        disableDefaultUI: true,
        zoomControl: true,
      }}
      onLoad={props.onLoad}
      onUnmount={props.onUnmount}
    >
      {props.children}
    </GoogleMap>
  )
}

export default CustomMap
