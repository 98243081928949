import { get, getDatabase, push, query, ref, remove } from 'firebase/database'
import { objectToArray } from '../utils/objectToArray'

interface AddTravelRequest extends TravelDetailResponse {}

export const addTravel = async (userId: string, travel: AddTravelRequest) => {
  const db = getDatabase()

  return push(ref(db, `travels/${userId}`), travel)
}

export const removeTravel = async (userId: string, travelId: string) => {
  const db = getDatabase()

  return remove(ref(db, `travels/${userId}/${travelId}`))
}

export interface TravelDetailResponse {
  companion: string
  spots: string[]
  startAt: string
  place: string
  themes: {
    [key: string]: boolean
  }
  title: string
}

export interface Spots {
  [key: string]: boolean
}

export const getTravel = async (userId: string, travelId: string) => {
  const db = getDatabase()

  return get(query(ref(db, `travels/${userId}/${travelId}`))).then((snapshot) => {
    if (snapshot.exists()) {
      return snapshot.val() as TravelDetailResponse
    }

    return null
  })
}

export interface MyTravelResponse extends TravelDetailResponse {
  id: string
}

export const getMyTravel = async (userId: string) => {
  const db = getDatabase()

  return get(query(ref(db, `travels/${userId}`))).then((snapshot) => {
    if (snapshot.exists()) {
      return objectToArray<MyTravelResponse>(snapshot.val())
    }

    return null
  })
}
