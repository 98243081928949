import { getDatabase, onValue, ref } from 'firebase/database'
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { Spot } from '../repository/spot.repository'
import { useAuth } from '../auth/Auth'
import { getLikeByUid, setLikeByUid } from '../repository/like.repository'
import { Like } from '../model/user.entity'

interface LikeContextType {
  spots: Spot[]
  addLike: (spotId: string) => void
  removeLike: (spotId: string) => void
}

export const LikeContext = createContext<LikeContextType>({
  spots: [],
  addLike: () => null,
  removeLike: () => null,
})

export const LikeProvider = ({ children }: { children: ReactNode }) => {
  const [spots, setSpots] = useState<Spot[]>([])
  const { currentUser } = useAuth()

  useEffect(() => {
    const getSpotsByLikes = async (likes: Like) => {
      if (likes) {
        const spots = await Promise.all(
          Object.keys(likes).map((key) => {
            if (likes[key]) {
              return getLikeByUid(key)
            }
          }),
        )

        return spots.filter((item) => item !== undefined && item !== null) as Spot[]
      }
      return []
    }

    onValue(ref(getDatabase(), `users/${currentUser?.uid}/likes`), (snapshot) => {
      const data = snapshot.val()
      if (data) {
        getSpotsByLikes(data).then((spots) => {
          setSpots([...spots])
        })
      }
    })
  }, [])
  const addLike = (spotId: string) => {
    return setLikeByUid(currentUser!.uid, spotId, true)
  }

  const removeLike = (spotId: string) => {
    return setLikeByUid(currentUser!.uid, spotId, false)
  }

  const obj = useMemo(() => ({ spots, addLike, removeLike }), [spots])

  return <LikeContext.Provider value={obj}>{children}</LikeContext.Provider>
}

export const useLike = () => useContext(LikeContext)
