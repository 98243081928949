import { Box } from '@mui/material'
import { ChargerInformation } from '../MyStore.entity'
import ChargerLocationRadios from './ChargerLocationRadios'
import ChargerQuantity from './ChargerQuantity'
import { EVChargerType } from '../../../types/EVCharger'

interface ChargerInformationFormProps {
  chargerInformation: ChargerInformation
  onChangeChargerInformation: (value: ChargerInformation) => void
}

const ChargerInformationForm = ({ chargerInformation, onChangeChargerInformation }: ChargerInformationFormProps) => {
  const onChangeChargerLocation = (value: string) => {
    onChangeChargerInformation({ ...chargerInformation, chargerLocation: value })
  }

  // const onChangeExtraDescription = (value: string) => {
  //   onChangeChargerInformation({ ...chargerInformation, extraDescription: value })
  // }

  const onChangeChargerQuantity = (type: EVChargerType, quantity: number) => {
    const newEVCharger = chargerInformation.EVCharger.map((charger) => {
      if (charger.value === type) return { ...charger, quantity: quantity }
      else return charger
    })
    onChangeChargerInformation({ ...chargerInformation, EVCharger: newEVCharger })
  }

  return (
    <>
      <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
        <ChargerLocationRadios
          chargerLocation={chargerInformation.chargerLocation}
          onChangeChargerLocation={onChangeChargerLocation}
        />
        <ChargerQuantity EVCharger={chargerInformation.EVCharger} onChangeChargerQuantity={onChangeChargerQuantity} />
        {/*<Box display={'flex'} flexDirection={'column'}>*/}
        {/*  <label className="inline text-[12px] mb-[4px]" htmlFor="extraDescription">*/}
        {/*    부가설명*/}
        {/*  </label>*/}
        {/*  <TextField*/}
        {/*    id="extraDescription"*/}
        {/*    multiline*/}
        {/*    rows={2}*/}
        {/*    placeholder="ex) 매장에서 반경 300m내 도보로 이용 가능한*/}
        {/*  김녕 해수욕장 공용 충전시설이 있습니다."*/}
        {/*    sx={{*/}
        {/*      bgcolor: colors.white,*/}
        {/*    }}*/}
        {/*    onChange={(event) => onChangeExtraDescription(event.target.value)}*/}
        {/*  />*/}
        {/*</Box>*/}
      </Box>
    </>
  )
}

export default ChargerInformationForm
