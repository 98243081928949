import { Box, Button, Typography } from '@mui/material'
import BellIcon from '../../shared-components/Icons/BellIcon'
import colors from '../../styles/colors'
import InfoStrokeIcon from '../../shared-components/Icons/InfoStrokeIcon'
import MyStoreAccordian from './fragments/MyStoreAccordian'
import StoreInformationForm from './fragments/StoreInformationForm'
import ServiceInformationForm from './fragments/ServiceInformationForm'
import { FormEvent, useEffect, useState } from 'react'
import { ChargerInformation, ServiceInformation, StoreInformation } from './MyStore.entity'
import ChargerInformationForm from './fragments/ChargerInformationForm'
import { EV_CHARGERS, EVCharger } from '../../types/EVCharger'
import { useRecoilState } from 'recoil'
import { myStoreModalState } from '../../store/modalState'
import MyStoreModal from './fragments/MyStoreModal'
import { useAuth } from '../../auth/Auth'
import { getUserByUid, setSpotIdByUid } from '../../repository/user.repository'
import { BusinessDay, BusinessDays, ChargingType } from '../../types/SpotResponse'
import {
  addSpot,
  AddSpotRequest,
  convertDayOfWeekToKorean,
  getSpotResponseById,
  updateSpot,
} from '../../repository/spot.repository'
import { addService, AddServiceRequest, getServiceList, removeServices } from '../../repository/service.repository'
import { GeometryResponse } from '../../model/spot.entity'
import { v4 as uuidv4 } from 'uuid'
import { getDownloadURL, getStorage, ref, uploadBytes } from '@firebase/storage'
import CompleteModal from '../../shared-components/CompleteModal'
import { useNavigate } from 'react-router-dom'

const storeInformationDefaultValue: StoreInformation = {
  name: '',
  address: '',
  detailAddress: '',
  category: '',
  theme: '',
  phoneNumber: '',
  useDirectCall: false,
  businessRegistrationNumber: '',
  workingDay: [],
  openAt: '',
  closeAt: '',
  thumbnail: null,
  description: '',
}

const serviceInformationDefaultValue: ServiceInformation = [{ id: '0', image: null, name: '', price: 0 }]

const EVChargerWithQuantityDefaultValue = EV_CHARGERS.map((charger: EVCharger) => {
  return { ...charger, quantity: 0 }
})

const chargerInformaionDefaultValue: ChargerInformation = {
  chargerLocation: '매장 내구비',
  extraDescription: '',
  EVCharger: EVChargerWithQuantityDefaultValue,
}

const MyStore = () => {
  const [storeInformation, setStoreInformation] = useState<StoreInformation>(storeInformationDefaultValue)
  const [serviceInformation, setServiceInformation] = useState<ServiceInformation>(serviceInformationDefaultValue)
  const [chargerInformation, setChargerInformation] = useState<ChargerInformation>(chargerInformaionDefaultValue)
  const [myStoreModalOpen, setMyStoreModalOpen] = useRecoilState(myStoreModalState)
  const { currentUser } = useAuth()
  const [completeModalStatus, setCompleteModalStatus] = useState(false)
  const navigate = useNavigate()
  const [spotId, setSpotId] = useState('')

  useEffect(() => {
    if (currentUser === null) return

    const fetchData = async () => {
      const user = await getUserByUid(currentUser!.uid)

      if (!user) return
      if (!user.spotId) return

      const spot = await getSpotResponseById(user.spotId)
      setSpotId(user.spotId)

      if (!spot) return

      console.log(spot)

      setStoreInformation({
        name: spot.name,
        address: `${spot.city} ${spot.street}`,
        detailAddress: spot.detailedAddress,
        category: spot.category,
        theme: Object.keys(spot.themes)[0],
        phoneNumber: spot.phoneNumber,
        useDirectCall: false,
        businessRegistrationNumber: spot.businessNumber,
        workingDay: Object.keys(spot.businessDays)
          .filter((day) => spot.businessDays[day as BusinessDay])
          .map((day) => {
            return convertDayOfWeekToKorean(day)
          }),
        openAt: spot.startAt,
        closeAt: spot.endAt,
        thumbnail: spot.thumbnail,
        description: spot.description,
      })

      const serviceList = await getServiceList(spot.id)
      if (!serviceList) return

      if (serviceList.length === 0) {
        setServiceInformation(serviceInformationDefaultValue)
      } else {
        setServiceInformation(
          await Promise.all(
            serviceList.map(async (service) => ({
              id: service.id,
              image: service.image,
              name: service.name,
              price: service.price,
            })),
          ),
        )
      }

      setChargerInformation({
        chargerLocation: spot.chargerLocation,
        extraDescription: '',
        EVCharger: EV_CHARGERS.map((charger) => {
          return {
            ...charger,
            quantity: spot.chargingStations[charger.value],
          }
        }),
      })
    }

    fetchData()
  }, [])

  const onChangeStoreInformation = (value: StoreInformation) => {
    setStoreInformation(value)
  }

  const onChangeServiceInformation = (value: ServiceInformation) => {
    setServiceInformation(value)
  }

  const onChangeChargerInformation = (value: ChargerInformation) => {
    setChargerInformation(value)
  }

  const onCloseMyStoreModal = () => {
    setMyStoreModalOpen(false)
  }

  /*
  "EVCharger":[{"name":"AC 단상","value":"AC_1","chargerType":"완속","quantity":1},{"name":"AC 3상","value":"AC_3","chargerType":"완속","quantity":0},{"name":"DC 차데모","value":"DC_CHADEMO","chargerType":"급속","quantity":0},{"name":"DC 콤보","value":"DC_COMBO","chargerType":"완속 + 급속","quantity":0},{"name":"테슬라","value":"TESLA","chargerType":"급속","quantity":0}]}}
   */
  const onSubmit = async (event: FormEvent) => {
    event.preventDefault()
    if (currentUser === null) return

    // 유효성 검사
    if (!storeInformation.thumbnail) {
      alert('매장 사진을 등록해주세요.')
      return
    }

    if (!storeInformation.address) {
      alert('매장 주소를 입력해주세요.')
      return
    }

    if (!storeInformation.name) {
      alert('매장 이름을 입력해주세요.')
      return
    }

    if (!storeInformation.theme) {
      alert('매장 테마를 선택해주세요.')
      return
    }

    if (!storeInformation.category) {
      alert('매장 카테고리를 선택해주세요.')
      return
    }

    if (!storeInformation.phoneNumber) {
      alert('매장 전화번호를 입력해주세요.')
      return
    }

    // thumbnail 업로드 후, url을 받아온다.
    const storage = getStorage()
    const fileRef = ref(storage, `spot/${currentUser!.uid}/thumbnail/${uuidv4()}`)
    let thumbnail
    if (storeInformation.thumbnail instanceof File) {
      const arrayBuffer = await storeInformation.thumbnail!.arrayBuffer()
      const uploadResult = await uploadBytes(fileRef, arrayBuffer)
      thumbnail = await getDownloadURL(uploadResult.ref)
    } else {
      thumbnail = storeInformation.thumbnail
    }

    // latitude, longitude 데이터를 받아오기
    const data: GeometryResponse = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${storeInformation.address}&key=${process.env.REACT_APP_PRODUCTION_GEOMETRY_API_KEY}`,
    ).then((response) => response.json())
    const latitude = data.results[0].geometry.location.lat
    const longitude = data.results[0].geometry.location.lng

    // businessDays 데이터를 받아오기
    const businessDays: BusinessDays = {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    }
    const convertKoreanToDayOfWeek = (dayOfWeek: string): BusinessDay => {
      const daysOfWeekMap: Record<string, BusinessDay> = {
        일: 'sunday',
        월: 'monday',
        화: 'tuesday',
        수: 'wednesday',
        목: 'thursday',
        금: 'friday',
        토: 'saturday',
      }

      return daysOfWeekMap[dayOfWeek]
    }

    storeInformation.workingDay.forEach((dayOfWeek) => {
      businessDays[convertKoreanToDayOfWeek(dayOfWeek)] = true
    })

    // chargingStations 데이터를 받아오기
    const chargingStations: ChargingType = {
      AC_1: 0,
      AC_3: 0,
      DC_CHADEMO: 0,
      DC_COMBO: 0,
      TESLA: 0,
    }

    chargerInformation.EVCharger.forEach((charger) => {
      chargingStations[charger.value] = charger.quantity
    })

    const services = serviceInformation.filter((service) => service.name !== '')

    const spot: AddSpotRequest = {
      name: storeInformation.name,
      city: storeInformation.address.split(' ')[0],
      street: storeInformation.address
        .split(' ')
        .filter((_, index) => index !== 0)
        .join(' '),
      detailedAddress: storeInformation.detailAddress,
      businessDays,
      startAt: storeInformation.openAt,
      endAt: storeInformation.closeAt,
      description: storeInformation.description,
      latitude,
      longitude,
      phoneNumber: storeInformation.phoneNumber,
      themes: {
        [storeInformation.theme]: true,
      },
      category: storeInformation.category,
      chargingStations,
      thumbnail,
      chargerLocation: chargerInformation.chargerLocation,
      businessNumber: storeInformation.businessRegistrationNumber ?? '',
    }

    if (spotId) {
      await updateSpot(spotId, spot)
      await removeServices(spotId)
      await Promise.all(
        services
          .filter((service) => service.image !== null)
          .map(async (service) => {
            // image를 업로드하고, url을 받아온다.
            const serviceRef = ref(storage, `service/${currentUser!.uid}/image/${uuidv4()}`)
            let image
            if (service.image instanceof File) {
              const serviceImageBuffer = await service.image!.arrayBuffer()
              const uploadResult = await uploadBytes(serviceRef, serviceImageBuffer)
              image = await getDownloadURL(uploadResult.ref)
            } else {
              image = service.image
            }

            const request: AddServiceRequest = {
              name: service.name,
              image: image ?? '',
              price: service.price,
              spotId: spotId,
            }

            addService(request)
          }),
      )

      setCompleteModalStatus(true)

      return
    }

    const thenableReference = await addSpot(spot)

    if (!thenableReference.key) {
      throw new Error('spotId가 없습니다.')
    }

    await Promise.all(
      services
        .filter((service) => service.image !== null)
        .map(async (service) => {
          // image를 업로드하고, url을 받아온다.
          const serviceRef = ref(storage, `service/${currentUser!.uid}/image/${uuidv4()}`)
          let image
          if (service.image instanceof File) {
            const serviceImageBuffer = await service.image!.arrayBuffer()
            const uploadResult = await uploadBytes(serviceRef, serviceImageBuffer)
            image = await getDownloadURL(uploadResult.ref)
          } else {
            image = service.image
          }

          const request: AddServiceRequest = {
            name: service.name,
            image: image ?? '',
            price: service.price,
            spotId: thenableReference.key!,
          }

          addService(request)
        }),
    )

    await setSpotIdByUid(currentUser!.uid, thenableReference.key!)

    setCompleteModalStatus(true)

    // TODO: 실패했을 때 롤백을 해야한다.
  }

  const handleOnCloseCompleteModal = () => {
    navigate(-1)
  }

  const handleOnClickConfirm = () => {
    navigate(-1)
  }
  return (
    <>
      <Box padding={'24px'}>
        <Typography variant="h3" fontSize={'24px'} fontWeight={500} lineHeight={1.4}>
          원할한 서비스를 위해
          <br />
          시설정보를 등록해 주세요.
        </Typography>
        <Box mt={'20px'} display={'flex'} padding={'12px'} gap={'8px'} alignItems={'center'} bgcolor={colors.gray10}>
          <BellIcon className="shrinkg-0" />
          <Typography
            fontWeight={500}
            fontSize={'12px'}
            lineHeight={1.3}
            sx={{
              wordWrap: 'break-word',
              wordBreak: 'keep-all',
            }}
          >
            심사는 최대 5일이 소요됩니다.
            <br />
            내용을 모두 입력하면 심사통과가 더욱 빨라집니다!
          </Typography>
        </Box>
        <Box mt={'8px'} display={'flex'} padding={'12px'} gap={'8px'} alignItems={'center'} bgcolor={colors.gray10}>
          <InfoStrokeIcon className="shrink-0 w-[20px]" />
          <Typography
            fontWeight={500}
            fontSize={'12px'}
            lineHeight={1.3}
            sx={{
              wordWrap: 'break-word',
              wordBreak: 'keep-all',
            }}
          >
            충전기를 직접 구비하고 계시거나 반경 300M 이내에 공용 충전시설이 있는 매장만 가입이 가능합니다.
          </Typography>
        </Box>
      </Box>

      <Box paddingBottom={'56px'} component={'form'} noValidate autoComplete="off">
        <MyStoreAccordian
          title="매장 정보"
          content={
            <StoreInformationForm
              storeInformation={storeInformation}
              onChangeStoreInformation={onChangeStoreInformation}
            />
          }
        />
        <MyStoreAccordian
          title="서비스 정보"
          content={
            <ServiceInformationForm
              serviceInformation={serviceInformation}
              onChangeServiceInformation={onChangeServiceInformation}
            />
          }
        />
        <MyStoreAccordian
          title="충전기 정보"
          content={
            <ChargerInformationForm
              chargerInformation={chargerInformation}
              onChangeChargerInformation={onChangeChargerInformation}
            />
          }
        />
        <Button
          type="button"
          variant="contained"
          color="primary"
          sx={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            borderRadius: '0px',
            minHeight: '56px',
            background: 'black',
            maxWidth: '639px',
          }}
          onClick={onSubmit}
        >
          매장 등록하기
        </Button>
      </Box>
      <MyStoreModal open={myStoreModalOpen} onClose={onCloseMyStoreModal} />
      <CompleteModal
        title={'매장 등록이 완료되었습니다.'}
        content={''}
        onClickConfirm={handleOnClickConfirm}
        open={completeModalStatus}
        onClose={handleOnCloseCompleteModal}
      />
    </>
  )
}

export default MyStore
