const colors = {
  white: '#fff',
  black: '#000',
  base: '#DEDDDD',
  gray90: '#1C1E20',
  gray80: '#464A4D',
  gray70: '#5D6064',
  gray60: '#74777B',
  gray50: '#8C8F91',
  gray40: '#A5A6A8',
  gray30: '#BEBEBF',
  gray20: '#D5D5D5',
  gray10: '#ECECEC',
  gray05: '#F9F9F9',

  primary90: '#4D0023',
  primary80: '#6F0033',
  primary70: '#910043',
  primary60: '#B30454',
  primary50: '#D5116B',
  primary40: '#EB3C8D',
  primary30: '#EA5A9D',
  primary20: '#FF86BE',
  primary10: '#FFB7D8',
  primary05: '#FFE9F3',

  secondary90: '#2B2A5B',
  secondary80: '#3E3D78',
  secondary70: '#545295',
  secondary60: '#6C6BB2',
  secondary50: '#8785D0',
  secondary40: '#A4A3ED',
  secondary30: '#BCBBFF',
  secondary20: '#D0CFFF',
  secondary10: '#E4E4FF',
  secondary05: '#F8F8FF',
}

export default colors
