import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from './auth/Auth'

const PrivateRoute = () => {
  const { currentUser } = useAuth()

  if (currentUser) {
    return <Outlet />
  }

  return <Navigate replace to="/login" />
}

export default PrivateRoute
