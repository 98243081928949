import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioProps,
  TextField,
  Typography,
} from '@mui/material'
import { ChangeEvent, useState } from 'react'
import colors from '../../styles/colors'
import ActiveRadioIcon from '../../images/activeRadio.png'
import { useNavigate } from 'react-router-dom'
import RoundCheckBoxWithLabel from '../myStore/fragments/RoundedCheckBoxWithLabel'
import { deleteUser } from '@firebase/auth'
import { useAuth } from '../../auth/Auth'
import { deleteUserByUid } from '../../repository/user.repository'

interface RadioGroupListType {
  id: number
  value: string
  label: string
}

const radioGroupList: RadioGroupListType[] = [
  {
    id: 1,
    value: '나에게 맞는 여행 찾을 수 없어서',
    label: '나에게 맞는 여행 찾을 수 없어서',
  },
  {
    id: 2,
    value: '추천 결과가 마음에 들지 않음',
    label: '추천 결과가 마음에 들지 않음',
  },
  {
    id: 3,
    value: '회원혜택이 부족해서',
    label: '회원혜택이 부족해서',
  },
  {
    id: 4,
    value: '서비스 이용이 불편해서',
    label: '서비스 이용이 불편해서',
  },
  {
    id: 5,
    value: '장애가 많아서',
    label: '장애가 많아서',
  },
  {
    id: 6,
    value: '더 이상 서비스 이용을 하지 않아서',
    label: '더 이상 서비스 이용을 하지 않아서',
  },
  {
    id: 7,
    value: '다른 아이디로 가입하기 위해서',
    label: '다른 아이디로 가입하기 위해서',
  },
  {
    id: 8,
    value: '기타',
    label: '기타',
  },
]

const RadioButton = (props: RadioProps) => (
  <Radio disableRipple checkedIcon={<img src={ActiveRadioIcon} alt={'active radio'} />} {...props} />
)

const Resign = () => {
  const [resignReason, setResignReason] = useState('나에게 맞는 여행 찾을 수 없어서')
  const [isConfirmed, setIsConfirmed] = useState(false)
  const navigate = useNavigate()
  const auth = useAuth()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setResignReason((event.target as HTMLInputElement).value)
  }

  const onClickCancel = () => {
    navigate(-1)
  }

  const onClickResign = async () => {
    localStorage.removeItem('memberType')
    await deleteUser(auth.currentUser!)
    await deleteUserByUid(auth.currentUser!.uid)
    navigate('/login')
  }

  return (
    <Box sx={{ paddingBottom: '93px' }}>
      <Box sx={{ padding: '22px 24px 0 24px' }}>
        <FormControl>
          <RadioGroup
            value={resignReason}
            onChange={handleChange}
            sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            {radioGroupList.map((item) => (
              <FormControlLabel
                key={item.id}
                sx={{
                  height: '24px',
                  '.MuiFormControlLabel-label': {
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    letterSpacing: '-0.32px',
                    color: colors.gray90,
                  },
                }}
                value={item.value}
                control={<RadioButton />}
                label={item.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <TextField
          fullWidth
          multiline
          rows={5}
          variant="outlined"
          placeholder={'탈퇴사유를 입력해주세요.'}
          sx={{
            margin: '18px 0 28px 0',
            '.MuiOutlinedInput-root': {
              padding: '16px 12px',
              height: '143px',
            },
          }}
        />
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '28px', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '14px', fontWeight: '500', lineHeight: '18px', letterSpacing: '-0.28px' }}>
            회원탈퇴를 하는 즉시 모든 개인정보는 삭제됩니다.
          </Typography>
          <RoundCheckBoxWithLabel
            checked={isConfirmed}
            label={'위 안내사항을 모두 확인하였습니다.'}
            onChange={() => setIsConfirmed(!isConfirmed)}
          />
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: '639px',
          position: 'fixed',
          bottom: 0,
          width: '100%',
          height: '56px',
          display: 'flex',
        }}
      >
        <Button
          disableRipple={true}
          onClick={onClickCancel}
          variant="contained"
          color="primary"
          sx={{
            width: '50%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '0px',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '20px',
              letterSpacing: '-0.32px',
              color: colors.white,
            }}
          >
            취소
          </Typography>
        </Button>
        <Button
          disabled={!isConfirmed}
          onClick={onClickResign}
          sx={{
            width: '50%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '0px',
            backgroundColor: !isConfirmed ? colors.gray30 : colors.black,
            '&:hover': {
              backgroundColor: colors.gray90,
            },
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '20px',
              letterSpacing: '-0.32px',
              color: colors.white,
            }}
          >
            탈퇴하기
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}

export default Resign
