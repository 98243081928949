const SuccessGreenIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1626 32.2706C12.3402 31.7092 9.74762 30.3234 7.71277 28.2886C4.98411 25.5599 3.45117 21.8591 3.45117 18.0002C3.45117 14.1413 4.98411 10.4404 7.71277 7.71179C9.74762 5.67694 12.3402 4.29119 15.1626 3.72978C17.985 3.16836 20.9105 3.4565 23.5692 4.55775C26.2279 5.65901 28.5003 7.52391 30.0991 9.91664C31.6978 12.3094 32.5512 15.1225 32.5512 18.0002C32.5512 20.8779 31.6978 23.691 30.0991 26.0837C28.5003 28.4765 26.2279 30.3414 23.5692 31.4426C20.9105 32.5439 17.985 32.832 15.1626 32.2706ZM16.3904 22.4326C16.2253 22.4327 16.062 22.4 15.9097 22.3364C15.7575 22.2727 15.6194 22.1795 15.5036 22.062L12.018 18.5764C11.8873 18.4634 11.7814 18.3246 11.7067 18.1688C11.6321 18.013 11.5903 17.8435 11.5841 17.6708C11.5778 17.4982 11.6072 17.3261 11.6705 17.1653C11.7337 17.0045 11.8294 16.8585 11.9515 16.7363C12.0737 16.6142 12.2197 16.5185 12.3805 16.4553C12.5413 16.392 12.7134 16.3626 12.886 16.3689C13.0587 16.3751 13.2282 16.4169 13.384 16.4915C13.5398 16.5662 13.6786 16.6721 13.7916 16.8028L16.4168 19.428L22.2804 13.9085C22.5236 13.689 22.8396 13.5675 23.1673 13.5675C23.4949 13.5675 23.8108 13.689 24.054 13.9085C24.1697 14.0241 24.2614 14.1613 24.324 14.3123C24.3866 14.4633 24.4189 14.6252 24.4189 14.7887C24.4189 14.9522 24.3866 15.1141 24.324 15.2651C24.2614 15.4161 24.1697 15.5533 24.054 15.6689L17.3036 22.0487C17.0604 22.2901 16.733 22.4277 16.3904 22.4326Z"
        fill="#23CD84"
      />
    </svg>
  )
}

export default SuccessGreenIcon
