import colors from '../styles/colors'
import { Box, Button, Modal, Typography } from '@mui/material'
import SuccessGreenIcon from './Icons/SuccessGreenIcon'
import { ModalProps } from '../types/ModalProps'

interface CompleteModalProps extends ModalProps {
  title: string
  content: string
  onClickConfirm: () => void
}

const CompleteModal = ({ open, title, content, onClose, onClickConfirm }: CompleteModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          backgroundColor: colors.white,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 'calc(100% - 40px)',
          minWidth: '298px',
          px: '20px',
          minHeight: '252px',
          py: '8px',
          borderRadius: '4px',
          border: '1px solid #F5F5F5',
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            padding: '16px 24px 8px 24px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <SuccessGreenIcon />
          <Typography
            sx={{
              textAlign: 'center',
              color: '#262626',
              fontSize: '18px',
              fontWeight: '700',
              lineHeight: '24px',
              letterSpacing: '-0.36px',
              whiteSpace: 'pre-wrap',
              wordBreak: 'keep-all',
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box sx={{ paddingY: '8px' }}>
          <Typography
            sx={{
              textAlign: 'center',
              color: '#595959',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '20px',
              letterSpacing: '-0.32px',
              whiteSpace: 'pre-wrap',
              wordBreak: 'keep-all',
            }}
          >
            {content}
          </Typography>
        </Box>
        <Box sx={{ padding: '16px 24px' }}>
          <Button
            onClick={onClickConfirm}
            sx={{
              width: '250px',
              height: '50px',
              borderRadius: '2px',
              backgroundColor: colors.black,
              '&:hover': { backgroundColor: colors.gray80 },
            }}
          >
            <Typography
              sx={{
                color: colors.white,
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '20px',
                letterSpacing: '-0.32px',
              }}
            >
              확인
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default CompleteModal
