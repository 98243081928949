import { Box, Typography } from '@mui/material'
import RadioOff from '../../../shared-components/Icons/RadioOff'
import RadioOn from '../../../shared-components/Icons/RadioOn'
import { ChangeEvent } from 'react'

interface RadioButtonProps {
  name: string
  value: string
  checked: boolean
  onChangeChargerLocation: (value: string) => void
}

const RadioButton = ({ name, value, checked, onChangeChargerLocation }: RadioButtonProps) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && event.target.value) onChangeChargerLocation(event.target.value)
  }
  return (
    <label className="flex gap-[4px]">
      <input
        className="hidden"
        type="radio"
        name={name}
        value={value}
        onChange={(event) => onChange(event)}
        checked={checked}
      />
      {checked ? <RadioOn /> : <RadioOff />}
      <Typography component={'span'} fontWeight={500}>
        {value}
      </Typography>
    </label>
  )
}

interface ChargerLocationRadiosProps {
  chargerLocation: string
  onChangeChargerLocation: (value: string) => void
}

const ChargerLocationRadios = ({ chargerLocation, onChangeChargerLocation }: ChargerLocationRadiosProps) => {
  return (
    <Box display={'flex'} gap={'16px'}>
      <RadioButton
        name={'chargerLocation'}
        value={'매장 내구비'}
        onChangeChargerLocation={onChangeChargerLocation}
        checked={chargerLocation === '매장 내구비'}
      />
      <RadioButton
        name={'chargerLocation'}
        value={'주변 공용 충전시설 이용'}
        onChangeChargerLocation={onChangeChargerLocation}
        checked={chargerLocation === '주변 공용 충전시설 이용'}
      />
    </Box>
  )
}

export default ChargerLocationRadios
