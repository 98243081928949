import { Box, Button, TextField, Typography } from '@mui/material'
import colors from '../../../styles/colors'
import { ChangeEvent, FormEvent, useState } from 'react'
import { Users } from '../../../model/user.entity'
import dayjs from 'dayjs'

interface UserInfoPageProps {
  handleStep: () => void
  onChangeField: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  userInfo: Users
}

const UserInfoPage = ({ handleStep, onChangeField, userInfo }: UserInfoPageProps) => {
  const [isPhoneNumberValidate] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')

  /* 휴대폰 번호 인증 제거되어서 PhoneNumberValidateInput과 함께 주석처리
  const onClickPhoneValidate = () => {
    setIsPhoneNumberValidate(true)
  }
  */
  const isNotMatchPhoneNumberRegex = (value: string) => {
    const phoneNumberRegex = /^\d{11}$/
    return !phoneNumberRegex.test(value)
  }

  const isNotMatchBirthRegex = (value: string) => {
    const birthRegex = /^\d{8}$/

    if (!birthRegex.test(value)) {
      return true
    }

    return dayjs(value).format('YYYYMMDD') !== value
  }

  const isNotMatchEmailRegex = (value: string) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
    return !emailRegex.test(value)
  }

  const isNotMatchNameRegex = (value: string) => {
    const nameRegex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z]+$/
    return !nameRegex.test(value)
  }

  const onChangeConfirmPassword = (value: string) => {
    setConfirmPassword(value)
  }

  const isAllValid = () => {
    return (
      !isNotMatchEmailRegex(userInfo.email) &&
      userInfo.password.length >= 8 &&
      userInfo.password === confirmPassword &&
      !isNotMatchNameRegex(userInfo.name) &&
      !isNotMatchBirthRegex(userInfo.birth) &&
      !isNotMatchPhoneNumberRegex(userInfo.phoneNumber)
    )
  }

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()

    if (isAllValid()) {
      handleStep()
    }
  }

  return (
    <>
      <form onSubmit={(event) => onSubmit(event)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px', padding: '32px 24px 130px 24px' }}>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: '500',
              lineHeight: '36px',
              letterSpacing: '-0.48px',
              color: colors.gray90,
            }}
          >
            가입을 위해
            <br />
            기본정보를 입력해 주세요.
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '16px',
                  letterSpacing: '-0.24px',
                  color: colors.gray90,
                }}
              >
                이메일
              </Typography>
              <TextField
                name={'email'}
                placeholder={'이메일 주소를 입력해주세요'}
                variant={'standard'}
                sx={{ '.MuiInput-input': { padding: '16px 4px' } }}
                fullWidth
                type={'email'}
                onChange={onChangeField}
                error={userInfo.email.length > 0 && isNotMatchEmailRegex(userInfo.email)}
                helperText={userInfo.email && isNotMatchEmailRegex(userInfo.email) ? '이메일 형식을 확인해주세요.' : ''}
                required
              />
            </Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '16px',
                  letterSpacing: '-0.24px',
                  color: colors.gray90,
                }}
              >
                비밀번호
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <TextField
                  name={'password'}
                  type={'password'}
                  placeholder={'비밀번호를 입력해주세요. (8자리 이상)'}
                  variant={'standard'}
                  sx={{ '.MuiInput-input': { padding: '16px 4px' } }}
                  fullWidth
                  onChange={onChangeField}
                  error={userInfo.password.length > 0 && userInfo.password.length < 8}
                  helperText={
                    userInfo.password.length > 0 && userInfo.password.length < 8 ? '8자리 이상 입력해주세요.' : ''
                  }
                  required
                />
                <TextField
                  type={'password'}
                  placeholder={'비밀번호를 한번 더 입력해주세요.'}
                  variant={'standard'}
                  sx={{ '.MuiInput-input': { padding: '16px 4px' } }}
                  value={confirmPassword}
                  onChange={(event) => onChangeConfirmPassword(event.target.value)}
                  error={confirmPassword.length > 0 && userInfo.password !== confirmPassword}
                  helperText={
                    confirmPassword.length > 0 && userInfo.password !== confirmPassword
                      ? '비밀번호가 일치하지 않습니다.'
                      : ''
                  }
                  fullWidth
                  required
                />
              </Box>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '16px',
                  letterSpacing: '-0.24px',
                  color: colors.gray90,
                }}
              >
                이름
              </Typography>
              <TextField
                name={'name'}
                placeholder={'이름을 입력해주세요'}
                variant={'standard'}
                sx={{ '.MuiInput-input': { padding: '16px 4px' } }}
                fullWidth
                onChange={onChangeField}
                error={userInfo.name.length > 0 && isNotMatchNameRegex(userInfo.name)}
                helperText={
                  userInfo.name.length > 0 && isNotMatchNameRegex(userInfo.name)
                    ? '이름엔 공백이나 특수문자가 포함될 수 없습니다.'
                    : ''
                }
                required
              />
            </Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '16px',
                  letterSpacing: '-0.24px',
                  color: colors.gray90,
                }}
              >
                생년월일
              </Typography>
              <TextField
                name={'birth'}
                placeholder={'예 ) 19961212'}
                variant={'standard'}
                sx={{ '.MuiInput-input': { padding: '16px 4px' } }}
                fullWidth
                onChange={onChangeField}
                error={userInfo.birth.length > 0 && isNotMatchBirthRegex(userInfo.birth)}
                helperText={userInfo.birth && isNotMatchBirthRegex(userInfo.birth) ? '생년월일을 입력해 주세요.' : ''}
                required
              />
            </Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '16px',
                  letterSpacing: '-0.24px',
                  color: colors.gray90,
                }}
              >
                휴대폰 번호
              </Typography>
              <TextField
                name={'phoneNumber'}
                disabled={isPhoneNumberValidate}
                placeholder={'-를 제외한 11자리 번호를 입력해주세요'}
                variant={'standard'}
                error={userInfo.phoneNumber.length > 0 && isNotMatchPhoneNumberRegex(userInfo.phoneNumber)}
                helperText={
                  userInfo.phoneNumber && isNotMatchPhoneNumberRegex(userInfo.phoneNumber)
                    ? '휴대폰 번호를 입력해 주세요.'
                    : ''
                }
                sx={{
                  '.MuiInput-input': { padding: '16px 4px' },
                  backgroundColor: isPhoneNumberValidate ? '#F2F3F5' : '',
                  color: isPhoneNumberValidate ? colors.gray40 : '',
                }}
                fullWidth
                onChange={onChangeField}
                required
              />
            </Box>
            {/*<PhoneNumberValidateInput*/}
            {/*  isPhoneNumberValidate={isPhoneNumberValidate}*/}
            {/*  onClickPhoneValidate={onClickPhoneValidate}*/}
            {/*/>*/}
          </Box>
        </Box>
        <Button
          variant="contained"
          color="primary"
          sx={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            borderRadius: '0px',
            minHeight: '56px',
            background: 'black',
            maxWidth: '639px',
            ':disabled': {
              backgroundColor: colors.gray40,
            },
          }}
          type="submit"
          disabled={!isAllValid()}
        >
          <Typography
            color={colors.white}
            fontSize={'16px'}
            fontWeight={500}
            lineHeight={'20px'}
            letterSpacing={'-0.32px'}
          >
            다음
          </Typography>
        </Button>
      </form>
    </>
  )
}

export default UserInfoPage
