const RightArrowIcon = ({ color = 'black', className = '' }: { color?: string; className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3016 12.0223L8.0573 18.5071C7.9809 18.5863 7.9809 18.7146 8.0573 18.7938L9.16398 19.9406C9.24038 20.0198 9.36425 20.0198 9.44065 19.9406L17.9318 12.1638C17.9377 12.1576 17.9432 12.1511 17.9482 12.1444L17.9525 12.14C18.0158 12.0744 18.0158 11.9679 17.9525 11.9023C17.9464 11.8934 17.9395 11.885 17.9318 11.8771L16.8251 10.7302C16.8174 10.7223 16.8093 10.7151 16.8008 10.7088L9.44065 4.05938C9.36425 3.98021 9.24038 3.98021 9.16398 4.05938L8.0573 5.2062C7.9809 5.28538 7.9809 5.41374 8.0573 5.49291L15.3016 12.0223Z"
        fill={color}
      />
    </svg>
  )
}

export default RightArrowIcon
