const DotLine = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12C5 11.4477 5.44772 11 6 11H7.77778C8.33006 11 8.77778 11.4477 8.77778 12C8.77778 12.5523 8.33006 13 7.77778 13H6C5.44772 13 5 12.5523 5 12ZM10.3333 12C10.3333 11.4477 10.781 11 11.3333 11H13.1111C13.6634 11 14.1111 11.4477 14.1111 12C14.1111 12.5523 13.6634 13 13.1111 13H11.3333C10.781 13 10.3333 12.5523 10.3333 12ZM15.6667 12C15.6667 11.4477 16.1144 11 16.6667 11H18.5556C19.1078 11 19.5556 11.4477 19.5556 12C19.5556 12.5523 19.1078 13 18.5556 13H16.6667C16.1144 13 15.6667 12.5523 15.6667 12Z"
        fill="#9DA7B0"
      />
    </svg>
  )
}

export default DotLine
