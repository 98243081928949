export interface EVCharger {
  name: string
  value: EVChargerType
  chargerType: '완속' | '급속' | '완속 + 급속'
}

const EV_CHARGER_TYPE = ['AC_1', 'AC_3', 'DC_CHADEMO', 'DC_COMBO', 'TESLA'] as const

export type EVChargerType = (typeof EV_CHARGER_TYPE)[number]

export const EV_CHARGERS: EVCharger[] = [
  {
    name: 'AC 단상',
    value: 'AC_1',
    chargerType: '완속',
  },
  {
    name: 'AC 3상',
    value: 'AC_3',
    chargerType: '완속',
  },
  {
    name: 'DC 차데모',
    value: 'DC_CHADEMO',
    chargerType: '급속',
  },
  {
    name: 'DC 콤보',
    value: 'DC_COMBO',
    chargerType: '완속 + 급속',
  },
  {
    name: '테슬라',
    value: 'TESLA',
    chargerType: '급속',
  },
]

export const getChargerName = (value: EVChargerType) => {
  const charger = EV_CHARGERS.find((charger) => charger.value === value)
  return charger ? charger.name : ''
}

export const getChargerType = (value: EVChargerType) => {
  const charger = EV_CHARGERS.find((charger) => charger.value === value)
  return charger ? charger.chargerType : ''
}
