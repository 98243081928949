const SearchIcon = ({ color = 'black', className = '' }: { color?: string; className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      className={className}
    >
      <path
        d="M18.0986 17.1895L13.0264 12.1172C13.8135 11.0996 14.2393 9.85547 14.2393 8.54688C14.2393 6.98047 13.6279 5.51172 12.5225 4.4043C11.417 3.29687 9.94434 2.6875 8.37988 2.6875C6.81543 2.6875 5.34277 3.29883 4.2373 4.4043C3.12988 5.50977 2.52051 6.98047 2.52051 8.54688C2.52051 10.1113 3.13184 11.584 4.2373 12.6895C5.34277 13.7969 6.81348 14.4062 8.37988 14.4062C9.68848 14.4062 10.9307 13.9805 11.9482 13.1953L17.0205 18.2656C17.0354 18.2805 17.053 18.2923 17.0725 18.3004C17.0919 18.3084 17.1127 18.3126 17.1338 18.3126C17.1548 18.3126 17.1757 18.3084 17.1951 18.3004C17.2145 18.2923 17.2322 18.2805 17.2471 18.2656L18.0986 17.416C18.1135 17.4011 18.1253 17.3835 18.1334 17.364C18.1414 17.3446 18.1456 17.3238 18.1456 17.3027C18.1456 17.2817 18.1414 17.2609 18.1334 17.2414C18.1253 17.222 18.1135 17.2043 18.0986 17.1895ZM11.4736 11.6406C10.6455 12.4668 9.54785 12.9219 8.37988 12.9219C7.21191 12.9219 6.11426 12.4668 5.28613 11.6406C4.45996 10.8125 4.00488 9.71484 4.00488 8.54688C4.00488 7.37891 4.45996 6.2793 5.28613 5.45312C6.11426 4.62695 7.21191 4.17188 8.37988 4.17188C9.54785 4.17188 10.6475 4.625 11.4736 5.45312C12.2998 6.28125 12.7549 7.37891 12.7549 8.54688C12.7549 9.71484 12.2998 10.8145 11.4736 11.6406Z"
        fill={color}
      />
    </svg>
  )
}

export default SearchIcon
