import { Box } from '@mui/material'
import EmptyNoticePage from './fragments/EmptyNoticePage'
import NoticeList from './fragments/NoticeList'
import { useEffect, useState } from 'react'
import { Notice } from '../../model/notice.entity'
import { getNoticeList } from '../../repository/notice.repository'

const NoticePage = () => {
  const [noticeList, setNoticeList] = useState<Notice[]>([])

  useEffect(() => {
    getNoticeList().then((notices) => {
      if (!notices) return
      setNoticeList(notices)
    })
  }, [])

  return (
    <Box height={'100%'}>{noticeList.length !== 0 ? <NoticeList noticeList={noticeList} /> : <EmptyNoticePage />}</Box>
  )
}

export default NoticePage
