import Seoul from '../../images/mockup/Seoul.jpg'
import Jeonnam from '../../images/mockup/Jeonnam.jpg'
import Chungbuk from '../../images/mockup/Chungbuk.jpg'
import Busan from '../../images/mockup/Busan.jpg'
import Gyeongnam from '../../images/mockup/Gyeongnam.jpg'
import Gangwon from '../../images/mockup/Gangwon.jpg'
import Daegu from '../../images/mockup/Daegu.jpg'
import Jeju from '../../images/mockup/Jeju.png'

interface TravelCity {
  name: string
  subLocation?: string
  thumbnail: string
}

export const TRAVEL_CITY: TravelCity[] = [
  {
    name: '전남',
    thumbnail: Jeonnam,
  },
  {
    name: '서울',
    thumbnail: Seoul,
  },
  {
    name: '충북',
    thumbnail: Chungbuk,
  },
  {
    name: '부산',
    thumbnail: Busan,
  },
  {
    name: '경남',
    thumbnail: Gyeongnam,
  },
  {
    name: '강원',
    thumbnail: Gangwon,
  },
  {
    name: '대구',
    thumbnail: Daegu,
  },
  {
    name: '제주',
    thumbnail: Jeju,
  },
]
