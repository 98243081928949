import { Box } from '@mui/material'
import DaumPostcode from 'react-daum-postcode'

interface SearchAddressModalProps {
  isOpen: boolean
  onCompleteSearchAddress: (address: string) => void
}

const SearchAddressModal = ({ isOpen, onCompleteSearchAddress }: SearchAddressModalProps) => {
  // eslint-disable-next-line
  const onCompletePost = (data: any) => {
    onCompleteSearchAddress(data.address)
  }

  return (
    <>
      {isOpen && (
        <Box>
          <DaumPostcode
            style={{
              position: 'fixed',
              left: 0,
              top: 0,
              height: '100%',
              width: '100%',
              zIndex: 50,
            }}
            onComplete={(data) => onCompletePost(data)}
          />
        </Box>
      )}
    </>
  )
}

export default SearchAddressModal
