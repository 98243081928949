import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import DownArrowIcon from '../../../shared-components/Icons/DownArrowIcon'
import colors from '../../../styles/colors'
import { ReactElement } from 'react'

interface MyStoreAccordianProps {
  title: string
  content: ReactElement
}

const MyStoreAccordian = ({ title, content }: MyStoreAccordianProps) => {
  return (
    <Accordion
      sx={{
        bgcolor: colors.gray05,
        borderRadius: '0 !important',
        boxShadow: 'unset',
        borderBottomRightRadius: 0,
        '&.Mui-expanded': {
          marginTop: '0px',
        },
        '&:before': {
          content: 'none',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<DownArrowIcon />}
        aria-controls="storeInformation-content"
        id="storeInformation-header"
        sx={{
          bgcolor: colors.white,
          borderBottom: `1px solid ${colors.base}`,
          padding: '16px 20px',
          '.MuiAccordionSummary-content': {
            margin: 0,
            '&.Mui-expanded': {
              margin: 0,
            },
          },
          '&.Mui-expanded': {
            minHeight: 'unset',
          },
        }}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: '20px 24px',
          bgcolor: colors.gray05,
        }}
      >
        {content}
      </AccordionDetails>
    </Accordion>
  )
}

export default MyStoreAccordian
