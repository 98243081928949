const getHeaderTitle = (pathname: string) => {
  const titleMappings = {
    '/travelPlanRecommend/': '여행 코스 추천',
    '/travelSpot/search': '여행지 검색',
    '/travelSpot/change': '여행지 검색',
    '/travelHistory/detail': '여행 코스',
    '/myPage': '마이페이지',
    '/myStore': '내 매장 등록',
    '/notice': '공지사항',
    '/faq': '자주 묻는 질문',
    '/setting': '설정',
    '/myTravels': '내가 만든 여행',
    '/wishList': '내가 찜한 여행지',
    '/alarm': '알림 설정',
    '/resign': '회원탈퇴',
    '/signup': '회원가입',
    '/resetpassword': '비밀번호 재설정',
    '/profile': '프로필 수정',
  }

  // 동적인 notice 경로에 대한 정규식
  const noticeRegex = /^\/notice\/\d+$/

  // 특정 경로에 해당하는 title이 매핑되어 있으면 해당 값을 반환하고,
  // 그렇지 않으면 빈 문자열을 반환합니다.
  for (const [path, title] of Object.entries(titleMappings)) {
    if (path === pathname || (noticeRegex.test(pathname) && path === '/notice')) {
      return title
    }
  }

  return ''
}

export default getHeaderTitle
