import Gangwon from '../images/mockup/Gangwon.jpg'
import TravelThemeChip from './TravelThemeChip'
import RightArrowIcon from './Icons/RightArrowIcon'
import { useNavigate } from 'react-router-dom'
import { Spot } from '../repository/spot.repository'
import { useRecoilState } from 'recoil'
import { myTravelState } from '../travel/travelState'

interface TravelHistory {
  id: string
  title: string
  tags: string[]
  spots: Spot[]
}

interface TravelHistoryCardProps {
  history: TravelHistory
}

const TravelHistoryCard = ({ history }: TravelHistoryCardProps) => {
  const navigate = useNavigate()
  const [myTravel, setMyTravel] = useRecoilState(myTravelState)
  const handleOnClickDetail = () => {
    setMyTravel({
      ...myTravel,
      title: '',
    })
    navigate(`/travelHistory/${history.id}`, { state: { from: location.pathname, history } })
  }

  return (
    <div className="rounded-[6px] overflow-hidden border border-gray20 ">
      <div
        style={{
          aspectRatio: 1.95,
          background: `no-repeat center url(${history.spots.length > 0 ? history.spots[0].thumbnail : Gangwon})`,
          backgroundSize: 'cover',
        }}
      />
      <div className="p-[12px]">
        <p>{history.title}</p>
        <div className="flex gap-[16px]">
          {history.tags.slice(0, 3).map((tag) => (
            <TravelThemeChip label={tag} key={`${history.title}-${tag}`} />
          ))}
          {history.tags.length > 3 && (
            <TravelThemeChip label={`외 +${history.tags.length - 3}`} key={`${history.title}-more`} />
          )}
        </div>
        <div className="border border-[#DEDDDD] rounded-[9px]">
          <button className="w-full flex justify-between items-center p-[12px]" onClick={handleOnClickDetail}>
            <span className="text-[12px]">여행지 살펴보기</span>
            <RightArrowIcon className="w-[16px] h-[16px]" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default TravelHistoryCard
