import { ForwardedRef, forwardRef } from 'react'
import { Unstable_NumberInput as BaseNumberInput, NumberInputProps } from '@mui/base/Unstable_NumberInput'
import { styled } from '@mui/material'
import PlusIcon from '../../../shared-components/Icons/PlusIcon'
import MinusIcon from '../../../shared-components/Icons/MinusIcon'
import colors from '../../../styles/colors'
import { EVChargerType } from '../../../types/EVCharger'

const StyledInputRoot = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
}))

const StyledInput = styled('input')(() => ({
  fontSize: '15px',
  width: '36px',
  textAlign: 'center',

  '&:focus-visible': {
    outlineColor: colors.primary50,
  },
}))

const StyledButton = styled('button')(() => ({
  '&.increment': {
    order: 1,
  },
}))

const NumberInput = forwardRef(function CustomNumberInput(props: NumberInputProps, ref: ForwardedRef<HTMLDivElement>) {
  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInput,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: <PlusIcon />,
          className: 'increment',
        },
        decrementButton: {
          children: <MinusIcon />,
        },
      }}
      defaultValue={0}
      {...props}
      ref={ref}
    />
  )
})

interface QuantityInputProps {
  charger: EVChargerType
  value: number
  onChangeChargerQuantity: (type: EVChargerType, quantity: number) => void
}

const QuantityInput = ({ charger, value, onChangeChargerQuantity }: QuantityInputProps) => {
  const onChangeQuantity = (value: number | undefined) => {
    value !== undefined && onChangeChargerQuantity(charger, value)
  }

  return (
    <NumberInput
      aria-label="Quantity Input"
      min={0}
      value={value}
      onChange={(event, value) => {
        event.preventDefault()
        onChangeQuantity(value)
      }}
    />
  )
}

export default QuantityInput
