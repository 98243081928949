import { Box, Divider, Typography } from '@mui/material'
import colors from '../../../styles/colors'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Notice } from '../../../model/notice.entity'
import { getNotice } from '../../../repository/notice.repository'

const NoticeDetail = () => {
  const { id } = useParams()
  const [notice, setNotice] = useState<Notice>()

  useEffect(() => {
    if (!id) return
    getNotice(id).then((notice) => {
      if (!notice) return
      setNotice(notice)
    })
  }, [])

  if (!notice) return null

  return (
    <Box height={'100%'}>
      <Box padding={'20px'} display={'flex'} flexDirection={'column'} gap={'8px'}>
        <Typography
          color={colors.gray90}
          fontSize={'15px'}
          fontWeight={500}
          lineHeight={'18px'}
          letterSpacing={'-0.3px'}
        >
          {notice?.title}
        </Typography>
        <Typography
          color={colors.gray60}
          fontSize={'14px'}
          fontWeight={500}
          lineHeight={'18px'}
          letterSpacing={'-0.28px'}
        >
          {new Date(notice.createdAt).toLocaleDateString()}
        </Typography>
      </Box>
      <Divider />
      <Box padding={'28px 20px'} display={'flex'} flexDirection={'column'} gap={'23px'}>
        <Typography fontSize={'14px'} fontWeight={500} lineHeight={'150%'} color={colors.gray60}>
          {notice.content}
        </Typography>
      </Box>
    </Box>
  )
}

export default NoticeDetail
