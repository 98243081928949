import { Box, Typography } from '@mui/material'
import EmptyNotice from '../../../shared-components/Icons/emptyNotice'

const EmptyNoticePage = () => {
  return (
    <Box
      sx={{
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          alignItems: 'center',
          marginBottom: '150px',
        }}
      >
        <EmptyNotice />
        <Typography sx={{ fontSize: '14px', fontWeight: '500', lineHeight: '18px', letterSpacing: '-0.28px' }}>
          아직 등록된 공지사항이 없습니다.
        </Typography>
      </Box>
    </Box>
  )
}

export default EmptyNoticePage
