const DownArrowIcon = ({ color = 'black', className = '' }: { color?: string; className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9787 14.3016L5.49389 7.0573C5.41472 6.9809 5.28635 6.9809 5.20718 7.0573L4.06036 8.16398C3.98118 8.24038 3.98118 8.36425 4.06036 8.44065L11.8372 16.9318C11.8434 16.9377 11.8498 16.9432 11.8565 16.9482L11.8609 16.9525C11.9266 17.0158 12.033 17.0158 12.0987 16.9525C12.1075 16.9464 12.116 16.9395 12.1239 16.9318L13.2707 15.8251C13.2787 15.8174 13.2858 15.8093 13.2922 15.8008L19.9416 8.44065C20.0208 8.36425 20.0208 8.24038 19.9416 8.16398L18.7948 7.0573C18.7156 6.9809 18.5872 6.9809 18.5081 7.0573L11.9787 14.3016Z"
        fill={color}
      />
    </svg>
  )
}

export default DownArrowIcon
