const MapPinLineIcon = ({ color = 'black', className = '' }: { color?: string; className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_130_11070)">
        <path
          d="M12 20.9L16.95 15.95C17.9289 14.971 18.5955 13.7237 18.8655 12.3659C19.1356 11.008 18.9969 9.6006 18.4671 8.32156C17.9373 7.04251 17.04 5.94931 15.8889 5.18017C14.7378 4.41103 13.3844 4.0005 12 4.0005C10.6156 4.0005 9.26221 4.41103 8.11108 5.18017C6.95995 5.94931 6.06275 7.04251 5.53291 8.32156C5.00308 9.6006 4.86441 11.008 5.13445 12.3659C5.40449 13.7237 6.0711 14.971 7.05 15.95L12 20.9ZM12 23.728L5.636 17.364C4.37734 16.1053 3.52018 14.5017 3.17292 12.7558C2.82567 11.01 3.0039 9.20043 3.68509 7.5559C4.36629 5.91137 5.51984 4.50577 6.99988 3.51684C8.47992 2.52792 10.22 2.00008 12 2.00008C13.78 2.00008 15.5201 2.52792 17.0001 3.51684C18.4802 4.50577 19.6337 5.91137 20.3149 7.5559C20.9961 9.20043 21.1743 11.01 20.8271 12.7558C20.4798 14.5017 19.6227 16.1053 18.364 17.364L12 23.728ZM12 13C12.5304 13 13.0391 12.7893 13.4142 12.4142C13.7893 12.0391 14 11.5304 14 11C14 10.4696 13.7893 9.96086 13.4142 9.58579C13.0391 9.21071 12.5304 9 12 9C11.4696 9 10.9609 9.21071 10.5858 9.58579C10.2107 9.96086 10 10.4696 10 11C10 11.5304 10.2107 12.0391 10.5858 12.4142C10.9609 12.7893 11.4696 13 12 13ZM12 15C10.9391 15 9.92172 14.5786 9.17157 13.8284C8.42143 13.0783 8 12.0609 8 11C8 9.93913 8.42143 8.92172 9.17157 8.17157C9.92172 7.42143 10.9391 7 12 7C13.0609 7 14.0783 7.42143 14.8284 8.17157C15.5786 8.92172 16 9.93913 16 11C16 12.0609 15.5786 13.0783 14.8284 13.8284C14.0783 14.5786 13.0609 15 12 15Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_130_11070">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MapPinLineIcon
