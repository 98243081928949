import { getSpotsSnapshot, mapToSpot, Spot } from './spot.repository'
import { getDatabase, ref, set } from 'firebase/database'
import { SpotResponse } from '../types/SpotResponse'

export const getLikeByUid = async (uid: string): Promise<Spot | null> => {
  const db = getDatabase()

  return getSpotsSnapshot(db)
    .then((snapshot) => {
      if (snapshot.exists()) {
        const val = snapshot.val()

        if (val[uid]) {
          return mapToSpot({ id: uid, ...val[uid] } as SpotResponse)
        }

        return null
      }

      return null
    })
    .catch((error) => {
      console.error(error)
      return null
    })
}

export const setLikeByUid = (uid: string, spotId: string, value: boolean) => {
  const db = getDatabase()

  return set(ref(db, `users/${uid}/likes/${spotId}`), value)
}
