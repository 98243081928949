import MapPinLineIcon from '../shared-components/Icons/MapPinLineIcon'
import colors from '../styles/colors'
import EVChargerChip from './EVChargerChip'
import { getChargerName } from '../types/EVCharger'
import TimeLineIcon from './Icons/TimeLineIcon'
import { TravelSpot } from '../pages/travelSpot/TravelSpot.entity'

interface TravelSpotListItemProps extends TravelSpot {
  disabledOperationHours?: boolean
}

const TravelSpotListItem = (props: TravelSpotListItemProps) => {
  const { thumbnail, title, chargers, address, operationHours, disabledOperationHours } = props

  return (
    <div className="flex gap-[12px]">
      <div
        className="shrink-0 rounded-[4px] w-[64px] h-[64px] overflow-hidden"
        style={{
          background: `no-repeat center url(${thumbnail})`,
          backgroundSize: 'cover',
        }}
      />
      <div
        className="w-full"
        style={{
          maxWidth: 'calc(100vw - 120px)',
        }}
      >
        <div className="flex items-center justify-between">
          <p className="mb-[4px] truncate">{title}</p>
        </div>
        <div className="flex gap-[8px] mb-[4px]">
          {(chargers || []).map((charger, index) => {
            if (index < 2) {
              return <EVChargerChip label={getChargerName(charger)} key={`${title}-${charger}`} />
            }
          })}
          {(chargers?.length || 0) > 3 && <EVChargerChip label={`외 +${chargers.length - 3}`} key={`${title}-more`} />}
        </div>
        {!disabledOperationHours && (
          <div className="flex gap-[4px] items-center">
            <TimeLineIcon color={colors.gray60} className="w-[14px] h-[14px]" />
            <p className="text-gray60 text-[12px] truncate">{operationHours}</p>
          </div>
        )}
        <div className="flex gap-[4px] items-center">
          <MapPinLineIcon color={colors.gray60} className="w-[14px] h-[14px]" />
          <p className="text-gray60 text-[12px] truncate">{address}</p>
        </div>
      </div>
    </div>
  )
}

export default TravelSpotListItem
