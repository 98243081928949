export interface TermsStatus {
  ageTerm: boolean
  privacyTerm: boolean
  serviceTerm: boolean
  benefitTerm: boolean
  marketingTerm: boolean
}

interface TermType {
  id: number
  title: string
  name: keyof TermsStatus
  url: string
  isNeed: boolean
}

export const termList: TermType[] = [
  {
    id: 1,
    name: 'ageTerm',
    title: '(필수) 만 14세 이상입니다',
    url: '',
    isNeed: true,
  },
  {
    id: 2,
    name: 'privacyTerm',
    title: '(필수) 개인정보 수집·이용 동의',
    url: '',
    isNeed: true,
  },
  {
    id: 3,
    name: 'serviceTerm',
    title: '(필수) 서비스 이용약관 동의',
    url: '',
    isNeed: true,
  },
  {
    id: 4,
    name: 'benefitTerm',
    title: '(선택) 야간 혜택 수신에 동의',
    url: '',
    isNeed: false,
  },
  {
    id: 5,
    name: 'marketingTerm',
    title: '(선택) 홍보 및 마케팅 이용 동의',
    url: '',
    isNeed: false,
  },
]
