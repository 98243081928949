import { useLocation, useNavigate } from 'react-router-dom'
import MapPinLineIcon from '../../shared-components/Icons/MapPinLineIcon'
import colors from '../../styles/colors'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import 'dayjs/locale/ko'
import { useState } from 'react'
import { Button } from '@mui/material'
import dayjs from 'dayjs'
import DescriptionWithIcon from '../../shared-components/DescriptionWithIcon'

const TravelPlanRecommendWhen = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const selectedLocation = location.state.selectedLocation
  const today = dayjs()

  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(today.add(1, 'day'))
  const handleOnDateChange = (date: dayjs.Dayjs | null) => {
    setSelectedDate(date)
  }

  const handleOnSubmit = () => {
    navigate('/travelPlanRecommend/theme', {
      state: {
        from: location.pathname,
        selectedLocation: selectedLocation,
        selectedDate: selectedDate?.format('YYYY.MM.DD'),
      },
    })
  }

  return (
    <div className={'h-full'}>
      <div className={'px-6 flex flex-col gap-5'}>
        <div className="py-5 flex flex-col gap-2 w-full text-zinc-900 text-xl font-semibold font-['Pretendard']">
          언제 여행을 떠나시나요?
          <DescriptionWithIcon
            label={selectedLocation}
            icon={<MapPinLineIcon color={colors.gray40} className={'w-[14px] h-[14px]'} />}
          />
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ko'}>
          <DateCalendar
            value={selectedDate}
            onChange={handleOnDateChange}
            minDate={today}
            view={'day'}
            views={['day']}
          />
        </LocalizationProvider>
      </div>
      <div className={'w-full absolute bottom-0 z-10 max-w-[639px]'}>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: '100%', borderRadius: '0px', minHeight: '56px', background: 'black', maxWidth: '639px' }}
          disabled={selectedDate === null}
          onClick={handleOnSubmit}
        >
          선택
        </Button>
      </div>
    </div>
  )
}

export default TravelPlanRecommendWhen
