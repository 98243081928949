const MyPageIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.09936 6.91544C7.09936 4.51637 9.10157 2.57153 11.5714 2.57153C14.0413 2.57153 16.0435 4.51636 16.0435 6.91543V7.36888C16.0435 9.76795 14.0413 11.7128 11.5714 11.7128C9.10157 11.7128 7.09936 9.76795 7.09936 7.36888V6.91544Z"
        fill="#8C8F91"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.19783 14.557C6.75848 13.4019 8.92233 12.8715 11.5716 12.8715C14.2208 12.8715 16.382 13.4019 17.9413 14.5571C19.5243 15.7299 20.3813 17.4677 20.5684 19.6511C20.6103 20.1409 20.2138 20.5715 19.6969 20.5715H3.44592C2.92994 20.5715 2.5337 20.1424 2.57427 19.6533C2.75554 17.4684 3.61306 15.7299 5.19783 14.557Z"
        fill="#8C8F91"
      />
    </svg>
  )
}

export default MyPageIcon
