export type MemberType = 'general' | 'enterprise'

export interface Member {
  name: string
  value: MemberType
}

export const MEMBERS: Member[] = [
  {
    name: '일반회원',
    value: 'general',
  },
  {
    name: '기업회원',
    value: 'enterprise',
  },
]

export const getMemberName = (value: MemberType) => {
  const member = MEMBERS.find((member) => member.value === value)
  return member ? member.name : ''
}
