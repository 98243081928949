import { useNavigate } from 'react-router-dom'
import RightArrowIcon from '../../../shared-components/Icons/RightArrowIcon'
import { Notice } from '../../../model/notice.entity'

interface NotificationProps {
  noticeList: Notice[]
}

const Notification = ({ noticeList }: NotificationProps) => {
  const navigate = useNavigate()

  const goNotification = (id: string) => {
    navigate(`/notice/${id}`, { state: { from: location.pathname } })
  }

  return (
    <div className="flex items-center gap-[10px] bg-gray10 px-[16px] py-[12px] mx-[20px] mt-[13.5px]">
      <div className="shrink-0 flex items-center justify-center bg-gray50 h-[20px] w-[37px] rounded-[2px]">
        <span className="text-[12px] text-white">공지</span>
      </div>
      <button className="text-left flex-1 text-[14px] truncate" onClick={() => goNotification(noticeList[0].id)}>
        {noticeList[0].title}
      </button>
      <button onClick={() => goNotification(noticeList[0].id)}>
        <RightArrowIcon className="shrink-0 w-[16px] h-[16px]" />
      </button>
    </div>
  )
}
export default Notification
