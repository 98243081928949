import { useLocation } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { servicePolicy } from './fragments/serviceTerms'
import colors from '../../styles/colors'
import { privacyPolicy } from './fragments/privacyPolicy'

export interface PolicyType {
  title: string
  contents: {
    title: string
    content: string
  }[]
}

const Policy = () => {
  const location = useLocation()
  const pathWithoutSlash = location.pathname.replace(/^\/|/g, '')
  return (
    <Box sx={{ padding: '32px 20px' }}>
      {pathWithoutSlash === 'serviceTerms' && (
        <>
          <Typography
            sx={{
              marginBottom: '24px',
              color: colors.gray90,
              fontSize: '20px',
              fontWeight: '600',
              lineHeight: '24px',
              letterSpacing: '-0.4px',
            }}
          >
            {servicePolicy.title}
          </Typography>
          {servicePolicy.contents.map((content) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                marginBottom: '24px',
                color: colors.gray90,
                fontSize: '15px',
                fontWeight: '400',
                lineHeight: '24px',
              }}
              key={content.title}
            >
              <Typography>{content.title}</Typography>
              <Typography sx={{ whiteSpace: 'pre-wrap' }}>{content.content}</Typography>
            </Box>
          ))}
        </>
      )}
      {pathWithoutSlash === 'privacyPolicy' && (
        <>
          <Typography
            sx={{
              marginBottom: '24px',
              color: colors.gray90,
              fontSize: '20px',
              fontWeight: '600',
              lineHeight: '24px',
              letterSpacing: '-0.4px',
            }}
          >
            {privacyPolicy.title}
          </Typography>
          {privacyPolicy.contents.map((content) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                marginBottom: '24px',
                color: colors.gray90,
                fontSize: '15px',
                fontWeight: '400',
                lineHeight: '24px',
              }}
              key={content.title}
            >
              <Typography>{content.title}</Typography>
              <Typography sx={{ whiteSpace: 'pre-wrap' }}>{content.content}</Typography>
            </Box>
          ))}
        </>
      )}
    </Box>
  )
}

export default Policy
