import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Box } from '@mui/material'
import { Banner } from '../../../model/banner.entity'

interface BannerSectionProps {
  banners: Banner[]
}

const BannerSection = ({ banners }: BannerSectionProps) => {
  const onClickBanner = (link: string) => {
    window.location.href = link
  }

  return (
    <Box
      sx={{
        marginTop: '30px',
        '.swiper-pagination-fraction': {
          bottom: '10px',
          right: '20px',
          left: 'unset',
          bgcolor: 'rgba(0, 0, 0, 0.40)',
          color: 'white',
          whiteSpace: 'nowrap',
          width: 'min-content',
          borderRadius: '35px',
          padding: '2px 14px',
        },
      }}
    >
      <Swiper
        className="w-full"
        style={{
          aspectRatio: 3.6,
        }}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination]}
        pagination={{
          type: 'fraction',
        }}
      >
        {banners.map((banner) => (
          <SwiperSlide
            key={banner.id}
            style={{
              background: `no-repeat center url(${banner.image})`,
              backgroundSize: 'contain',
            }}
            onClick={() => onClickBanner(banner.link)}
          />
        ))}
      </Swiper>
    </Box>
  )
}

export default BannerSection
