const LeftArrowIcon = ({ color = 'black', className = '' }: { color?: string; className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.6984 11.9777L15.9427 5.49291C16.0191 5.41374 16.0191 5.28538 15.9427 5.20621L14.836 4.05938C14.7596 3.98021 14.6357 3.98021 14.5593 4.05938L6.06821 11.8362C6.06226 11.8424 6.05678 11.8489 6.05176 11.8556L6.04752 11.86C5.98416 11.9256 5.98416 12.0321 6.04752 12.0977C6.05365 12.1066 6.06054 12.115 6.06821 12.1229L7.17489 13.2698C7.18255 13.2777 7.1907 13.2849 7.19922 13.2912L14.5593 19.9406C14.6357 20.0198 14.7596 20.0198 14.836 19.9406L15.9427 18.7938C16.0191 18.7146 16.0191 18.5863 15.9427 18.5071L8.6984 11.9777Z"
        fill={color}
      />
    </svg>
  )
}

export default LeftArrowIcon
