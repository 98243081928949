interface TravelThemeChipProps {
  label: string
  onClick?: () => void
}

const TravelThemeChip = ({ label, onClick }: TravelThemeChipProps) => (
  <div onClick={onClick} className="mt-[12px] mb-[16px] shrink-0 inline-block bg-primary05 px-[10px] rounded-[4px]">
    <span className="text-[12px] text-primary50">{label}</span>
  </div>
)

export default TravelThemeChip
