import { Database, get, getDatabase, query, ref } from 'firebase/database'
import { objectToArray } from '../utils/objectToArray'

export interface TravelTheme {
  id: string
  name: string
  icon: string
}

const getDataSnapshot = (db: Database) => get(query(ref(db, 'themes')))

export const getThemes = async (): Promise<TravelTheme[]> => {
  const db = getDatabase()

  return getDataSnapshot(db)
    .then((snapshot) => {
      if (snapshot.exists()) {
        const themes = snapshot.val()

        return objectToArray<TravelTheme>(themes)
      }

      console.log('No data available')
      return []
    })
    .catch((error) => {
      console.error(error)
      return []
    })
}
