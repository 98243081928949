import colors from '../../styles/colors'
import { SvgProps } from '../../types/svgProps'

const RadioOn = ({ color = colors.primary50, className = '' }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2Z"
        fill={color}
      />
      <circle cx="12" cy="12" r="5" fill="white" />
    </svg>
  )
}

export default RadioOn
