import { Box, Divider } from '@mui/material'
import TravelSpotListItem from '../../shared-components/TravelSpotListItem'
import LikeButton from '../../shared-components/LikeButton'
import colors from '../../styles/colors'
import { useState } from 'react'
import TravelSpotDetailModal from '../travelSpot/TravelSpotDetailModal'
import EmptyCourseCard from '../../shared-components/EmptyCourseCard'
import { useLike } from '../../like/Like'
import { LikeItem } from '../main/fragments/WishList'

const MyWishList = () => {
  const [selectedTravelSpotId, setSelectedTravelSpotId] = useState<string | null>(null)
  const { spots, addLike, removeLike } = useLike()

  const handleOnClickItem = (id: string) => {
    setSelectedTravelSpotId(id)
  }
  const handleOnCloseModal = () => {
    setSelectedTravelSpotId(null)
  }

  const handleOnChangeLike = (likeItem: LikeItem) => {
    if (likeItem.value) {
      addLike(likeItem.id)
      return
    }

    removeLike(likeItem.id)
  }

  return (
    <>
      {spots.length !== 0 ? (
        <Box sx={{ padding: '10px 24px' }}>
          {spots.map((spot) => (
            <Box key={`spot-${spot.id}`}>
              <div className={'flex justify-between items-start py-4'} onClick={() => handleOnClickItem(spot.id)}>
                <TravelSpotListItem {...spot} disabledOperationHours={true} />
                <LikeButton liked={true} onChange={(value) => handleOnChangeLike({ id: spot.id, value })} />
              </div>
              <Divider sx={{ borderColor: colors.gray10 }} />
            </Box>
          ))}
          <TravelSpotDetailModal
            open={selectedTravelSpotId !== null}
            onClose={handleOnCloseModal}
            onClickConfirm={() => null}
            travelSpotId={selectedTravelSpotId}
            label={'확인'}
          />
        </Box>
      ) : (
        <Box
          sx={{
            height: '70vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <EmptyCourseCard
            link={'/travelPlanRecommend/where'}
            text={'찜한 여행지가 없어요 여행 만들면서 \n 마음에 드는 여행지를 찜해보세요!'}
          />
        </Box>
      )}
    </>
  )
}

export default MyWishList
