import { Button, SxProps } from '@mui/material'
import colors from '../styles/colors'

interface EcaroButtonProps {
  color: 'primary'
  text: string
  sx?: SxProps
  onClick: () => void
}

const EcaroButton = ({ color = 'primary', text, sx = {}, onClick }: EcaroButtonProps) => {
  if (color === 'primary')
    return (
      <Button
        variant="text"
        sx={{
          bgcolor: colors.primary50,
          color: colors.white,
          padding: '8px 12px',
          whiteSpace: 'nowrap',
          height: '32px',
          '&:hover': {
            bgcolor: colors.primary50,
            color: colors.white,
          },
          ...sx,
        }}
        onClick={onClick}
      >
        {text}
      </Button>
    )
  else return null
}

export default EcaroButton
