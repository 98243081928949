const HeartMonoSolidIcon = ({ color = 'black', className = '' }: { color?: string; className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M15.7047 4.07711C14.3481 4.21391 13.0811 4.81744 12.12 5.7846C11.1454 4.85039 9.87011 4.29354 8.52243 4.21375C7.17474 4.13397 5.84265 4.53644 4.7646 5.34912C3.68656 6.1618 2.93294 7.33163 2.63866 8.64921C2.34437 9.96679 2.52863 11.3461 3.15845 12.5402C3.93265 14.0674 8.25966 18.0551 10.7731 20.2928C11.1376 20.6149 11.6072 20.7927 12.0935 20.7927C12.5799 20.7927 13.0495 20.6149 13.4139 20.2928C15.938 18.0445 20.2756 14.0674 21.0392 12.5296C21.4779 11.6339 21.6818 10.6413 21.6317 9.64509C21.5816 8.6489 21.2792 7.68178 20.7529 6.83453C20.2194 5.97243 19.4702 5.26454 18.5792 4.78092C17.6882 4.2973 16.6864 4.05469 15.6729 4.07711H15.7047Z"
        fill={color}
      />
    </svg>
  )
}

export default HeartMonoSolidIcon
