import LeftArrowIcon from './Icons/LeftArrowIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import { IconButton, Typography } from '@mui/material'
import MyPageIcon from './Icons/MyPageIcon'
import LOGO from '../images/logo.png'
import getHeaderTitle from '../utils/getHeaderTitle'
import { useRecoilState } from 'recoil'
import { myStoreModalState } from '../store/modalState'
import { useEffect, useState } from 'react'
import { getMemberName } from '../types/member'
import colors from '../styles/colors'
import { signupStepState } from '../store/signupStepState'

const NO_HEADER_PATHS = ['/login']

const Header = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const hasPreviousPage = location.state?.from //이전페이지 존재여부
  const isMainPage = location.pathname === '/'
  const [, setMyStoreModalOpen] = useRecoilState(myStoreModalState)
  const [memberType, setMemberType] = useState('')
  const [signupStep, setSignupStep] = useRecoilState(signupStepState)

  useEffect(() => {
    // TODO: 멤버타입 실연동
    if (localStorage.getItem('memberType') === 'general') setMemberType('general')
    if (localStorage.getItem('memberType') === 'enterprise') setMemberType('enterprise')
  }, [localStorage.getItem('memberType')])

  const handleGoBack = () => {
    if (location.pathname === '/myStore') {
      setMyStoreModalOpen(true)
    } else if (location.pathname === '/myPage') {
      navigate('/', { state: { from: location.pathname } })
    } else if (location.pathname === '/signup' && signupStep > 1) {
      setSignupStep(signupStep - 1)
    } else {
      navigate(-1)
    }
  }

  const navigateToMyPage = () => {
    navigate('/myPage', { state: { from: location.pathname } })
  }
  const HEADER_HEIGHT = 'min-h-[48px] max-h-[48px]'

  return (
    <>
      {!NO_HEADER_PATHS.includes(location.pathname) && (
        <header className={HEADER_HEIGHT}>
          <div
            className={
              HEADER_HEIGHT +
              ' fixed w-full max-w-[639px] px-4 py-3.5 bg-white border-b border-gray10 items-center gap-2 flex justify-between z-50'
            }
          >
            <div className="w-fit h-9 relative flex items-center">
              {isMainPage ? (
                <img src={LOGO} alt="logo" className="h-6" />
              ) : (
                <>
                  {hasPreviousPage && (
                    <IconButton onClick={handleGoBack}>
                      <LeftArrowIcon />
                    </IconButton>
                  )}
                </>
              )}
            </div>
            <div className="text-center text-[#24272B] font-medium font-['Pretendard'] leading-tight">
              {getHeaderTitle(location.pathname)}
            </div>
            <div className="h-9 relative">
              {isMainPage ? (
                <div className={'flex items-center gap-[10px]'}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '500',
                      lineHeight: '18px',
                      letterSpacing: '-0.28px',
                      color: colors.primary50,
                    }}
                  >
                    {memberType === 'enterprise' && getMemberName(memberType)}
                  </Typography>
                  <IconButton onClick={navigateToMyPage}>
                    <MyPageIcon />
                  </IconButton>
                </div>
              ) : (
                <div className={'w-[40px]'} />
              )}
            </div>
          </div>
        </header>
      )}
    </>
  )
}

export default Header
