import { ReactNode } from 'react'

interface PageProps {
  children: ReactNode
}

const Page = ({ children }: PageProps) => {
  return (
    <main className="w-full min-h-screen mx-auto bg-white max-w-[639px] shadow-lg overflow-y-auto">{children}</main>
  )
}

export default Page
