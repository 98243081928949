import { Box, Button, Divider, Typography } from '@mui/material'
import colors from '../../styles/colors'
import { useNavigate } from 'react-router-dom'
import { getAuth, signOut } from 'firebase/auth'

interface Navigations {
  title: string
  link: string
}

const settingNavigations: Navigations[] = [
  {
    title: '서비스 이용약관',
    link: '/serviceTerms',
  },
  {
    title: '개인정보 처리방침',
    link: '/privacyPolicy',
  },
  {
    title: '로그아웃',
    link: '/logout',
  },
  {
    title: '회원탈퇴',
    link: '/resign',
  },
]

const Setting = () => {
  const navigate = useNavigate()
  const moveToLink = (link: string) => {
    navigate(link, { state: { from: location.pathname } })
  }

  const onClickLogout = async () => {
    await signOut(getAuth())
    await localStorage.removeItem('memberType')
    navigate('/login')
  }
  return (
    <Box height={'100%'}>
      {settingNavigations.map((navigation) => (
        <Box key={`myPage-button-${navigation.title}`}>
          <Button
            color={'inherit'}
            fullWidth
            sx={{
              padding: '20px 24px',
              justifyContent: 'space-between',
              borderRadius: 0,
            }}
            onClick={navigation.link === '/logout' ? onClickLogout : () => moveToLink(navigation.link)}
          >
            <Typography fontSize={'15px'} fontWeight={500}>
              {navigation.title}
            </Typography>
          </Button>
          <Divider sx={{ borderColor: colors.gray10 }} />
        </Box>
      ))}
    </Box>
  )
}
export default Setting
