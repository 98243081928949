import { Box, styled, Typography } from '@mui/material'
import colors from '../../../styles/colors'
import { EV_CHARGERS, EVChargerType } from '../../../types/EVCharger'
import ChargerIcon from '../../../shared-components/Icons/ChargerIcon'
import EVChargerChip from '../../../shared-components/EVChargerChip'
import QuantityInput from './QuantityInput'
import { EVChargerWithQuantity } from '../MyStore.entity'

const StyledBorderBox = styled(Box)(() => ({
  border: `1px solid ${colors.gray20}`,
  backgroundColor: colors.white,
  borderRadius: '2px',
  padding: '8px 12px',
  display: 'flex',
  justifyContent: 'space-between',
}))

interface ChargerQuantityProps {
  EVCharger: EVChargerWithQuantity[]
  onChangeChargerQuantity: (type: EVChargerType, quantity: number) => void
}

const ChargerQuantity = ({ EVCharger, onChangeChargerQuantity }: ChargerQuantityProps) => {
  const elements = EVCharger.map((charger) => (
    <StyledBorderBox key={`charger-number-input-${charger.value}`}>
      <Box display={'flex'} gap={'8px'} alignItems={'center'}>
        <ChargerIcon type={charger.value} className={'w-7 h-7'} />
        <Typography fontWeight={500} fontSize={'15px'}>
          {charger.name}
        </Typography>
        <EVChargerChip label={charger.chargerType} />
      </Box>
      <Box alignSelf={'flex-end'}>
        <QuantityInput
          onChangeChargerQuantity={onChangeChargerQuantity}
          charger={charger.value}
          value={charger.quantity}
        />
      </Box>
    </StyledBorderBox>
  ))
  const elements1 = EV_CHARGERS.map((charger) => {
    return (
      <StyledBorderBox key={`charger-number-input-${charger.value}`}>
        <Box display={'flex'} gap={'8px'} alignItems={'center'}>
          <ChargerIcon type={charger.value} className={'w-7 h-7'} />
          <Typography fontWeight={500} fontSize={'15px'}>
            {charger.name}
          </Typography>
          <EVChargerChip label={charger.chargerType} />
        </Box>
        <Box alignSelf={'flex-end'}>
          <QuantityInput onChangeChargerQuantity={onChangeChargerQuantity} charger={charger.value} value={0} />
        </Box>
      </StyledBorderBox>
    )
  })
  return (
    <>
      <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
        {EVCharger.length > 0 ? elements : elements1}
      </Box>
    </>
  )
}

export default ChargerQuantity
