import { Box, Divider, Typography } from '@mui/material'
import colors from '../../../styles/colors'
import { useNavigate } from 'react-router-dom'
import { Notice } from '../../../model/notice.entity'

interface NoticeListProps {
  noticeList: Notice[]
}

const NoticeList = ({ noticeList }: NoticeListProps) => {
  const navigate = useNavigate()
  const moveToLink = (id: string) => {
    navigate(`/notice/${id}`, { state: { from: location.pathname } })
  }
  return (
    <>
      {noticeList.map((item) => (
        <>
          <Box
            padding={'20px'}
            display={'flex'}
            flexDirection={'column'}
            gap={'8px'}
            onClick={() => moveToLink(item.id)}
          >
            <Typography
              color={colors.gray90}
              fontSize={'15px'}
              fontWeight={500}
              lineHeight={'18px'}
              letterSpacing={'-0.3px'}
            >
              {item.title}
            </Typography>
            <Typography
              color={colors.gray60}
              fontSize={'14px'}
              fontWeight={500}
              lineHeight={'18px'}
              letterSpacing={'-0.28px'}
            >
              {new Date(item.createdAt).toLocaleDateString()}
            </Typography>
          </Box>
          <Divider />
        </>
      ))}
    </>
  )
}

export default NoticeList
