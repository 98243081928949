import { Database, endAt, get, getDatabase, orderByChild, push, query, ref, startAt, update } from 'firebase/database'
import { objectToArray } from '../utils/objectToArray'
import { BusinessDay, BusinessDays, ChargingType, SpotResponse, Themes } from '../types/SpotResponse'
import { EVChargerType } from '../types/EVCharger'

export interface Spot {
  id: string
  thumbnail: string
  title: string
  chargers: EVChargerType[]
  city: string
  detailedAddress: string
  address: string
  operationHours: string
  category: string
  longitude: number
  latitude: number
  description: string
  phoneNumber: string
  operationDays: string[]
  theme: string[]
}

const orderOfDays: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

const customSort = (a: string, b: string): number => {
  const dayA = orderOfDays.indexOf(a)
  const dayB = orderOfDays.indexOf(b)
  return dayA - dayB
}

export const mapToSpot = (spot: SpotResponse): Spot => {
  const chargers: EVChargerType[] = []

  Object.keys(spot.chargingStations).forEach((key) => {
    if (spot.chargingStations[key] > 0) {
      chargers.push(key as EVChargerType)
    }
  })

  return {
    id: spot.id,
    thumbnail: spot.thumbnail,
    title: spot.name,
    chargers,
    city: spot.city,
    address: `${spot.street}`,
    detailedAddress: spot.detailedAddress,
    operationHours: spot.startAt + ' ~ ' + spot.endAt,
    operationDays: Object.keys(spot.businessDays)
      .sort(customSort)
      .map((dayOfWeek) => {
        if (spot.businessDays[dayOfWeek as BusinessDay]) {
          return convertDayOfWeekToKorean(dayOfWeek)
        }
        return ''
      })
      .filter((dayOfWeek) => dayOfWeek !== ''),
    category: Object.keys(spot.themes).join(','),
    longitude: spot.longitude,
    latitude: spot.latitude,
    description: spot.description,
    phoneNumber: spot.phoneNumber,
    theme: Object.keys(spot.themes).filter((theme) => spot.themes[theme]),
  }
}

export const getSpotsSnapshot = (db: Database) => get(query(ref(db, 'spots')))

export const getSpots = async (): Promise<Spot[]> => {
  const db = getDatabase()

  return getSpotsSnapshot(db)
    .then((snapshot) => {
      if (snapshot.exists()) {
        const spots = objectToArray<SpotResponse>(snapshot.val())

        return spots.map((spot) => {
          return mapToSpot(spot)
        })
      }

      console.log('No data available')
      return []
    })
    .catch((error) => {
      console.error(error)
      return []
    })
}

export const convertDayOfWeekToKorean = (dayOfWeek: string): string => {
  const daysOfWeekMap: Record<string, string> = {
    sunday: '일',
    monday: '월',
    tuesday: '화',
    wednesday: '수',
    thursday: '목',
    friday: '금',
    saturday: '토',
  }

  const lowerCasedDayOfWeek = dayOfWeek.toLowerCase()
  return daysOfWeekMap[lowerCasedDayOfWeek]
}

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

export const getSpotsByCityAndThemeAndDate = async (
  city: string,
  travelTheme: string,
  day: BusinessDay,
): Promise<Spot[]> => {
  const db = getDatabase()

  return get(query(ref(db, 'spots'), orderByChild('city'), startAt(city), endAt(`${city}\uf8ff`))).then((snapshot) => {
    if (snapshot.exists()) {
      const spots = objectToArray<SpotResponse>(snapshot.val())

      const filteredSpots = spots
        .filter((spot) => {
          return spot.businessDays[day]
        })
        .filter((spot) => {
          return spot.themes[travelTheme]
        })

      if (filteredSpots.length <= 3) {
        return filteredSpots.map((spot) => {
          return mapToSpot(spot)
        })
      }

      const numbers = [0, 1, 2]
      const randoms: number[] = []
      numbers.forEach((i) => {
        let random = 0

        do {
          random = getRandomInt(0, filteredSpots.length)
        } while (randoms.includes(random))
        randoms.push(random)

        const temp = filteredSpots[i]
        filteredSpots[i] = filteredSpots[random]
        filteredSpots[random] = temp
      })

      return filteredSpots.slice(0, filteredSpots.length > 3 ? 3 : filteredSpots.length).map((spot) => {
        return mapToSpot(spot)
      })
    }

    return []
  })
}

export const getSpotById = async (id: string): Promise<Spot | null> => {
  const db = getDatabase()

  console.log(id)
  return getSpotsSnapshot(db).then((snapshot) => {
    if (snapshot.exists()) {
      const val = snapshot.val()

      if (val[id]) {
        return mapToSpot({ ...val[id], id })
      }
    }

    return null
  })
}

export const getSpotResponseById = async (id: string): Promise<SpotResponse | null> => {
  const db = getDatabase()

  console.log(id)
  return getSpotsSnapshot(db).then((snapshot) => {
    if (snapshot.exists()) {
      const val = snapshot.val()

      if (val[id]) {
        return { ...val[id], id }
      }
    }

    return null
  })
}

export interface AddSpotRequest {
  name: string
  city: string
  street: string
  detailedAddress: string
  businessDays: BusinessDays
  startAt: string
  endAt: string
  description: string
  latitude: number
  longitude: number
  phoneNumber: string
  category: string
  themes: Themes
  chargingStations: ChargingType
  thumbnail: string
  chargerLocation: string
  businessNumber: string
}

export const addSpot = (request: AddSpotRequest) => {
  const db = getDatabase()

  return push(ref(db, 'spots'), request)
}

export const updateSpot = (id: string, request: AddSpotRequest) => {
  const db = getDatabase()

  return update(ref(db, `spots/${id}`), request)
}

export const searchSpot = async (keyword: string): Promise<Spot[]> => {
  const db = getDatabase()

  return get(query(ref(db, 'spots'), orderByChild('name'), startAt(keyword), endAt(`${keyword}\uf8ff`))).then(
    (snapshot) => {
      if (snapshot.exists()) {
        const spots = objectToArray<SpotResponse>(snapshot.val())

        return spots.map((spot) => {
          return mapToSpot(spot)
        })
      }

      return []
    },
  )
}

export const filterSpotByTheme = async (theme: string): Promise<Spot[]> => {
  const db = getDatabase()

  return get(query(ref(db, 'spots'))).then((snapshot) => {
    if (snapshot.exists()) {
      const spots = objectToArray<SpotResponse>(snapshot.val())

      return spots
        .filter((spot) => {
          return spot.themes[theme]
        })
        .map((spot) => {
          return mapToSpot(spot)
        })
    }

    return []
  })
}
