'use client'

import IntroIllustration from '../../../images/IntroIllustration.png'

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import SearchBar from '../../../shared-components/SearchBar'
import { TRAVEL_CITY } from '../../travelPlanRecommend/TravelCityData'

const KEYWORDS = TRAVEL_CITY.map((city) => city.name)
const Intro = () => {
  const [currentKeywordIndex, setCurrentKeywordIndex] = useState(0)

  const location = useLocation()
  const navigate = useNavigate()

  const goTravelPlanRecommend = () => {
    navigate('travelPlanRecommend/where', { state: { from: location.pathname } })
  }

  useEffect(() => {
    const id = setInterval(() => {
      setCurrentKeywordIndex((previous) => (previous + 1) % KEYWORDS.length)
    }, 2000)

    return () => clearInterval(id)
  }, [])
  return (
    <section className="relative z-2 px-[20px] pt-[28px]">
      <h2 className="text-[24px] font-[600] leading-snug">
        이번 여행은
        <br />
        어디로 떠나시나요?
      </h2>
      <img
        className="absolute right-[37px] top-[12px] w-[98px]"
        src={IntroIllustration}
        alt={'travel car illustration'}
      />
      <button onClick={goTravelPlanRecommend} className={'w-full mt-[10px] '}>
        <SearchBar
          content={
            <p className="text-[14px] pl-[12px]">
              <strong className="font-bold text-primary50 mr-[4px]">{KEYWORDS[currentKeywordIndex]}</strong>
              <span className="text-gray40">을/를 검색해 보세요.</span>
            </p>
          }
        />
      </button>
    </section>
  )
}

export default Intro
