import { TextField } from '@mui/material'
import colors from '../../../styles/colors'
import * as React from 'react'

interface MyStoreTextField {
  label: string
  name: string
  placeholder: string
  value?: unknown
  type?: React.InputHTMLAttributes<unknown>['type']
  onChange?: (value: string) => void
}

const MyStoreTextField = ({ label, name, placeholder, value, type, onChange = () => null }: MyStoreTextField) => {
  return (
    <TextField
      fullWidth
      label={label}
      name={name}
      placeholder={placeholder}
      variant="outlined"
      value={value}
      type={type}
      sx={{
        bgcolor: colors.white,
        fieldset: {
          borderColor: colors.base,
        },
      }}
      onChange={(event) => onChange(event.target.value)}
    />
  )
}

export default MyStoreTextField
