import SearchIcon from './Icons/SearchIcon'
import { ReactNode } from 'react'

interface SearchBarProps {
  content: ReactNode
  disabledShadow?: boolean
  onClickSearchIcon?: () => void
}

const SearchBar = ({ content, disabledShadow = false, onClickSearchIcon = () => null }: SearchBarProps) => {
  return (
    <div
      className="w-full flex items-center relative h-[52px] border border-[#DDE3E9] bg-white rounded-[9px] overflow-hidden"
      style={{
        boxShadow: disabledShadow ? undefined : '0px 0px 11px 0px rgba(0, 0, 0, 0.07)',
      }}
    >
      {content}
      <div onClick={onClickSearchIcon}>
        <SearchIcon className="absolute right-[12px] top-[50%] translate-y-[-50%]" />
      </div>
    </div>
  )
}

export default SearchBar
