import CheckFillIcon from '../../../shared-components/Icons/CheckFillIcon'
import colors from '../../../styles/colors'
import { ChangeEvent } from 'react'

interface TermCheckBoxProps {
  name: string
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const TermCheckBox = ({ name, checked, onChange = () => null }: TermCheckBoxProps) => {
  return (
    <>
      <input type="checkbox" className="hidden" checked={checked} name={name} onChange={(event) => onChange(event)} />
      <CheckFillIcon color={checked ? colors.primary50 : colors.gray30} className={'w-[24px] h-[24px]'} />
    </>
  )
}

export default TermCheckBox
