import { SvgProps } from '../../types/svgProps'

const MinusIcon = ({ color = 'black', className = '' }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <circle cx="12" cy="12" r="11.5" fill="white" stroke="#DEDDDD" />
      <path
        d="M16.7775 12.75H7.22247C7.02356 12.75 6.83279 12.671 6.69214 12.5303C6.55149 12.3897 6.47247 12.1989 6.47247 12C6.47247 11.8011 6.55149 11.6103 6.69214 11.4697C6.83279 11.329 7.02356 11.25 7.22247 11.25H16.7775C16.9764 11.25 17.1672 11.329 17.3078 11.4697C17.4485 11.6103 17.5275 11.8011 17.5275 12C17.5275 12.1989 17.4485 12.3897 17.3078 12.5303C17.1672 12.671 16.9764 12.75 16.7775 12.75Z"
        fill={color}
      />
    </svg>
  )
}

export default MinusIcon
