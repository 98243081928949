import { Box, Button, Typography } from '@mui/material'
import colors from '../../../styles/colors'
import { ChangeEvent, useState } from 'react'
import TermCheckBox from './TermCheckBox'
import { termList, TermsStatus } from './termList'
import { Link } from 'react-router-dom'

interface AgreeTermsPageProps {
  onClickNextButton: (termsStatus: TermsStatus) => void
}

const AgreeTermsPage = ({ onClickNextButton }: AgreeTermsPageProps) => {
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [termsStatus, setTermsStatus] = useState<TermsStatus>({
    ageTerm: false,
    privacyTerm: false,
    serviceTerm: false,
    benefitTerm: false,
    marketingTerm: false,
  })

  const handleCheckAll = () => {
    setIsCheckedAll(!isCheckedAll)
    setTermsStatus({
      ageTerm: !isCheckedAll,
      privacyTerm: !isCheckedAll,
      serviceTerm: !isCheckedAll,
      benefitTerm: !isCheckedAll,
      marketingTerm: !isCheckedAll,
    })
  }

  const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    const newTermsStatus = {
      ...termsStatus,
      [name]: checked,
    }

    setTermsStatus(newTermsStatus)

    if (
      newTermsStatus.ageTerm &&
      newTermsStatus.privacyTerm &&
      newTermsStatus.serviceTerm &&
      newTermsStatus.benefitTerm &&
      newTermsStatus.marketingTerm
    ) {
      setIsCheckedAll(true)
    } else {
      setIsCheckedAll(false)
    }
  }

  const isNotCheckedAllNeed = () => !termsStatus.ageTerm || !termsStatus.privacyTerm || !termsStatus.serviceTerm

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '37px', padding: '32px 24px 130px 24px' }}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: '500',
            lineHeight: '36px',
            letterSpacing: '-0.48px',
            color: colors.gray90,
          }}
        >
          안전한 이용을 위해
          <br />
          아래 약관에 동의해 주세요.
        </Typography>
        <Box>
          <Box sx={{ backgroundColor: '#F2F3F5', borderRadius: '2px', padding: '12px 8px', marginBottom: '13px' }}>
            <label className={'flex gap-[4px] items-center'}>
              <TermCheckBox name={'checkedAll'} checked={isCheckedAll} onChange={handleCheckAll} />
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  letterSpacing: '-0.32px',
                  color: colors.gray90,
                  marginTop: '2px',
                }}
              >
                모두 동의합니다.
              </Typography>
            </label>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            {termList.map((item) => (
              <Box
                sx={{ padding: '8px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                key={item.id}
              >
                <label className={'flex gap-[4px] items-center'}>
                  <TermCheckBox name={item.name} checked={termsStatus[item.name]} onChange={handleCheck} />
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '500',
                      lineHeight: '18px',
                      letterSpacing: '-0.28px',
                      color: colors.gray90,
                    }}
                  >
                    {item.title}
                  </Typography>
                </label>
                <Link to={item.url} target={'_blank'}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '18px',
                      color: colors.gray60,
                    }}
                  >
                    보기
                  </Typography>
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          borderRadius: '0px',
          minHeight: '56px',
          background: 'black',
          maxWidth: '639px',
        }}
        disabled={isNotCheckedAllNeed()}
        onClick={() => onClickNextButton(termsStatus)}
      >
        <Typography
          color={colors.white}
          fontSize={'16px'}
          fontWeight={500}
          lineHeight={'20px'}
          letterSpacing={'-0.32px'}
        >
          다음
        </Typography>
      </Button>
    </>
  )
}

export default AgreeTermsPage
