import { MarkerF } from '@react-google-maps/api'
import MarkerIcon from '../images/marker.png'
import { TravelSpot } from '../pages/travelSpot/TravelSpot.entity'

interface MarkerListProps {
  travelSpots: TravelSpot[]
}

const MarkerList = ({ travelSpots }: MarkerListProps) => {
  return (
    <>
      {travelSpots.map((spot, index) => (
        <MarkerF
          key={`marker-${spot.id}-${index}`}
          position={{ lat: spot.latitude, lng: spot.longitude }}
          options={{
            label: {
              text: `${index + 1}`,
              color: '#fff',
              fontSize: '18px',
              fontWeight: '500',
              className: 'leading-6 tracking-[-.28px]',
              fontFamily: 'Pretendard',
            },
            icon: {
              labelOrigin: new window.google.maps.Point(25, 14),
              url: MarkerIcon,
              scaledSize: new window.google.maps.Size(50, 50),
            },
            anchorPoint: new window.google.maps.Point(0, 100),
          }}
        />
      ))}
    </>
  )
}

export default MarkerList
