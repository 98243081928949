import { Box } from '@mui/material'
import TravelHistoryCard from '../../shared-components/TravelHistoryCard'
import EmptyCourseCard from '../../shared-components/EmptyCourseCard'
import { useEffect, useState } from 'react'
import { useAuth } from '../../auth/Auth'
import { Travel } from '../../model/travel.entity'
import { getMyTravel } from '../../repository/travel.repository'
import { getSpotById } from '../../repository/spot.repository'

const MyTravels = () => {
  const { currentUser } = useAuth()
  const [myTravelData, setMyTravelData] = useState<Travel[]>([])

  useEffect(() => {
    if (!currentUser) return

    const setTravelData = async () => {
      const res = await getMyTravel(currentUser.uid)
      if (!res) return

      const newVar = await Promise.all(
        res.map(async (travel) => {
          const travelSpots = Object.keys(travel.spots)

          const spot = await getSpotById(travelSpots[0])

          if (!spot)
            return {
              id: travel.id,
              companion: travel.companion,
              title: travel.title,
              startAt: travel.startAt,
              themes: travel.themes,
              spots: [],
            }

          return {
            id: travel.id,
            companion: travel.companion,
            title: travel.title,
            startAt: travel.startAt,
            themes: travel.themes,
            spots: [spot],
          }
        }),
      )

      setMyTravelData(newVar)
    }

    setTravelData()
  }, [])

  return (
    <>
      {myTravelData.length !== 0 ? (
        <Box sx={{ padding: '18px 24px', display: 'flex', flexDirection: 'column', gap: '18px' }}>
          {myTravelData.map((item, index) => (
            <Box key={`my_travel_${index}`}>
              {/*TODO*/}
              <TravelHistoryCard
                history={{
                  id: item.id,
                  title: item.title,
                  tags: [item.companion, ...Object.keys(item.themes)],
                  spots: item.spots,
                }}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            height: '70vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <EmptyCourseCard
            link={'/travelPlanRecommend/where'}
            text={'내가 만든 여행이 없어요 \n 나에게 딱 맞는 친화경차 여행코스를 만들어 보세요!'}
          />
        </Box>
      )}
    </>
  )
}

export default MyTravels
