import { Box, Button, styled, TextField, Typography } from '@mui/material'
import colors from '../../styles/colors'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../auth/Auth'
import { get, getDatabase, query, ref, update } from 'firebase/database'
import { getAuth, signInWithEmailAndPassword, updatePassword, validatePassword } from 'firebase/auth'
import { UserInfo, Users } from '../../model/user.entity'
import dayjs from 'dayjs'

const ProfileInputLabel = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '16px',
  letterSpacing: '-0.24px',
  color: colors.gray90,
}))

const Profile = () => {
  const navigate = useNavigate()
  const location = useLocation()
  // const [isPhoneNumberValidate, setIsPhoneNumberValidate] = useState(false)
  const [isResetPassword, setIsResetPassword] = useState(false)
  const password = useRef({
    currentPassword: '',
    newPassword: '',
  })
  const [userInfo, setUserInfo] = useState<Users>({
    email: '',
    password: '1234567',
    name: '홀길동',
    birth: '20230101',
    phoneNumber: '01012345678',
    memberType: 'general',
  })
  const auth = getAuth()
  const [passwordValidationMessage, setPasswordValidationMessage] = useState({
    currentPassword: '',
    newPassword: '',
  })

  const { currentUser } = useAuth()
  const db = getDatabase()

  useEffect(() => {
    const getUserInfo = async () => {
      const dataSnapshot = await get(query(ref(db, `users/${currentUser?.uid}`)))
      return dataSnapshot.val() as UserInfo
    }

    if (currentUser) {
      getUserInfo().then((userInfo) => {
        setUserInfo({
          ...userInfo,
          email: currentUser.email!,
          password: '1234567',
        })
      })
    }
  }, [])

  const onChangeUserInfo = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUserInfo({
      ...userInfo,
      [event.target.name]: event.target.value,
    })
  }

  const isNotMatchBirthRegex = (value: string) => {
    const birthRegex = /^\d{8}$/

    if (!birthRegex.test(value)) {
      return true
    }

    return dayjs(value).format('YYYYMMDD') !== value
  }

  const isNotMatchPhoneNumberRegex = (value: string) => {
    const phoneNumberRegex = /^\d{11}$/
    return !phoneNumberRegex.test(value)
  }

  const isNotMatchNameRegex = (value: string) => {
    const nameRegex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z]+$/
    return !nameRegex.test(value)
  }

  // const onClickPhoneValidate = () => {
  //   setIsPhoneNumberValidate(true)
  // }

  const onClickResetPassword = async () => {
    if (!isResetPassword) {
      setIsResetPassword(true)
      return
    }

    const validationStatus = await validatePassword(auth, password.current.newPassword)

    if (!validationStatus.isValid) {
      setPasswordValidationMessage({
        ...passwordValidationMessage,
        newPassword: '새로운 비밀번호가 유효하지 않습니다.',
      })
      return
    } else {
      setPasswordValidationMessage({
        ...passwordValidationMessage,
        newPassword: '',
      })
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, userInfo.email, password.current.currentPassword)
      await updatePassword(userCredential.user, password.current.newPassword)
    } catch (error) {
      setPasswordValidationMessage({
        ...passwordValidationMessage,
        currentPassword: '기존 비밀번호가 일치하지 않습니다.',
      })
      return
    }

    setPasswordValidationMessage({
      currentPassword: '',
      newPassword: '',
    })
    setIsResetPassword(!isResetPassword)
  }

  // const sendValidateNumber = () => {
  //   // TODO: 인증번호 발송
  // }

  const onClickEditProfile = async () => {
    const updateUserInfo = async () => {
      await update(ref(db, `users/${currentUser?.uid}`), {
        name: userInfo.name,
        birth: userInfo.birth,
        phoneNumber: userInfo.phoneNumber,
        updatedAt: new Date().getTime(),
      })
    }

    await updateUserInfo()
    navigate('/myPage', { replace: true, state: { from: location.pathname } })
  }

  const onChangePassword = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    password.current = {
      ...password.current,
      [event.target.name]: event.target.value,
    }
  }

  return (
    <>
      <Box sx={{ padding: '26px 24px', display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <ProfileInputLabel>이메일</ProfileInputLabel>
          <TextField
            value={userInfo.email}
            disabled={true}
            variant={'standard'}
            sx={{ '.MuiInput-input': { padding: '16px 4px' } }}
            fullWidth
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {!isResetPassword ? (
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <ProfileInputLabel>비밀번호</ProfileInputLabel>
              <TextField
                value={userInfo.password}
                disabled={true}
                type={'password'}
                variant={'standard'}
                sx={{
                  '.MuiInput-input': { padding: '16px 4px' },
                  backgroundColor: '#F2F3F5',
                  color: colors.gray40,
                }}
                fullWidth
              />
            </Box>
          ) : (
            <>
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
                <ProfileInputLabel>기존 비밀번호</ProfileInputLabel>
                <TextField
                  type={'password'}
                  name={'currentPassword'}
                  variant={'standard'}
                  sx={{
                    '.MuiInput-input': { padding: '16px 4px' },
                  }}
                  fullWidth
                  error={passwordValidationMessage.currentPassword !== ''}
                  helperText={passwordValidationMessage.currentPassword}
                  onChange={(event) => onChangePassword(event)}
                />
              </Box>
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
                <ProfileInputLabel>새 비밀번호</ProfileInputLabel>
                <TextField
                  type={'password'}
                  name={'newPassword'}
                  variant={'standard'}
                  sx={{
                    '.MuiInput-input': { padding: '16px 4px' },
                  }}
                  fullWidth
                  error={passwordValidationMessage.newPassword !== ''}
                  helperText={passwordValidationMessage.newPassword}
                  onChange={(event) => onChangePassword(event)}
                />
              </Box>
            </>
          )}
          <Button
            onClick={onClickResetPassword}
            sx={{
              alignSelf: 'end',
              borderRadius: '2px',
              border: `1px solid ${colors.base}`,
              backgroundColor: colors.white,
              padding: '8px 12px',
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '16px',
                letterSpacing: '-0.24px',
                color: colors.gray90,
              }}
            >
              비밀번호 변경하기
            </Typography>
          </Button>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <ProfileInputLabel>이름</ProfileInputLabel>
          <TextField
            name={'name'}
            value={userInfo.name}
            variant={'standard'}
            onChange={(event) => onChangeUserInfo(event)}
            error={userInfo.name.length > 0 && isNotMatchNameRegex(userInfo.name)}
            helperText={
              userInfo.name.length > 0 && isNotMatchNameRegex(userInfo.name)
                ? '이름엔 공백이나 특수문자가 포함될 수 없습니다.'
                : ''
            }
            sx={{
              '.MuiInput-input': { padding: '16px 4px' },
            }}
            fullWidth
          />
        </Box>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <ProfileInputLabel>생년월일</ProfileInputLabel>
          <TextField
            name={'birth'}
            value={userInfo.birth}
            variant={'standard'}
            onChange={(event) => onChangeUserInfo(event)}
            sx={{ '.MuiInput-input': { padding: '16px 4px' } }}
            error={isNotMatchBirthRegex(userInfo.birth)}
            helperText={isNotMatchBirthRegex(userInfo.birth) ? '생년월일을 입력해 주세요.' : ''}
            fullWidth
          />
        </Box>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <ProfileInputLabel>휴대폰 번호</ProfileInputLabel>
          <TextField
            name={'phoneNumber'}
            value={userInfo.phoneNumber}
            onChange={(event) => onChangeUserInfo(event)}
            // disabled={!isPhoneNumberValidate}
            variant={'standard'}
            error={isNotMatchPhoneNumberRegex(userInfo.phoneNumber)}
            helperText={isNotMatchPhoneNumberRegex(userInfo.phoneNumber) ? '휴대폰 번호를 입력해 주세요.' : ''}
            sx={{
              '.MuiInput-input': { padding: '16px 4px' },
              // backgroundColor: !isPhoneNumberValidate ? '#F2F3F5' : '',
              // color: !isPhoneNumberValidate ? colors.gray40 : '',
            }}
            fullWidth
          />
        </Box>
        {/*<Button*/}
        {/*  onClick={!isPhoneNumberValidate ? onClickPhoneValidate : sendValidateNumber}*/}
        {/*  sx={{*/}
        {/*    alignSelf: 'end',*/}
        {/*    borderRadius: '2px',*/}
        {/*    border: `1px solid ${colors.base}`,*/}
        {/*    backgroundColor: colors.white,*/}
        {/*    padding: '8px 12px',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Typography*/}
        {/*    sx={{*/}
        {/*      fontSize: '12px',*/}
        {/*      fontWeight: '500',*/}
        {/*      lineHeight: '16px',*/}
        {/*      letterSpacing: '-0.24px',*/}
        {/*      color: colors.gray90,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {!isPhoneNumberValidate ? '휴대폰 번호 변경하기' : '인증번호 발송'}*/}
        {/*  </Typography>*/}
        {/*</Button>*/}
      </Box>
      <Button
        onClick={onClickEditProfile}
        variant="contained"
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          borderRadius: '0px',
          minHeight: '56px',
          background: 'black',
          maxWidth: '639px',
        }}
      >
        <Typography
          color={colors.white}
          fontSize={'16px'}
          fontWeight={500}
          lineHeight={'20px'}
          letterSpacing={'-0.32px'}
        >
          프로필 수정하기
        </Typography>
      </Button>
    </>
  )
}

export default Profile
