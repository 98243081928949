/* global google */
import TravelThemeChip from './TravelThemeChip'
import TravelSpotListItem from './TravelSpotListItem'
import { Button } from '@mui/material'
import colors from '../styles/colors'
import TourStepIcon from './Icons/TourStepIcon'
import { TravelSpot } from '../pages/travelSpot/TravelSpot.entity'
import { useLocation, useNavigate } from 'react-router-dom'
import LogoSymbol from '../images/logo-symbol.png'
import CustomMap from './CustomMap'
import MarkerList from './MarkerList'
import SaveCourseModal from './SaveCourseModal'
import { useState } from 'react'
import { useAuth } from '../auth/Auth'
import { addTravel, removeTravel } from '../repository/travel.repository'

interface TravelCourseProps {
  title: string
  travelId: string
  travelSpots: TravelSpot[]
  date: string
  theme: string[]
  companion: string
  place: string
}

const TravelCourse = ({ title, travelId, travelSpots, date, theme, companion, place }: TravelCourseProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [saveCourseModalOpen, setSaveCourseModalOpen] = useState<boolean>(false)
  const { currentUser } = useAuth()

  const handleOnSave = async () => {
    if (!currentUser) return

    if (travelId) {
      await removeTravel(currentUser.uid, travelId)
    }

    const spots = travelSpots.map((spot) => spot.id)

    const themes = theme.reduce((acc: any, currentValue) => {
      acc[currentValue] = true
      return acc
    }, {})

    await addTravel(currentUser.uid, {
      companion: companion,
      title: `${date} ${place} 여행`,
      startAt: date,
      themes,
      place,
      spots,
    })
    setSaveCourseModalOpen(true)
  }

  const onCloseSaveCourseModal = () => {
    setSaveCourseModalOpen(false)
  }

  const handelOnChangeSpot = (spot: TravelSpot, index: number) => {
    navigate('/travelSpot/change', {
      state: {
        from: location.pathname,
        prevSpot: spot,
        id: travelId,
        index,
      },
    })
  }

  const moveToSearch = () => {
    navigate('/travelSpot/search', { state: { from: location.pathname, id: travelId } })
  }

  const handleLoadMap = (loadedMap: google.maps.Map) => {
    const bounds = new google.maps.LatLngBounds()

    travelSpots.forEach((spot) => {
      bounds.extend({ lat: spot.latitude, lng: spot.longitude })
    })

    loadedMap.fitBounds(bounds)
  }

  return (
    <>
      <div className={'py-6 px-6 w-full mb-[130px]'}>
        <div className="text-zinc-900 text-xl font-semibold font-['Pretendard'] leading-normal">{title}</div>
        <div className={'flex flex-wrap gap-2 mt-2'}>
          {[companion, ...theme].map((tags: string) => (
            <TravelThemeChip label={tags} key={tags} />
          ))}
        </div>
        <div className="w-full h-14 p-3 bg-gray10 rounded justify-start items-center gap-2 inline-flex ">
          <img src={LogoSymbol} alt={'logo'} />
          <p className="text-xs font-medium">
            이카로의 모든 여행지에는 친환경차 충전기가 준비되어 있습니다. 언제든지 충전이 가능하니 편하게 여행하세요!
          </p>
        </div>
        <div
          className="w-full rounded-lg border border-gray20 mt-4 mb-8 overflow-hidden"
          style={{
            aspectRatio: 1.6,
          }}
        >
          <CustomMap onLoad={handleLoadMap}>
            <MarkerList travelSpots={travelSpots} />
          </CustomMap>
        </div>
        <div>
          {travelSpots.map((spot, index) => (
            <div className={'relative w-full h-fit flex flex-col gap-2 mb-[30px]'} key={`${spot.id}-${index}`}>
              <TravelSpotListItem {...spot} />
              <Button
                variant={'outlined'}
                color={'inherit'}
                sx={{ ml: 9.5, width: 75, height: 32, p: 0, borderColor: colors.gray10 }}
                onClick={() => handelOnChangeSpot(spot, index)}
              >
                <p className={'text-xs'}>변경하기</p>
              </Button>
              {index !== travelSpots.length - 1 && (
                <div id={'line'} className={'absolute left-100 top-16 h-[98px] flex flex-col w-[70px] items-center'}>
                  <div className={'h-[68px] w-[1px] bg-gray10'} />
                  <TourStepIcon className={'w-8 max-h-8'} />
                  <div className={'h-[14px] w-[1px] bg-gray10'} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={'w-full fixed bottom-0 z-10 max-w-[639px] flex flex-col gap-[24.5px] items-center'}>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: '90%', borderRadius: '0px', minHeight: '42px' }}
          onClick={moveToSearch}
        >
          여행지 추가하기
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: '100%', borderRadius: '0px', minHeight: '56px', background: 'black', maxWidth: '639px' }}
          onClick={handleOnSave}
        >
          여행코스 저장하기
        </Button>
      </div>
      <SaveCourseModal open={saveCourseModalOpen} onClose={onCloseSaveCourseModal} />
    </>
  )
}

export default TravelCourse
