import { Box, Typography } from '@mui/material'
import RoundCheckBoxWithLabel from './RoundedCheckBoxWithLabel'
import { useEffect, useState } from 'react'
import colors from '../../../styles/colors'

export const DAY_OF_WEEK = ['일', '월', '화', '수', '목', '금', '토']

interface DayOptionProps {
  day: string
  checked: boolean
  onChangeCheckedDay: (day: string) => void
}

const DayOption = ({ day, checked, onChangeCheckedDay }: DayOptionProps) => {
  return (
    <>
      <input
        id={day}
        className="hidden"
        type="checkbox"
        name="salesInformation"
        checked={checked}
        onChange={() => onChangeCheckedDay(day)}
      ></input>
      <label
        htmlFor={day}
        className="flex justify-center items-center border-[1px] aspect-square w-[36px] rounded-[50%] text-[14px]"
        style={{
          background: checked ? colors.primary50 : colors.white,
          color: checked ? colors.white : colors.gray90,
          borderColor: checked ? colors.primary50 : colors.base,
        }}
      >
        {day}
      </label>
    </>
  )
}

interface WorkingDayCheckBoxProps {
  workingDays: string[]
  onChange: (value: string[]) => void
}

const WorkingDayCheckBox = ({ workingDays, onChange }: WorkingDayCheckBoxProps) => {
  const [checkedDays, setCheckedDays] = useState<string[]>([])

  useEffect(() => {
    setCheckedDays(workingDays)
  }, [workingDays])

  const onChangeCheckedDay = (day: string) => {
    if (!checkedDays.includes(day)) {
      setCheckedDays([...checkedDays, day])
      onChange([...checkedDays, day])
    } else {
      const filtered = checkedDays.filter((checkedDay) => checkedDay !== day)
      setCheckedDays([...filtered])
      onChange([...filtered])
    }
  }

  const onCheckEveryDay = (checked: boolean) => {
    if (checked) {
      setCheckedDays(DAY_OF_WEEK)
      onChange(DAY_OF_WEEK)
    } else {
      setCheckedDays([])
      onChange([])
    }
  }

  return (
    <Box>
      <Typography fontSize={'15px'} fontWeight={500} mb={'16px'} mt={'24px'}>
        영업정보
      </Typography>
      <Box display={'flex'} gap={'10px'} mb={'16px'}>
        {DAY_OF_WEEK.map((day) => (
          <DayOption
            day={day}
            key={`sales-information-option-${day}`}
            onChangeCheckedDay={onChangeCheckedDay}
            checked={checkedDays.includes(day)}
          />
        ))}
      </Box>
      <RoundCheckBoxWithLabel
        label="매일"
        onChange={onCheckEveryDay}
        checked={checkedDays.length === DAY_OF_WEEK.length}
      />
    </Box>
  )
}

export default WorkingDayCheckBox
