import { SvgProps } from '../../types/svgProps'

const PhotoIcon = ({ color = 'black', className = '' }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.63892 5.5L8.33122 7.5H15.6688L15.3611 5.5H8.63892ZM6.88394 3.75438L6.30769 7.5H2.3C2.13431 7.5 2 7.63431 2 7.8V21.2C2 21.3657 2.13431 21.5 2.3 21.5H21.7C21.8657 21.5 22 21.3657 22 21.2V7.8C22 7.63431 21.8657 7.5 21.7 7.5H17.6923L17.1161 3.75438C17.0935 3.60803 16.9676 3.5 16.8195 3.5H7.18045C7.03238 3.5 6.90646 3.60803 6.88394 3.75438ZM6.34968 9.5H17.6503H20V19.5H4V9.5H6.34968ZM15 14.5C15 16.1569 13.6569 17.5 12 17.5C10.3431 17.5 9 16.1569 9 14.5C9 12.8431 10.3431 11.5 12 11.5C13.6569 11.5 15 12.8431 15 14.5Z"
        fill={color}
      />
    </svg>
  )
}

export default PhotoIcon
