import { SvgProps } from '../../types/svgProps'

const CheckFillIcon = ({ color = 'black', className = '' }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12ZM10.9145 13.5L16.4145 8L17.8285 9.414L12.3285 14.914L10.9145 16.328L9.5 14.914H9.5005L6 11.414L7.4145 10L10.9145 13.5Z"
        fill={color}
      />
    </svg>
  )
}

export default CheckFillIcon
