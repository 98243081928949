import { useLocation, useNavigate } from 'react-router-dom'
import MapPinLineIcon from '../../shared-components/Icons/MapPinLineIcon'
import colors from '../../styles/colors'
import TimeLineIcon from '../../shared-components/Icons/TimeLineIcon'
import InfoStrokeIcon from '../../shared-components/Icons/InfoStrokeIcon'
import { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import DescriptionWithIcon from '../../shared-components/DescriptionWithIcon'
import { getThemes, TravelTheme } from '../../repository/theme.repository'
import { useRecoilState } from 'recoil'
import { myTravelState } from '../../travel/travelState'

const COMPANION_OPTIONS = ['혼자', '가족', '친구', '연인', '동료', '기타']

type SelectedValue = {
  location: string
  date: string
  companion: string
  theme: string[]
}

const INITIAL_SELECTED_VALUE: SelectedValue = {
  location: '',
  date: '',
  companion: '',
  theme: [],
}

const TravelPlanRecommendTheme = () => {
  const location = useLocation()
  const selectedLocation = location.state.selectedLocation
  const selectedDate = location.state.selectedDate
  const navigate = useNavigate()
  const [themes, setThemes] = useState<TravelTheme[]>([])

  const [selectedValue, setSelectedValue] = useState<SelectedValue>(INITIAL_SELECTED_VALUE)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [myTravel, setMyTravel] = useRecoilState(myTravelState)

  useEffect(() => {
    setSelectedValue({
      ...selectedValue,
      location: selectedLocation,
      date: selectedDate,
    })

    getThemes().then((travelThemes) => {
      setThemes(travelThemes)
    })
  }, [])

  const checkIsValid = () => {
    return selectedValue.companion !== '' && selectedValue.theme.length > 0
  }

  useEffect(() => {
    setIsValid(checkIsValid())
  }, [selectedValue.companion, selectedValue.theme])

  const handleOnchangeCompanion = (option: string) => {
    setSelectedValue({ ...selectedValue, companion: option })
  }
  const handleOnChangeTheme = (themeName: string) => {
    if (selectedValue.theme.includes(themeName)) {
      setSelectedValue({
        ...selectedValue,
        theme: selectedValue.theme.filter((theme) => theme !== themeName),
      })
      return
    }

    setSelectedValue({
      ...selectedValue,
      theme: [themeName],
    })
  }

  const handleOnSubmit = () => {
    setMyTravel({
      ...myTravel,
      title: '',
    })
    navigate('/travelPlanRecommend/', {
      state: {
        from: location.pathname,
        selectedValue,
      },
    })
  }

  return (
    <>
      <div className={'px-6 flex flex-col gap-5 mb-20'}>
        <div className="py-5 flex flex-col gap-2 w-full text-zinc-900 text-xl font-semibold font-['Pretendard']">
          이번 여행의 테마는 무엇인가요?
          <DescriptionWithIcon
            label={selectedLocation}
            icon={<MapPinLineIcon color={colors.gray40} className={'w-[14px] h-[14px]'} />}
          />
          <DescriptionWithIcon
            label={selectedDate}
            icon={<TimeLineIcon color={colors.gray40} className={'w-[14px] h-[14px]'} />}
          />
        </div>
        <div className={'flex flex-col gap-7'}>
          <div className="w-full h-11 p-3 bg-gray10 rounded-sm justify-center items-center gap-2 inline-flex">
            <InfoStrokeIcon className={'h-5 w-5'} />
            <div className="text-black text-xs font-medium font-['Pretendard'] leading-none">
              여행 테마는 언제든지 추가하거나 수정이 가능해요.
            </div>
          </div>
          <div className={'w-full flex flex-col gap-2'}>
            <div className="text-black text-sm font-semibold font-['Pretendard'] leading-[18px]">
              누구와 떠나시나요?
            </div>
            <div className="flex flex-wrap gap-3">
              {COMPANION_OPTIONS.map((option) => (
                <div
                  key={option}
                  className={`${
                    selectedValue.companion === option ? 'border-primary50' : 'border-gray10'
                  } w-[69px] h-11 p-3 bg-white rounded-[11px] border flex-col justify-center items-center gap-6 inline-flex`}
                  onClick={() => handleOnchangeCompanion(option)}
                >
                  <div
                    className={`${
                      selectedValue.companion === option ? '' : 'opacity-50'
                    } text-center text-black text-base font-medium font-['Pretendard'] leading-tight`}
                  >
                    {option}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={'w-full flex flex-col gap-2'}>
            <div className={'flex flex-wrap justify-between items-center gap-2'}>
              <div className="text-black text-sm font-semibold font-['Pretendard'] leading-[18px]">
                어떤 여행을 떠날까요?
              </div>
              <div className="text-black text-xs font-medium font-['Pretendard'] leading-none">
                테마는 주기적으로 업데이트 됩니다!
              </div>
            </div>
            <div className="flex flex-wrap gap-3 justify-between">
              {themes.map((theme) => (
                <div
                  key={theme.name}
                  className={`${
                    selectedValue.theme.includes(theme.name) ? 'border-primary50' : 'border-gray10'
                  } w-[calc(35%-20px)] min-w-[100px] h-[calc(35%-20px)] p-3 bg-white rounded-[11px] border flex-col justify-center items-center gap-6 inline-flex`}
                  onClick={() => handleOnChangeTheme(theme.name)}
                >
                  <div
                    className={`${
                      selectedValue.theme.includes(theme.name) ? '' : 'opacity-50'
                    } text-center text-black text-base font-medium font-['Pretendard'] leading-tight flex flex-col gap-3 items-center`}
                  >
                    <img className="w-8 h-8" src={theme.icon} alt={'sample'} />
                    {theme.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={'w-full fixed bottom-0 z-10 max-w-[639px]'}>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: '100%', borderRadius: '0px', minHeight: '56px', background: 'black', maxWidth: '639px' }}
          disabled={!isValid}
          onClick={handleOnSubmit}
        >
          선택
        </Button>
      </div>
    </>
  )
}

export default TravelPlanRecommendTheme
