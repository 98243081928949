import { Box, Button, Modal, Typography } from '@mui/material'
import Error from '../../../images/error.png'
import { useNavigate } from 'react-router-dom'
import colors from '../../../styles/colors'
import { ModalProps } from '../../../types/ModalProps'

const MyStoreModal = ({ open, onClose }: ModalProps) => {
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(-1)
    onClose()
  }
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          backgroundColor: colors.white,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '298px',
          height: '252px',
          py: '8px',
          borderRadius: '4px',
          border: '1px solid #F5F5F5',
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            padding: '16px 24px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <img src={Error} alt={'error image'} style={{ width: '36px', height: '36px' }} />
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '18px',
              fontWeight: '700',
              lineHeight: '24px',
              letterSpacing: '-0.36px',
              whiteSpace: 'pre-wrap',
              wordBreak: 'keep-all',
            }}
          >
            지금 나가면 처음부터 다시 등록하셔야 합니다.
          </Typography>
        </Box>
        <Box sx={{ py: '8px' }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '500',
              lineHeight: '20px',
              letterSpacing: '-0.32px',
            }}
          >
            이전 화면으로 돌아갈까요?
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', padding: '16px 24px' }}>
          <Button
            onClick={handleGoBack}
            sx={{
              width: '117px',
              height: '50px',
              borderRadius: '2px',
              border: `1px solid ${colors.base}`,
              '&:hover': { backgroundColor: colors.gray10 },
            }}
          >
            <Typography
              sx={{
                color: colors.gray90,
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '-0.32px',
              }}
            >
              돌아가기
            </Typography>
          </Button>
          <Button
            onClick={onClose}
            sx={{
              width: '117px',
              height: '50px',
              borderRadius: '2px',
              backgroundColor: colors.black,
              '&:hover': { backgroundColor: colors.gray80 },
            }}
          >
            <Typography
              sx={{
                color: colors.white,
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '-0.32px',
              }}
            >
              닫기
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default MyStoreModal
