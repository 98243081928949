import { Box, Button, Divider, Typography } from '@mui/material'
import colors from '../../styles/colors'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getMemberName, MemberType } from '../../types/member'
import { useAuth } from '../../auth/Auth'

interface Navigations {
  title: string
  link: string
  count?: number
}

const navigationsForGeneral: Navigations[] = [
  {
    title: '내가 만든 여행',
    link: '/myTravels',
  },
  {
    title: '공지사항',
    link: '/notice',
  },
  {
    title: '문의',
    link: '/faq',
  },
  {
    title: '내가 찜한 여행지',
    link: '/wishList',
  },
  {
    title: '설정',
    link: '/setting',
  },
]

const navigationsForProvider: Navigations[] = [
  {
    title: '내 매장 등록',
    link: '/myStore',
  },
  {
    title: '내가 만든 여행',
    link: '/myTravels',
  },
  {
    title: '공지사항',
    link: '/notice',
  },
  {
    title: '문의',
    link: '/faq',
  },
  {
    title: '내가 찜한 여행지',
    link: '/wishList',
  },
  {
    title: '설정',
    link: '/setting',
  },
]

const MyPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [memberType, setMemberType] = useState<MemberType>('general')
  const { currentUser } = useAuth()

  const moveToLink = (link: string) => {
    navigate(link, { state: { from: location.pathname } })
  }

  const matchNavigation = (memberType: MemberType) => {
    if (memberType === 'general') {
      // TODO: 서버에서 내가 만든 여행 갯수 가져와서 반영하기
      navigationsForGeneral.forEach((navigation) => {
        if (navigation.title === '내가 만든 여행') navigation.count = 0
      })
      return navigationsForGeneral
    }
    if (memberType === 'enterprise') return navigationsForProvider
  }

  useEffect(() => {
    // TODO: 멤버타입 실연동
    if (localStorage.getItem('memberType') === 'general') setMemberType('general')
    if (localStorage.getItem('memberType') === 'enterprise') setMemberType('enterprise')
  }, [])

  return (
    <Box height={'100%'}>
      <Box padding={'20px 16px'}>
        <Box
          display={'flex'}
          gap={'12px'}
          justifyContent={'space-between'}
          alignItems={'center'}
          padding={'12px'}
          border={`1px solid ${colors.base}`}
          borderRadius={'4px'}
        >
          <Box>
            <Typography fontWeight={700}>{currentUser?.email}</Typography>
            <Typography
              color={memberType === 'enterprise' ? colors.primary50 : colors.gray60}
              fontWeight={500}
              fontSize={'14px'}
            >
              {getMemberName(memberType)}
            </Typography>
          </Box>
          <Button
            onClick={() => moveToLink('/profile')}
            variant={'outlined'}
            color={'inherit'}
            sx={{
              width: '45px',
              height: '32px',
              borderColor: colors.gray10,
              fontSize: '12px',
              whiteSpace: 'nowrap',
              minWidth: 'unset',
            }}
          >
            수정
          </Button>
        </Box>
      </Box>

      <Divider sx={{ borderWidth: '2px', borderColor: colors.gray10 }} />
      {matchNavigation(memberType)?.map((naviagation) => {
        return (
          <Box key={`myPage-button-${naviagation.title}`}>
            <Button
              color={'inherit'}
              fullWidth
              sx={{
                padding: '20px 24px',
                justifyContent: 'space-between',
                borderRadius: 0,
              }}
              onClick={() => moveToLink(naviagation.link)}
            >
              <Typography fontSize={'15px'} fontWeight={500}>
                {naviagation.title}
              </Typography>
              {naviagation.count ? (
                <Typography fontSize={'15px'} fontWeight={500}>
                  {`${naviagation.count} 건`}
                </Typography>
              ) : null}
            </Button>
            <Divider sx={{ borderColor: colors.gray10 }} />
          </Box>
        )
      })}
    </Box>
  )
}

export default MyPage
