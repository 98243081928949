import { objectToArray } from '../utils/objectToArray'
import { get, getDatabase, orderByChild, query, ref } from 'firebase/database'
import { Notice } from '../model/notice.entity'

export const getNoticeList = async (): Promise<Notice[]> => {
  const db = getDatabase()

  return get(query(ref(db, 'notice'), orderByChild('createdAt'))).then((snapshot) => {
    if (snapshot.exists()) {
      return objectToArray<Notice>(snapshot.val())
    }

    return []
  })
}

export const getNotice = async (id: string): Promise<Notice | null> => {
  const db = getDatabase()

  return get(ref(db, `notice/${id}`)).then((snapshot) => {
    if (snapshot.exists()) {
      return { ...snapshot.val(), id: snapshot.key } as Notice
    }

    return null
  })
}
