import { useEffect, useState } from 'react'
import { ToggleButton } from '@mui/material'
import HeartMonoSolidIcon from './Icons/HeartMonoSolidIcon'
import colors from '../styles/colors'

//TODO: 찜한 여행지 목록을 전역상태로 관리하고, 여기서는 여행지 id만 받아서 찜한 여행지인지 아닌지만 판단하도록 수정
const LikeButton = ({ liked, onChange }: { liked: boolean; onChange: (newValue: boolean) => void }) => {
  const [likedState, setLikedState] = useState(liked)

  useEffect(() => {
    setLikedState(liked)
  }, [liked])
  const onChangeLiked = (newLiked: boolean) => {
    setLikedState(newLiked)
    //TODO: 찜한 여행지 추가/삭제 로직
    console.log(newLiked)
    onChange(newLiked)
  }

  return (
    <ToggleButton
      value="check"
      selected={likedState}
      onClick={(event) => event.stopPropagation()}
      onChange={() => {
        onChangeLiked(!likedState)
      }}
      style={{
        border: 'none',
        padding: '5px',
        background: 'unset',
      }}
    >
      <HeartMonoSolidIcon className="w-[20px] h-[20px]" color={likedState ? colors.primary50 : colors.gray30} />
    </ToggleButton>
  )
}

export default LikeButton
